import BreadCrumb from 'components/Common/BreadCrumb';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import LabelWrapper from 'components/Common/LabelWrapper';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getCollecting as onGetCollecting } from "../../../store/thunks";
import TooltipCustom from 'components/Common/TooltipCustom';

const typeQuery = {
  'key': StringParam,
}


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale("en", en);
registerLocale("ko", ko);


const CollectingMonitoring = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const START_DATE = `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`;
  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 35),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    start_date: withDefault(StringParam, START_DATE),
    end_date: withDefault(StringParam, END_DATE),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      collecting: state.collecting,
      isCollectingSuccess: state.isCollectingSuccess,
      isCollectingLoading: state.isCollectingLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { collecting, error, isCollectingLoading } = useSelector(KeywordProperties);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  useEffect(() => {
    dispatch(onGetCollecting(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      start_date: START_DATE,
      end_date: END_DATE,
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: "date",
        filterable: false,
        sortable: true,
        thWidth: 100,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div className="text-start" style={{ minWidth: '80px' }}>{cell?.value}</div>
        ),
      },
      {
        Header: t('Total Number Of Keywords'),
        accessor: "total_keyword",
        filterable: false,
        sortable: true,
        thWidth: 250,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '200px' }}>
            {formatNumberWithCommas(cell?.value || 0)}
          </div>
        ),
      },
      {
        Header: t('Number of Stock'),
        accessor: "total_keyword_stock",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '140px' }}>
            {formatNumberWithCommas(cell?.value || 0)}
          </div>
        ),
      },
      {
        Header: t('Number of Naver Search'),
        thClass: 'text-end',
        filterable: false,
        sortable: false,
        // description: t('The number of keywords with search results on Naver. Search'),
        thWidth: 480,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t('Number of Naver Search')}
                  <TooltipCustom
                    title={t('The number of keywords with search results on Naver. Search')}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '180px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'total_search_success', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'total_search_success') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t('Collected Keyword')}
                  {query?.sort_by === 'total_search_success' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '180px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'total_invalid', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'total_invalid') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t('Invalid Keyword')}
                  {query?.sort_by === 'total_invalid' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '120px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'total_error', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'total_error') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t('Error Keyword')}
                  {query?.sort_by === 'total_error' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: '180px' }}>{formatNumberWithCommas(item.total_search_success)}</div>
              <div style={{ width: '180px', borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea" }}>{formatNumberWithCommas(item.total_invalid)}</div>
              <div style={{ width: '120px' }}>{formatNumberWithCommas(item.total_error)}</div>
            </div>
          );
        },
      },
      // {
      //   Header: t('Number Of Searches'),
      //   accessor: "total_search_success",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 150,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: '120px' }}>
      //       {formatNumberWithCommas(cell?.value || 0)}
      //     </div>
      //   ),
      // },
      {
        Header: t('Number Of Naver Blog'),
        accessor: "total_blog_success",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: 'text-end align-middle',
        description: t('The number of keywords with search results on Naver. Blog'),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '200px' }}>
            {formatNumberWithCommas(cell?.value || 0)}
          </div>
        ),
      },
      {
        Header: t('Number Of Naver Cafe'),
        accessor: "total_cafe_success",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: 'text-end align-middle',
        description: t('The number of keywords with search results on Naver. Cafe'),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '200px' }}>
            {formatNumberWithCommas(cell?.value || 0)}
          </div>
        ),
      },
      {
        Header: t('Number Of Naver News Articles'),
        accessor: "total_news_success",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: 'text-end align-middle',
        description: t('The number of keywords with search results on Naver. News'),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '200px' }}>
            {formatNumberWithCommas(cell?.value || 0)}
          </div>
        ),
      },
    ],
    [i18n?.language, userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  useEffect(() => {
    document.title = `${t('Collecting Monitoring')} - ${t('Monitoring')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Collecting Monitoring')} pageTitle={t('Monitoring')} />
          {isHavePermissionRole(ROLES_FOR_APP.COLLECTING_MONITORING, userPermissions) && (
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={collecting?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={4} xl={3} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                        <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                          <DatePickerCustom
                            placeholder={`${t('Start Date')} - ${t('End Date')}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                            showOptions={[
                              'today', 'yesterday', 'two_day_ago',
                              'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                              'this_week', 'last_week',
                              'this_month', 'last_month',
                            ]}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={12} xxl={4} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isCollectingLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={collecting?.list?.length ? collecting?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={collecting?.pagination?.total}
                      customPageCount={Math.ceil(Number(collecting?.pagination?.total) / Number(collecting?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isCollectingLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>
    </React.Fragment >
  );
};

export default CollectingMonitoring;