import { IKeyword } from "api/types/_keyword";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
  NavLink
} from "reactstrap";
import { createSelector } from "reselect";
import {
  deleteKeyword,
  deleteKeywordOfCategories,
  getKeyword,
  getKeywordsByCategory as onGetKeywords,
  postKeyword,
  putKeyword,
} from "../../../store/thunks";
import { IFrequency } from "api/types/_master";
import {
  CATEGORY_RANK_TYPE,
  FREQUENCY_OPTIONS,
  FREQUENCY_TYPE,
  INDEX_TYPE,
  KEY_CATEGORY_SOURCE_TYPE,
} from "helpers/constans";
import { Option } from "api/types/_public";
import DropdownOption from "components/Common/DropdownOption";
import calculatePercentages from "utils";
import ModalDetailKeyword from "../ModalDetailKeyword";
import NoImage from '../../../assets/images/no-photo-available.png';

const SORT_BY_DEFAULT = "index";
const ORDER_BY_DEFAULT = "DESC";

registerLocale("en", en);
registerLocale("ko", ko);

interface Props {
  categoryId?: string | number;
  category?: any;
  frequencies?: IFrequency[];
}

const ModalListKeyword = ({
  categoryId,
  category = {},
  frequencies = [],
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const TWO_DATE_AGO_DEFAULT = `${moment(
    new Date(new Date().setDate(new Date().getDate() - 2))
  ).format("Y-MM-DD")}`;

  const FREQUENCY_OPTIONS_LANG = FREQUENCY_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useState({
    page: 1,
    limit: 20,
    sort_by: SORT_BY_DEFAULT,
    order_by: ORDER_BY_DEFAULT,
    keyword: "",
    category_id: categoryId || "",
    frequency_type: FREQUENCY_OPTIONS_LANG[0]?.value,
    rank_type: String(CATEGORY_RANK_TYPE.POPULAR),
    date: TWO_DATE_AGO_DEFAULT,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(selectLayoutState, (state) => ({
    keywords: state.keywords,
    isKeywordSuccess: state.isKeywordSuccess,
    isKeywordLoading: state.isKeywordLoading,
    error: state.error,
  }));

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );

  const [dateSearch, setDateSearch] = useState<string>(
    query?.date || TWO_DATE_AGO_DEFAULT
  );

  const [frequencySearch, setFrequencySearch] = useState<Option | null>(
    FREQUENCY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.frequency_type)
    )[0]
  );

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<IKeyword | any | null>(null);

  const [inputKeywords, setInputKeywords] = useState<string>("");

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(
    null
  );

  const [keywordDetail, setKeywordDetail] = useState<IKeyword | null | Object>(null)

  useEffect(() => {
    if (!isKeywordLoading) {
      dispatch(onGetKeywords(query));
    }
  }, [dispatch, query]);

  const getDateRange = (
    type_picker: number | string | undefined,
    date_string: string
  ) => {
    const date = moment(date_string);
    if (String(type_picker) === String(FREQUENCY_TYPE.WEEKLY)) {
      const startDateOfWeek = date.clone().startOf("isoWeek");
      const endDateOfWeek = date.clone().endOf("isoWeek");
      return [
        startDateOfWeek.format("YYYY-MM-DD"),
        endDateOfWeek.format("YYYY-MM-DD"),
      ];
    } else if (String(type_picker) === String(FREQUENCY_TYPE.MONTHLY)) {
      const startDateOfMonth = date.clone().startOf("month");
      const endDateOfMonth = date.clone().endOf("month");
      return [
        startDateOfMonth.format("YYYY-MM-DD"),
        endDateOfMonth.format("YYYY-MM-DD"),
      ];
    } else {
      return [date_string, date_string];
    }
  };
  const searchData = () => {
    setQuery((_prev: any) => ({
      ..._prev,
      page: 1,
      keyword: keywordSearch || "",
      frequency_type: frequencySearch?.value,
      date: getDateRange(frequencySearch?.value, dateSearch)[0],
    }));
  };

  const resetData = () => {
    setQuery((_prev: any) => ({
      ..._prev,
      date: TWO_DATE_AGO_DEFAULT,
      keyword: "",
      frequency_type: FREQUENCY_OPTIONS_LANG[0]?.value,
      time_request: +new Date(),
      page: 1,
    }));
    setFrequencySearch(FREQUENCY_OPTIONS_LANG[0]);
    setDateSearch(TWO_DATE_AGO_DEFAULT);
    setKeywordSearch("");
  };

  const renderDateByFrequency = (data_str: string, frequency_type: number) => {
    if (!data_str) {
      return "";
    }
    if (String(frequency_type) === String(FREQUENCY_TYPE.DAILY)) {
      return moment(data_str).format("YYYY-MM-DD");
    }
    if (String(frequency_type) === String(FREQUENCY_TYPE.WEEKLY)) {
      return moment(data_str).format("YYYY-MM-DD") + " " + t("Week");
    }
    if (String(frequency_type) === String(FREQUENCY_TYPE.MONTHLY)) {
      return moment(data_str).format("YYYY-MM");
    }
    return data_str;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Ranking"),
        accessor: "ranking",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ width: "90px" }}>
            <div className="text-start" style={{ minWidth: "50px" }}>
              {(query?.page - 1) * query?.limit + (cell?.cell?.row?.index + 1)}
            </div>
          </div>
        ),
      },
      {
        Header: t("Date"),
        accessor: "date",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: "120px" }}>
              {String(item?.date || "").replace("week", t("Week"))}
            </div>
          );
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const info: any = item?.keyword_info;
          return (
            <div className="cursor-pointer d-flex align-items-center" style={{ minWidth: "120px" }} onClick={(e) => {
              e.stopPropagation();
              if (info) {
                handleCallKeywordDetail(item);
              }
            }}>
              {(info) &&
                <div style={{ width: '34px', height: '42px' }} className="me-2">
                  <img
                    alt={keyword?.keyword || ''}
                    loading="lazy" src={info?.image_url || info?.image || NoImage}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      backgroundColor: '#eaeaea'
                    }}
                  />
                </div>
              }
              <div>{cell?.value}</div>
            </div >
          )
        },
      },
      {
        Header: t("P Index"),
        accessor: "p_index",
        filterable: false,
        sortable: true,
        thClass: "align-middle text-end",
        description: t("Participation Index"),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t('Activity Index'),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thClass: "align-middle text-end",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Trend Index"),
        accessor: "index",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Search Index"),
        accessor: "search_index",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        thComponent: () => {
          const index_config = (category?.[String(query?.rank_type) === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs'] || [])?.find(
            (item: any) =>
              String(item?.index_type) === String(INDEX_TYPE.SEARCH_INDEX)
          );
          return (
            <>
              {" "}
              <span className="me-3">{t("Search Index")}</span> <br /> (
              {t("Weight")} {index_config?.ratio || 0} %)
            </>
          );
        },
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      // {
      //   Header: t('Social Index'),
      //   accessor: "social_index",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: '120px' }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t("Viral Index"),
        accessor: "viral_index",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        thComponent: () => {
          const index_config = (category?.[String(query?.rank_type) === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs'] || [])?.find(
            (item: any) =>
              String(item?.index_type) === String(INDEX_TYPE.VIRAL_INDEX)
          );
          return (
            <>
              <span className="me-3">{t("Viral Index")}</span> <br /> (
              {t("Weight")} {index_config?.ratio || 0} %)
            </>
          );
        },
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("News Index"),
        accessor: "news_index",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        thComponent: () => {
          const index_config = (category?.[String(query?.rank_type) === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs'] || [])?.find(
            (item: any) =>
              String(item?.index_type) === String(INDEX_TYPE.NEWS_INDEX)
          );
          return (
            <>
              <span className="me-3">{t("News Index")}</span> <br /> (
              {t("Weight")} {index_config?.ratio || 0} %)
            </>
          );
        },
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: `${"150px"}` }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },

      {
        Header: t("Gender"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 200,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={2}>
                  {t("Gender")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_male_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_male_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Male")}
                  {query?.sort_by === "search_male_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_female_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_female_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Female")}
                  {query?.sort_by === "search_female_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_male_value || 0,
            item.search_female_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_male_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_female_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "6",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Age"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 500,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={5}>
                  {t("Age")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_10_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_10_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("10")}
                  {query?.sort_by === "search_age_10_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_20_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_20_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("20")}
                  {query?.sort_by === "search_age_20_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_30_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_30_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("30")}
                  {query?.sort_by === "search_age_30_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_40_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_40_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("40")}
                  {query?.sort_by === "search_age_40_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_50_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_50_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("50")}
                  {query?.sort_by === "search_age_50_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_age_10_value || 0,
            item.search_age_20_value || 0,
            item.search_age_30_value || 0,
            item.search_age_40_value || 0,
            item.search_age_50_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_10_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_20_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_30_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[2]} %)</span>
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.search_age_40_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[3]} %)</span>
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_50_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[4]} %)</span>
              </div>
            </div>
          );
        },
      },
      // {
      //   Header: t("Created at"),
      //   accessor: "created_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || "").split(" ");
      //     return (
      //       <div className="text-end" style={{ minWidth: "100px" }}>
      //         <span>
      //           <span>{arrDate?.[0] || ""}</span> <br />{" "}
      //           <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: t("Updated at"),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || "").split(" ");
      //     return (
      //       <div className="text-end" style={{ minWidth: "100px" }}>
      //         <span>
      //           <span>{arrDate?.[0] || ""}</span> <br />{" "}
      //           <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: t("Action"),
        thClass: "text-center align-middle",
        thWidth: 80,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center" style={{ minWidth: "80px" }}>
              <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                {isHavePermissionRole(
                  ROLES_FOR_APP.KEYWORD_DELETE,
                  userPermissions
                ) && (
                    <TooltipCustom
                      title={t("Button Delete Keyword")}
                      id={`detail-cp-${item?.id}`}
                    >
                      <li className="list-inline-item" title="Remove">
                        <Link
                          className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleConfirmDelete(item);
                          }}
                        >
                          <i className="ri-delete-bin-5-fill"></i>
                        </Link>
                      </li>
                    </TooltipCustom>
                  )}
              </ul>
            </div>
          );
        },
      },
    ],
    [
      i18n?.language,
      userPermissions,
      JSON.stringify(query),
      JSON.stringify(category?.index_configs)
    ]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, page: page + 1 };
    });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) =>
      value ? moment(new Date(value)).format("Y-MM-DD") : ""
    );
  };

  const handleChangeDatePicker = (freq_type: number | string) => {
    if (String(freq_type) === String(FREQUENCY_TYPE.WEEKLY)) {
      const today = new Date();
      const lastWeek: Date = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      handleChangePicker(lastWeek);
    } else if (String(freq_type) === String(FREQUENCY_TYPE.MONTHLY)) {
      const today = new Date();
      const lastMonth: Date = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      handleChangePicker(lastMonth);
    } else if (String(freq_type) === String(FREQUENCY_TYPE.DAILY)) {
      const today = new Date();
      const twoDaysAgo: Date = new Date(today);
      twoDaysAgo.setDate(today.getDate() - 2);
      handleChangePicker(twoDaysAgo);
    } else {
      handleChangePicker(null);
    }
  };

  // Begin::Delete
  const handleConfirmDelete = (item: IKeyword) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeywordOfCategories(idDelete, {
        category_ids: [categoryId],
      });
      if (response?.code === 200) {
        dispatch(onGetKeywords(query));
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  };
  // End::Delete
  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: categoryId,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, []),
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setKeyword((prev: any) => null);
        setInputKeywords((prev: any) => "");
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        dispatch(onGetKeywords(query));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const handleCallKeywordDetail = async (item: IKeyword) => {
    try {
      setKeywordDetail(() => ({ keyword_type: item?.keyword_type }));
      const response: any = await getKeyword(item?.id);
      if (response?.code === 200) {
        setKeywordDetail(() => (response?.data));
      } else {
        setKeywordDetail(() => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setKeywordDetail(() => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordDetailClick = () => {
    setKeywordDetail(() => null);
  };

  // Arrow Nav tabs
  const arrowNavToggle = (tab: any) => {
    if (query?.rank_type !== tab) {
      setQuery((_prev: any) => ({
        ..._prev,
        rank_type: tab,
        time_request: +new Date(),
        page: 1,
      }));
    }
  };
  return (
    <React.Fragment>
      <div>
        <div>
          {isHavePermissionRole(
            ROLES_FOR_APP.KEYWORD_MANAGEMENT,
            userPermissions
          ) && (
              <Row>
                <Col sm={12}>
                  <Card
                    id="customerList"
                    style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                  >
                    <Col sm={12} style={{ borderRadius: 0, borderTopRightRadius: 'var(--vz-border-radius)', borderTopLeftRadius: 'var(--vz-border-radius)' }}>
                      <Nav pills className="nav nav-pills nav-custom nav-custom-light mb-0" style={{
                        borderTopRightRadius: 'var(--vz-border-radius)', borderTopLeftRadius: 'var(--vz-border-radius)',
                        borderBottomRightRadius: 0, borderBottomLeftRadius: 0
                      }}>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer", borderRadius: 0, borderTopLeftRadius: 'var(--vz-border-radius)', borderTopRightRadius: 'var(--vz-border-radius)', }}
                            className={classnames({
                              active: query?.rank_type === String(CATEGORY_RANK_TYPE.POPULAR),
                            })}
                            onClick={() => {
                              arrowNavToggle(String(CATEGORY_RANK_TYPE.POPULAR));
                            }}
                          >
                            {t('Popular')}
                          </NavLink>
                        </NavItem>
                        {!!category?.issue_index_configs?.length && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer", borderRadius: 0, borderTopLeftRadius: 'var(--vz-border-radius)', borderTopRightRadius: 'var(--vz-border-radius)', }}
                              className={classnames({
                                active: query?.rank_type === String(CATEGORY_RANK_TYPE.ISSUE),
                              })}
                              onClick={() => {
                                arrowNavToggle(String(CATEGORY_RANK_TYPE.ISSUE));
                              }}
                            >
                              {t('Issue')}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Col>
                    <CardHeader className="border-0">
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              <span className="me-2">{t("Total")}:</span>
                              <CountUp
                                start={0}
                                end={keywords?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </h5>
                          </div>
                        </div>
                        {isHavePermissionRole(
                          ROLES_FOR_APP.KEYWORD_CREATE,
                          userPermissions
                        ) && (
                            <div className="col-sm-auto">
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-success add-btn rounded-pill"
                                  id="create-btn"
                                  onClick={() => setKeyword((prev: any) => ({}))}
                                >
                                  <i className="ri-add-line align-bottom me-1"></i>
                                  {t("Button Create Keyword")}
                                </button>
                              </div>
                            </div>
                          )}
                      </Row>
                    </CardHeader>
                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={4}
                          md={4}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Keyword")}
                            isShow={!!keywordSearch}
                          >
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Keyword")}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Frequency")}
                            isShow={!!frequencySearch?.value}
                          >
                            <DropdownOption
                              name="frequency"
                              dataList={FREQUENCY_OPTIONS_LANG || []}
                              placeholder={`${t("Frequency")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={
                                frequencySearch || FREQUENCY_OPTIONS_LANG[0]
                              }
                              onChangeSelect={(e: any) => {
                                setFrequencySearch(e);
                                handleChangeDatePicker(e?.value);
                              }}
                              isHasOptionAll={false}
                            // optionAll={{ label: t('All Type'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          xl={3}
                          xxl={2}
                          className="date-picker-wrapper-custom mt-3 mt-md-2"
                        >
                          <LabelWrapper label={t("Date")} isShow={true}>
                            <DatePicker
                              className="form-control search"
                              placeholderText={`${t("Date")}`}
                              // value={dateSearch || undefined}
                              selected={
                                dateSearch ? new Date(dateSearch) : undefined
                              }
                              dateFormat={
                                String(frequencySearch?.value) ===
                                  String(FREQUENCY_TYPE.MONTHLY)
                                  ? "yyyy-MM"
                                  : String(frequencySearch?.value) ===
                                    String(FREQUENCY_TYPE.WEEKLY)
                                    ? `yyyy-MM I'${t("week")}'`
                                    : "yyyy-MM-dd"
                              }
                              calendarStartDay={
                                String(frequencySearch?.value) ===
                                  String(FREQUENCY_TYPE.WEEKLY)
                                  ? 1
                                  : 0
                              }
                              showWeekNumbers={
                                String(frequencySearch?.value) ===
                                String(FREQUENCY_TYPE.WEEKLY)
                              }
                              weekLabel={t("Week")}
                              onWeekSelect={(
                                firstDayOfWeek: Date,
                                weekNumber: string | number,
                                event: React.SyntheticEvent<any> | undefined
                              ) => {
                                handleChangePicker(firstDayOfWeek);
                              }}
                              showMonthYearPicker={
                                String(frequencySearch?.value) ===
                                String(FREQUENCY_TYPE.MONTHLY)
                              }
                              showFullMonthYearPicker={
                                String(frequencySearch?.value) ===
                                String(FREQUENCY_TYPE.MONTHLY)
                              }
                              showTwoColumnMonthYearPicker={
                                String(frequencySearch?.value) ===
                                String(FREQUENCY_TYPE.MONTHLY)
                              }
                              isClearable={true}
                              // maxDate={new Date()}
                              locale={i18n?.language === "ko" ? "ko" : "en"}
                              onChange={handleChangePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          xl={3}
                          xxl={6}
                          className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={searchData}
                              disabled={isKeywordLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={keywords?.list?.length ? keywords?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={keywords?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(keywords?.pagination?.total) /
                          Number(keywords?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordLoading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <ToastContainer closeButton={false} limit={1} />
        </div>
        <Modal
          isOpen={keyword !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setKeyword(null)}>
            {t("Button Create Keyword")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Category")}
                </Col>
                <Col sm={12} md={10} lg={10} className="mt-3 mt-sm-3 mt-md-2">
                  <div className="text-muted fs-14">
                    {(category?.group_categories || [])
                      ?.map((item: any) => item?.name)
                      .join(" > ")}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Keyword")}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords(
                        (prev: any) => event?.target?.value || ""
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => {
                    !isLoading && setKeyword(null);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || !categoryId || !inputKeywords}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: "100px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    t("Button Update")
                  )}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t("Button Delete Keyword")}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />

        <ModalDetailKeyword
          header={t("Button Delete Category")}
          isShow={!!keywordDetail}
          keyword={keywordDetail}
          onClose={onCloseKeywordDetailClick}
        />
      </div>
    </React.Fragment>
  );
};

export default ModalListKeyword;
