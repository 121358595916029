import { IKeyword } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CollapseTag from 'components/Common/CollapseTag';
import CopyWrapper from 'components/Common/CopyWrapper';
import DropdownCategory from 'components/Common/DropdownCategory';
import DropdownOptionCategory from 'components/Common/DropdownOptionCategory';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { CATEGORY_TYPE, COLORS_KEYWORD_STATUS, STATUS_KEYWORD_DAILY_OPTIONS, STATUS_KEYWORD_INIT_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Input, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { deleteKeyword, deleteKeywordOfCategories, getAllCategoriesNormal, getKeywordSummariesStatus, getKeywords as onGetKeywords, postKeyword, putKeyword } from "../../../store/thunks";

const typeQuery = {
  'key': StringParam,
}


const SORT_BY_DEFAULT = 'search_index';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale("en", en);
registerLocale("ko", ko);

interface Props {
  titlePage: string,
  typeCategory: CATEGORY_TYPE
}

const TrendIndexKeywordManagement = ({ typeCategory, titlePage }: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { durationResponses, setDurationResponses } = useDurationResponses();
  const isFirstLoadingPageRef = useRef<any>(true);

  const TWO_DATE_AGO_DEFAULT = `${moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD")}`;

  const STATUS_KEYWORD_INIT_OPTIONS_LANG = STATUS_KEYWORD_INIT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const STATUS_KEYWORD_DAILY_OPTIONS_LANG = STATUS_KEYWORD_DAILY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    type: withDefault(NumberParam, typeCategory),
    keyword: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, ''),
    date: withDefault(StringParam, TWO_DATE_AGO_DEFAULT),
    init_status: withDefault(StringParam, ''),
    daily_status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywords,
      isKeywordSuccess: state.isKeywordSuccess,
      isKeywordLoading: state.isKeywordLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [initStatusSearch, setInitStatusSearch] = useState<Option | null>(STATUS_KEYWORD_INIT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.init_status))[0]);
  const [dailyStatusSearch, setDailyStatusSearch] = useState<Option | null>(STATUS_KEYWORD_DAILY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.daily_status))[0]);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [dateSearch, setDateSearch] = useState<string>(query?.date || TWO_DATE_AGO_DEFAULT);

  const [itemDelete, setItemDelete] = useState<any | null>(null);
  const [keywordDelete, setKeywordDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<IKeyword | any | null>(null);
  const [categorySearch, setCategorySearch] = useState<any | null>(null);

  const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<any>([]);
  const [categoryDropdown, setCategoryDropdown] = useState<any>({});
  const [inputKeywords, setInputKeywords] = useState<string>('');

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(null);

  const [summariesLogs, setSummariesLogs] = useState<{
    init_status: { '10': number, '20': number, '30': number, '40': number, '50': number, '90': number, '99': number },
    daily_status: { '10': number, '20': number, '30': number, '40': number, '50': number, '90': number, '99': number }
  }>({
    init_status: { '10': 0, '20': 0, '30': 0, '40': 0, '50': 0, '90': 0, '99': 0 },
    daily_status: { '10': 0, '20': 0, '30': 0, '40': 0, '50': 0, '90': 0, '99': 0 }
  });
  const [isSummariesLogLoading, setIsSummariesLogLoading] = useState<boolean>(false);

  const searchData = () => {
    if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
      setQuery({
        ...query,
        page: 1
      });
      return;
    }
    setQuery({
      ...query,
      type: typeCategory,
      page: 1,
      keyword: keywordSearch || '',
      category_id: categorySearch?.value || '',
      date: dateSearch,
      init_status: initStatusSearch?.value || '',
      daily_status: dailyStatusSearch?.value || '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      type: typeCategory,
      date: TWO_DATE_AGO_DEFAULT,
      keyword: '',
      category_id: '',
      init_status: '',
      daily_status: '',
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch(TWO_DATE_AGO_DEFAULT);
    setKeywordSearch("");
    setCategorySearch(null);
    setInitStatusSearch(null);
    setDailyStatusSearch(null);
  };

  const handleQuerySummariesData = async () => {
    try {
      isFirstLoadingPageRef.current = false;
      setIsSummariesLogLoading((prev) => true);
      const res: any = await getKeywordSummariesStatus({
        type: query?.type,
        keyword: query?.keyword,
        category_id: query?.category_id,
        date: query?.date,
        init_status: query?.init_status,
        daily_status: query?.daily_status,
      });
      setSummariesLogs((prev: any) => ({
        init_status: res?.data?.init_status?.reduce((ob: any, item: any) => ({
          ...ob,
          [`${item?.status}`]: item?.count
        }), {}),
        daily_status: res?.data?.daily_status?.reduce((ob: any, item: any) => ({
          ...ob,
          [`${item?.status}`]: item?.count
        }), {})
      }));
      setIsSummariesLogLoading((prev) => false);
    } catch (error: any) {
      setIsSummariesLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  useEffect(() => {
    dispatch(onGetKeywords(query));
  }, [dispatch, query]);

  useEffect(() => {
    (query?.page === 1) && handleQuerySummariesData();
  }, [JSON.stringify(query)]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Ranking'),
        accessor: "ranking",
        filterable: false,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <div style={{ width: '80px' }}>
            <div className="text-start" style={{ minWidth: '50px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
          </div>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => (
          <div style={{ minWidth: '140px' }}>
            <CopyWrapper contentCopy={cell?.value} >
              {cell?.value}
            </CopyWrapper>
          </div>
        ),
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 280,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(category?.name);
              categoryName.push(subs.join(` > `));
            }
          }

          return (
            <div style={{ minWidth: '280px' }}>
              <CollapseTag tags={categoryName} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />
            </div>
          );
        },
      },
      {
        Header: t('Init Status'),
        accessor: "init_status",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '100px' }}>
              <span className={`rounded-pill badge bg-${COLORS_KEYWORD_STATUS[String(cell?.value)] || 'secondary'}`}>{STATUS_KEYWORD_INIT_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('Daily Status'),
        accessor: "daily_status",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '100px' }}>
              <span className={`rounded-pill badge bg-${COLORS_KEYWORD_STATUS[String(cell?.value)] || 'secondary'}`}>{STATUS_KEYWORD_DAILY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('P Index'),
        accessor: "p_index",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Participation Index'),
        thWidth: 120,
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t('Activity Index'),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },

      // {
      //   Header: t('Trend Index'),
      //   accessor: "trend_index",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: '100px' }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t('Search Index'),
        accessor: "search_index",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      // {
      //   Header: t('Social Index'),
      //   accessor: "social_index",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: '120px' }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t('Viral Index'),
        accessor: "viral_index",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t('News Index'),
        accessor: "news_index",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: `${'150px'}` }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      // {
      //   Header: t('Participation Index'),
      //   accessor: "participation_index",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('Recent Change'),
      //   accessor: "recent_change",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 120 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '120px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 80,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                {/* {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_UPDATE, userPermissions) && (<TooltipCustom
                  title={t('Button Update Keyword')}
                  id={`update-cp-${item?.id}`}
                >
                  <li className="list-inline-item edit " title="Edit">
                    <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setItemUpdateKeyword((_prev) => item);
                      }}>
                      <i className="ri-pencil-fill"></i>
                    </Link>
                  </li>
                </TooltipCustom>)} */}
                {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_DELETE, userPermissions) && (<TooltipCustom
                  title={t('Button Delete Keyword On Categories')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" title="Remove">
                    <Link className="btn btn-sm btn-soft-warning d-inline-block edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-5-fill"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}

                {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_DELETE, userPermissions) && (<TooltipCustom
                  title={t('Button Delete Keyword')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" title="Remove">
                    <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDeleteKeyword(item) }}
                    >
                      <i className="ri-delete-bin-5-fill"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}
              </ul>
            </div>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IKeyword) => {
    setItemDelete((_prev: any) => ({ ...item, categories_delete: [] }));
  };

  const handleActionUpdateSelectCategory = (idCate: any, isChecked: boolean) => {
    setItemDelete((_prev: any) => ({
      ..._prev,
      categories_delete: isChecked ? [..._prev?.categories_delete, idCate] : [..._prev?.categories_delete]?.filter((c) => c !== idCate),
    }));
  };

  const handleActionDelete = async () => {
    if (!itemDelete?.categories_delete?.length) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeywordOfCategories(itemDelete?.id, { category_ids: itemDelete?.categories_delete || [] });
      if (response?.code === 200) {
        dispatch(onGetKeywords(query));
        setIsConfirmLoading((_prev) => false);
        setItemDelete((_prev: any) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setItemDelete((_prev: any) => null);
  }
  // End::Delete


  // Begin::Delete
  const handleConfirmDeleteKeyword = (item: IKeyword) => {
    setKeywordDelete((_prev: any) => item?.id);
  };

  const handleActionDeleteKeyword = async () => {
    if (!keywordDelete) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeyword(keywordDelete);
      if (response?.code === 200) {
        dispatch(onGetKeywords(query));
        setIsConfirmLoading((_prev) => false);
        setKeywordDelete((_prev: any) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordConfirmClick = () => {
    setKeywordDelete((_prev: any) => null);
  }
  // End::Delete


  const handleUpdateKeyword = async () => {
    try {
      if (!itemUpdateKeyword) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putKeyword(itemUpdateKeyword?.id, { keyword: itemUpdateKeyword?.keyword });
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeyword((_prev) => null);
        dispatch(onGetKeywords(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: categoryDropdown?.level_5?.value || categoryDropdown?.level_4?.value || categoryDropdown?.level_3?.value || categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, [])
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setKeyword((prev: any) => null);
        setCategoryDropdown((prev: any) => ({}));
        setInputKeywords((prev: any) => '');
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        dispatch(onGetKeywords(query));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }


  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res]: any = await Promise.all([getAllCategoriesNormal({ type: typeCategory })]);
        setOptionCategoryDropdown((_prev: any) => res?.data || []);
      } catch (error: any) {
        return error;
      }
    };
    if (typeCategory) {
      handleCallAllOption();
    }

  }, [typeCategory]);

  useEffect(() => {
    document.title = `${t(titlePage)} - ${t('Keyword Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t(titlePage)} pageTitle={t('Keyword Management')} />
          {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_MANAGEMENT, userPermissions) && (
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <div className=" border border-dashed border-top-0 border-end-0 border-start-0 card-body mt-0 pb-3">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={keywords?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_CREATE, userPermissions) && (<div className="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            className="btn btn-success add-btn rounded-pill"
                            id="create-btn"
                            onClick={() => setKeyword((prev: any) => ({}))}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {t('Button Create Keyword')}
                          </button>
                        </div>
                      </div>)}
                    </Row>
                  </div>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row>
                      <Col md={12} xl={12} xxl={6}>
                        <div className="d-flex w-100 flex-wrap gap-2 gap-md-0 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                          <span className="position-absolute text-primary fs-13" style={{
                            top: 0,
                            left: 10,
                            transform: 'translate(-5px, -55%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 2px',
                            zIndex: 1,
                          }}>{t('Init Status')}</span>
                          {STATUS_KEYWORD_INIT_OPTIONS_LANG?.map((item) => (
                            <Card className="card-animate card-animate-small mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0" key={item?.value}>
                              <CardBody className="p-2">
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{item?.label}</p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-0 pt-0">
                                  <div>
                                    <h4 className="fs-18 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                          <CountUp
                                            start={0}
                                            end={(summariesLogs as any)?.init_status?.[item?.value] || 0}
                                            duration={1}
                                          />
                                        )}
                                      </span></h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          ))}
                        </div>
                      </Col>
                      <Col md={12} xl={12} xxl={6}>
                        <div className="d-flex w-100 flex-wrap gap-2 gap-md-0 menu-card-statistics justify-content-start mb-0 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                          <span className="position-absolute text-primary fs-13" style={{
                            top: 0,
                            left: 10,
                            transform: 'translate(-5px, -55%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 2px',
                            zIndex: 1,
                          }}>{t('Daily Status')}</span>
                          {STATUS_KEYWORD_DAILY_OPTIONS_LANG?.map((item) => (
                            <Card className="card-animate card-animate-small mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0" key={item?.value}>
                              <CardBody className="p-2">
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{item?.label}</p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-0 pt-0">
                                  <div>
                                    <h4 className="fs-18 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                          <CountUp
                                            start={0}
                                            end={(summariesLogs as any)?.daily_status?.[item?.value] || 0}
                                            duration={1}
                                          />
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={4} xl={6} xxl={2} className='mt-3 mt-md-3'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={8} xl={6} xxl={4} className='mt-3 mt-md-3'>
                        <DropdownCategory
                          name="category"
                          placeholder={t('Category')}
                          isClearable={true}
                          dataList={optionCategoryDropdown || []}
                          initialValue={categorySearch ?? undefined}
                          onChangeCategory={(event) => {
                            setCategorySearch(event)
                          }}
                        />
                      </Col>
                      <Col sm={12} md={4} xl={4} xxl={2} className='mt-3 mt-md-3'>
                        <LabelWrapper label={t('Init Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_KEYWORD_INIT_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={initStatusSearch || null}
                            onChangeSelect={(e: any) => setInitStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Status'), value: '' }}
                            otherColor="dark"
                            colors={COLORS_KEYWORD_STATUS}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={4} xl={4} xxl={2} className='mt-3 mt-md-3'>
                        <LabelWrapper label={t('Daily Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_KEYWORD_DAILY_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={dailyStatusSearch || null}
                            onChangeSelect={(e: any) => setDailyStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Status'), value: '' }}
                            otherColor="dark"
                            colors={COLORS_KEYWORD_STATUS}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={4} xl={4} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-3'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('Date')}`}
                            value={dateSearch || undefined}
                            selected={!!dateSearch ? new Date(dateSearch) : undefined}
                            dateFormat="yyyy-MM-dd"
                            isClearable={false}
                            maxDate={new Date()}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-xl-end mt-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={keywords?.list?.length ? keywords?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
        <Modal
          isOpen={keyword !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setKeyword(null)}>
            {t('Button Create Keyword')}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Category')}
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 1')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={optionCategoryDropdown || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_1 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_1: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 2')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_1?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_2 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_2: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 3')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_2?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_3 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_3: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 4')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_3?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_4 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_4: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 5')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_4?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_5 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({ ...prev, level_5: e }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Keyword')}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords((prev: any) => (event?.target?.value || ''))
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => { !isLoading && setKeyword(null) }}
                  style={{ minWidth: '100px' }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || (!categoryDropdown?.level_1?.value || !inputKeywords)}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: '100px' }}
                >
                  {isLoading ? (
                    <Spinner size="sm" ></Spinner>
                  ) : t("Button Update")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={itemUpdateKeyword !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeyword((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeyword((_prev) => null)}>
            {t('Button Update Keyword')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="keyword"
                  name="keyword"
                  autocomplete={false}
                  value={itemUpdateKeyword?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeyword((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  style={{ width: '100px' }}
                  onClick={() => { !isLoading && setItemUpdateKeyword((_prev) => null) }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoading}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeyword()}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!itemDelete}
          centered
          size="xl"
          scrollable={true}
          toggle={onCloseConfirmClick}
          keyboard={true}
        >
          <ModalHeader toggle={onCloseConfirmClick}>
            {t('Button Delete Keyword On Categories')}
          </ModalHeader>
          <ModalBody className="text-center">
            <div className="row g-4 w-100">
              <Col md={12} className="mt-2">
                <div className="d-flex flex-row flex-wrap">
                  {itemDelete?.categories?.map((item: any, index: number) => (
                    <ListGroupItem
                      key={index}
                      tag="label"
                      className="px-4 py-3 text-start item-keyword-suggestion"
                    >
                      {
                        item?.group_categories?.map((cate: any, i: number) => (
                          <div className="form-check notification-check" key={`${cate?.id}-${i}`}>
                            <input className="form-check-input" type="checkbox" value="" id={`check-${cate?.id}-${i}`}
                              checked={!!(itemDelete?.categories_delete || []).includes(cate?.id)}
                              onChange={(event: any) => {
                                handleActionUpdateSelectCategory(cate?.id, !!(event?.target.checked));
                              }}
                            />
                            <label className="form-check-label" htmlFor={`check-${cate?.id}-${i}`}><span className="text-muted me-1">{i + 1}.</span>{cate?.name}</label>
                          </div>
                        ))
                      }
                      <div className="form-check notification-check" key={`${item?.id}-${index}`}>
                        <input className="form-check-input" type="checkbox" value="" id={`check-${item?.id}-${index}`}
                          checked={!!(itemDelete?.categories_delete || []).includes(item?.id)}
                          onChange={(event: any) => {
                            handleActionUpdateSelectCategory(item?.id, !!(event?.target.checked));
                          }}
                        />
                        <label className="form-check-label" htmlFor={`check-${item?.id}-${index}`}><span className="text-muted me-1">{item?.group_categories?.length + 1}.</span>{item?.name}</label>
                      </div>
                    </ListGroupItem>
                  ))}
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <button
                    className={`btn btn-soft-warning fs-14 me-2`}
                    type="button"
                    disabled={isConfirmLoading}
                    onClick={handleActionDelete}
                    style={{ minWidth: '100px' }}
                  >
                    {isConfirmLoading ? (
                      <Spinner size="sm" className="me-2"></Spinner>
                    ) : (
                      <i
                        className={`ri-delete-bin-3-line align-bottom me-1`}
                      ></i>
                    )}
                    {t("Button Delete Keyword")}
                  </button>
                  <button
                    className="btn btn-light fs-14 me-2"
                    type="button"
                    color="light"
                    onClick={onCloseConfirmClick}
                  >
                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                    {t("Button Close")}
                  </button>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Keyword')}
          isOpen={keywordDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseKeywordConfirmClick}
          onConfirm={handleActionDeleteKeyword}
        />
      </div>
    </React.Fragment >
  );
};

export default TrendIndexKeywordManagement;