import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IMember } from "./types/_member";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/users';
const memberApi = {
  members(params: any):Promise<AxiosResponse<ResponseData<IMember[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getMember(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IMember>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postMember(dataForm: any) : Promise<AxiosResponse<ResponseData<IMember>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putMember(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMember>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteMember(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMember>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default memberApi
