import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Global
import GlobalReducer from "./global/reducer";

// Configuration
import ConfigurationReducer from "./configuration/reducer";

// Category
import CategoryReducer from "./category/reducer";

// Master
import MasterReducer from "./master/reducer";

// Keyword
import KeywordReducer from "./keyword/reducer";

// TrendIndex
import TrendIndexReducer from "./trendindex/reducer";

// Statistic
import StatisticReducer from "./statistic/reducer";

// Media
import MediaReducer from "./media/reducer";

// Member
import MemberReducer from "./member/reducer";

//Participation
import ParticipationReducer from "./participation/reducer";

//Payment
import PaymentReducer from "./payment/reducer";

//Account
import AccountReducer from "./account/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Dashboard: DashboardReducer,
    User: UserReducer,
    Role: RoleReducer,
    Global: GlobalReducer,
    Configuration: ConfigurationReducer,
    Category: CategoryReducer,
    Keyword: KeywordReducer,
    Master: MasterReducer,
    TrendIndex: TrendIndexReducer,
    Statistic: StatisticReducer,
    Media: MediaReducer,
    Member: MemberReducer,
    Participation: ParticipationReducer,
    Payment: PaymentReducer,
    Account: AccountReducer,
});

export default rootReducer;
