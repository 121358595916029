import { KEYWORD_SOURCE_TYPE } from "helpers/constans";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import InfoByType from "./InfoByType";

const ModalDetailKeyword = ({ isShow = false, keyword = {}, onClose }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    const keyword_info: any = keyword?.keyword_info;
    const keyword_type = keyword?.keyword_type;

    return (
        <>
            <Modal
                isOpen={!!isShow}
                centered={true}
                scrollable={false}
                toggle={onHide}
                size="lg"
            >
                <ModalHeader toggle={onHide}>
                    {t('Keyword Description')}
                </ModalHeader>
                <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
                    <InfoByType info={keyword_info} source_type={keyword_type} />
                </ModalBody>
            </Modal>
        </>
    )
}

export default React.memo(ModalDetailKeyword);