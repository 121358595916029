import { IUserActivityLog } from 'api/types/_logging';
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownMethod from 'components/Common/DropdownMethod';
import LabelWrapper from 'components/Common/LabelWrapper';
import SearchFilterUser from "components/Common/SearchFilterUser";
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import { METHOD_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { getUserActivityLogsByNormal } from 'store/logging/thunk';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { renderUrlSearchLookingUpByIP } from 'helpers/format';
import { IRole } from 'api/types/_role';
import { IMenu } from 'api/types/_master';
import { getAllMenus, getAllRoles } from 'store/thunks';
import DropdownOption from 'components/Common/DropdownOption';
import { ROUTE_NAMES } from 'routes/allRoutes';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = '';


const COLORS_METHOD: any = { GET: 'success', POST: 'primary', PUT: 'warning', DELETE: 'danger' };

const LogList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);

  const METHOD_OPTIONS_LANG = METHOD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    method: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    url: withDefault(StringParam, ''),
    user_id: withDefault(StringParam, ''),
    role_id: withDefault(StringParam, ''),
    menu: withDefault(StringParam, ''),
    from_created_at: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    to_created_at: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.from_created_at || "", 'Y-MM-DD').toDate(), moment(query?.to_created_at || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.url || "");

  const [methodSearch, setMethodSearch] = useState<Option | null>(METHOD_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.method))[0]);

  const [userSearch, setUserSearch] = useState<Option | null>(null);

  const [roleSearch, setRoleSearch] = useState<Option | null>(null);

  const [menuSearch, setMenuSearch] = useState<Option | null>(null);

  const [bodyData, setBodyData] = useState<string>('');

  const [listRole, setListRole] = useState<IRole[]>([]);

  const [listMenu, setListMenu] = useState<IMenu[]>(ROUTE_NAMES || []);

  // Inside your component

  const [userActivityLogs, setWebsiteLogs] = useState<{ list: IUserActivityLog[], total: number }>({ list: [], total: 0 });

  const [isUserActivityLogLoading, setIsUserActivityLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    setIsUserActivityLogLoading((prev) => true);
    if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
      setQuery({
        ...query,
        page: 1
      });
      return;
    }
    isFirstLoadingPageRef.current = false;
    const res: any = await getUserActivityLogsByNormal(query);
    setWebsiteLogs((prev: any) => ({
      list: (prev?.list || []).concat(res?.data?.list || []),
      total: res?.data?.pagination?.total || 0,
    }));
    setIsUserActivityLogLoading((prev) => false);
    setDurationResponses([{
      name: 'Activity Logs',
      time: res?.data?.pagination?.duration || 0
    }]);
  };


  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      ip: ipSearch || "",
      url: urlSearch || '',
      user_id: userSearch?.value || "",
      role_id: roleSearch?.value || "",
      menu: menuSearch?.value || "",
      method: methodSearch?.value || '',
      from_created_at: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      to_created_at: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setWebsiteLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      ip: '',
      url: '',
      user_id: '',
      role_id: '',
      menu: '',
      sort_by: TYPE_SELECT_DEFAULT,
      from_created_at: moment(new Date()).format("Y-MM-DD"),
      to_created_at: moment(new Date()).format("Y-MM-DD"),
      method: '',
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setWebsiteLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew, "push");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setUserSearch(null);
    setRoleSearch(null);
    setMenuSearch(null);
    setDateSearch([moment(queryNew?.from_created_at || "", 'Y-MM-DD').toDate(), moment(queryNew?.to_created_at || "", 'Y-MM-DD').toDate()]);
    setMethodSearch(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('#'),
        accessor: "stt",
        filterable: false,
        sortable: true,
        thWidth: 70,
        thClass: 'text-start',
        Cell: (cell: any) => {
          return (<>
            <div className="text-start" style={{ minWidth: '50px' }}>{cell?.cell?.row?.index + 1}</div>
          </>)
        },
      },
      {
        Header: t('Role'),
        accessor: "user.role_id",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => {
          const role = (listRole || [])?.find((item: any) => String(item?.id) === String(cell?.value)) || null;
          return (
            <>
              <div style={{ minWidth: '100px' }}>
                {role?.name || ''}
              </div>
            </>
          );
        },
      },
      {
        Header: t('Operator'),
        accessor: "user",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<>
            {!!item?.user_id ? <div style={{ minWidth: '120px' }}>
              {item?.user?.first_name || ''} {item?.user?.last_name || ''} <br /> <span className="fs-11 text-muted">(ID: {item?.user_id})</span>
            </div> : <></>}
          </>);
        },
      },
      {
        Header: t('IP Address'),
        accessor: "ip",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '100px' }}>
              <Link className='text-normal flex-shrink-0 ' to={renderUrlSearchLookingUpByIP(cell?.value)} target="_blank">{cell?.value}</Link>
            </CopyWrapper>
          </>
        ),
      },
      {
        Header: t('Method'),
        accessor: "method",
        filterable: true,
        sortable: false,
        thClass: 'text-center',
        thWidth: 110,
        Cell: (cell: any) => {
          return <>
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`text-center badge bg-${COLORS_METHOD[cell?.value] || 'secondary'} `}>{cell?.value}</span>
            </div>
          </>
        },
      },
      {
        Header: t('Url'),
        accessor: "url",
        filterable: true,
        sortable: false,
        thWidth: 600,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ maxWidth: '550px' }}>
              {cell?.value}
            </CopyWrapper>
          </>
        ),
      },
      {
        Header: t('Body'),
        accessor: "body",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<div style={{ minWidth: '200px' }}>
            {item?.body ? <div className="list-inline-item text-primary cursor-pointer" id={`detail-md-${item?.id}`} onClick={() => setBodyData((_prev) => item?.body)}>
              {t('Show Detail JSON Body')}
            </div> : <></>}
          </div>);
        },
      },
      {
        Header: t('Menu Name'),
        accessor: "menu",
        filterable: true,
        sortable: false,
        thWidth: 160,
        thClass: 'text-start',
        Cell: (cell: any) => {
          const menu = (listMenu || [])?.find((item) => item?.link === cell?.value) || null;
          return (
            <>
              <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '160px', textAlign: 'start' }}>
                {!!menu ? t(`${menu?.name}`) : (cell?.value || '')}
              </CopyWrapper>
            </>
          )
        },
      },
      {
        Header: t('Browser Information'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        thWidth: 180,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '150px', textAlign: 'center' }}>
              {cell?.value}
            </CopyWrapper>
          </>
        ),
      },
      {
        Header: t('Operating System'),
        accessor: "operating_system",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '130px', textAlign: 'center' }}>
              {cell?.value}
            </CopyWrapper>
          </>
        ),
      },
      {
        Header: t('Device Model'),
        accessor: "device_model",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '100px', textAlign: 'center' }}>
              {cell?.value}
            </CopyWrapper>
          </>
        ),
      },

      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, COLORS_METHOD, JSON.stringify(listRole)]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resRole]: any = await Promise.all([getAllRoles()]);
        if (resRole?.data) {
          setListRole((_prev) => resRole?.data);
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (methodSearch) {
      setMethodSearch((_prev: any) =>
        METHOD_OPTIONS_LANG?.filter((e: any) => e.value === methodSearch?.value)[0]
      );
    }
    if (!roleSearch?.value) {
      setRoleSearch((_prev: any) => ({ label: t('All Role'), value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Activity Logs')} - ${t('Operator Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Activity Logs')} pageTitle={t('Operator Management')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.LOG_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isUserActivityLogLoading && query?.page === 1) ? <Spinner size="sm" className="text-primary"></Spinner> : (<CountUp
                              start={0}
                              end={userActivityLogs?.total || 0}
                              duration={1}
                              className="text-primary"
                            />)}
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3'>
                        <LabelWrapper label={t('Role')} isShow={!!roleSearch?.value}>
                          <DropdownOption
                            name="role"
                            dataList={listRole?.map((item) => ({ value: String(item?.id), label: item?.name }))}
                            placeholder={`${t("Role")}...`}
                            className="search-filter-category-type dropdown-status-rounded "
                            classNamePrefix="name-prefix"
                            initialValue={roleSearch || null}
                            onChangeSelect={(e: any) => setRoleSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Role'), value: '' }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3'>
                        <LabelWrapper label={t('Menu')} isShow={!!menuSearch?.value}>
                          <DropdownOption
                            name="menu"
                            dataList={listMenu?.map((item) => ({ value: item?.link, label: t(`${item?.name}`) }))}
                            placeholder={`${t("Role")}...`}
                            className="search-filter-category-type dropdown-status-rounded "
                            classNamePrefix="name-prefix"
                            initialValue={menuSearch || null}
                            onChangeSelect={(e: any) => setMenuSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Menu'), value: '' }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3'>
                        <LabelWrapper label={t('Method')} isShow={!!methodSearch?.value}>
                          <DropdownMethod
                            name="method"
                            dataList={METHOD_OPTIONS_LANG || []}
                            placeholder={`${t("Method")}...`}
                            className="search-filter-category-type dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={methodSearch || null}
                            onChangeSelect={(e: any) => setMethodSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Method'), value: '' }}
                            colors={COLORS_METHOD}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={3} className="mt-3 date-picker-wrapper-custom">
                        <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={3} xxl={4} className='mt-3'>
                        <LabelWrapper label={t('Url')} isShow={!!urlSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Url')}...`}
                            value={urlSearch}
                            onChange={(e) => setUrlSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3'>
                        <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('IP')}...`}
                            value={ipSearch}
                            onChange={(e) => setIpSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={3} className='mt-3'>
                        <LabelWrapper label={t('Operator')} isShow={!!userSearch?.value}>
                          <SearchFilterUser
                            limitShow={10}
                            initialValue={userSearch}
                            isClearable={true}
                            onChangeSelect={(event: any) => {
                              setUserSearch((_prev: any) => event);
                            }}
                            placeholder={`${t('Operator')}`}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-xl-start mt-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isUserActivityLogLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0 px-0">
                    <InfiniteScroll
                      dataLength={userActivityLogs?.list?.length || 0}
                      next={handleLoadMore}
                      scrollableTarget='scrollableDiv'
                      hasMore={userActivityLogs && userActivityLogs?.list?.length < userActivityLogs?.total ? true : false}
                      loader={''} // loader={<LoadingListNotify />}
                      scrollThreshold={'50%'}
                    >
                      <div className="mx-3 my-4 mt-3">
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle table-bordered-dashed"
                          theadClass="table-light text-muted"
                          columns={columns}
                          data={userActivityLogs?.list?.length ? userActivityLogs?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={userActivityLogs?.total}
                          customPageCount={1}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isUserActivityLogLoading}
                          isShowPagination={false}
                          isShowLoadingBottom={query.page > 1}
                          isScrollToTop={false}
                        />
                      </div>
                    </InfiniteScroll>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
        <Modal
          isOpen={!!bodyData}
          centered={true}
          size="xl"
          scrollable={false}
        >
          <ModalHeader toggle={() => setBodyData((_prev) => '')}>
            {t("Show Detail JSON Body")}
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <code>{bodyData}</code>
            </div>
          </ModalBody>
        </Modal>
      </div >
    </React.Fragment >
  );
};

export default LogList;
