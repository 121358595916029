import { ICategory } from 'api/types/_category';
import { IDataSource, IFrequency } from 'api/types/_master';
import { Option } from 'api/types/_public';
import { ISearchIndex } from 'api/types/_trendindex';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CopyWrapper from 'components/Common/CopyWrapper';
import DropdownOption from 'components/Common/DropdownOption';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { STATUS_TREND_INDEX_OPTIONS, TREND_INDEX_TYPE } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { deleteTrendIndex, getDataSources, getFrequencies, getNewsIndexes as onGetNewsIndexes } from "../../../store/thunks";
import Form from './Form';

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale("en", en);
registerLocale("ko", ko);

const NewsIndexManagement = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_TREND_INDEX_OPTIONS_LANG = STATUS_TREND_INDEX_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        index_type: withDefault(StringParam, String(TREND_INDEX_TYPE.NEWS_INDEX)),
        frequency_type: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.TrendIndex;

    const TrendIndexProperties = createSelector(
        selectLayoutState,
        (state) => ({
            newsIndexes: state.newsIndexes,
            isNewsIndexSuccess: state.isNewsIndexSuccess,
            isNewsIndexLoading: state.isNewsIndexLoading,
            error: state.error,
        })
    );

    // Inside your component
    const { newsIndexes, error, isNewsIndexLoading } = useSelector(TrendIndexProperties);

    const [listDataSource, setListDataSource] = useState<IDataSource[]>([]);

    const [listFrequency, setListFrequency] = useState<IFrequency[]>([]);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [frequencySearch, setFrequencySearch] = useState<Option | null>(null);

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_TREND_INDEX_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);


    const [detail, setDetail] = useState<ISearchIndex | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetNewsIndexes(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            frequency_type: frequencySearch?.value || '',
            status: statusSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            ...query,
            name: '',
            frequency_type: '',
            status: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setFrequencySearch(null);
        setStatusSearch({ label: t('All Status'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: false,
                thWidth: 90,
                Cell: (cell: any) => (
                    <div style={{ width: '90px' }}>
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Media Name 2'),
                accessor: "name",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <div style={{ minWidth: '130px' }} className="text-start">
                        <CopyWrapper contentCopy={cell?.value} >
                            {cell?.value}
                        </CopyWrapper>
                    </div>
                ),
            },
            {
                Header: t('Weight'),
                accessor: "ratio",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => (
                    <div style={{ minWidth: '80px' }} className="text-center">
                        {cell?.value} %
                    </div>
                ),
            },
            {
                Header: t('Data Source'),
                accessor: "data_source",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const data_source = listDataSource?.find((ii) => (ii?.id === cell?.value));
                    return (
                        <>
                            <div style={{ minWidth: '130px' }} className="text-start">
                                {i18n?.language === 'en' ? data_source?.name : (data_source?.name_korea || data_source?.name_kr)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Collection Method'),
                accessor: "method",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <div style={{ minWidth: '100px' }} className="text-start">
                        {t('Crawling')}
                    </div>
                ),
            },
            {
                Header: t('Update Frequency'),
                accessor: "frequency_type",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const frequency = listFrequency?.find((ii) => (ii?.id === cell?.value));
                    return (
                        <>
                            <div style={{ minWidth: '100px' }} className="text-center">
                                {i18n?.language === 'en' ? frequency?.name : (frequency?.name_korea || frequency?.name_kr)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Recent update'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '75px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'primary', 'success'][cell?.value] || 'danger'}`}>{STATUS_TREND_INDEX_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },

            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_SETTING_INDEX_WEIGHT, userPermissions) && (<TooltipCustom
                                    title={t('Button Update')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {/* {isHavePermissionRole(ROLES_FOR_APP.VIRAL_INDEX_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)} */}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(listFrequency), JSON.stringify(listDataSource), JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: ICategory) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteTrendIndex(idDelete);
            if (response?.code === 200) {
                dispatch(onGetNewsIndexes(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleRefresh = () => {
        setDetail((_prev) => null);
        resetData();
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1, res2]: any = await Promise.all([getFrequencies(), getDataSources()]);
                setListFrequency((_prev) => res1?.data || []);
                setListDataSource((_prev) => res2?.data || []);

                if (query?.frequency_type) {
                    setFrequencySearch((_prev) => {
                        const item = res1?.data?.find((item: any) => (String(query?.frequency_type || '') === String(item?.id || '')));
                        return ({
                            label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr),
                            value: `${item?.id || ''}`,
                        });
                    });
                }

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {

        if (frequencySearch) {
            const valStr = frequencySearch?.value;
            setFrequencySearch((_prev) => {
                const item: any = listFrequency?.filter((item: any) => String(valStr) === (String(item?.id || '')));
                return ({
                    label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr),
                    value: `${item?.id || ''}`,
                });
            });
        }

        document.title = `${t('News Index Management')} - ${t('Trend Index Management')} | Rankify`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('News Index Management')} pageTitle={t('Trend Index Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.NEWS_INDEX_MANAGEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={newsIndexes?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {/* {isHavePermissionRole(ROLES_FOR_APP.VIRAL_INDEX_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setDetail((_prev) => ({} as ISearchIndex))}
                                                    >
                                                        {t('Button Create')}
                                                    </button>
                                                </div>
                                            </div>)} */}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media Name 2')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Media Name 2')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Frequency')} isShow={!!frequencySearch?.value}>
                                                    <DropdownOption
                                                        name="frequency"
                                                        dataList={listFrequency?.map((item) => ({ value: String(item?.id), label: `${i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr)}` }))}
                                                        placeholder={`${t("Frequency")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={frequencySearch || null}
                                                        onChangeSelect={(e: any) => setFrequencySearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Frequency'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_TREND_INDEX_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={["danger", "primary", "success"]}
                                                        otherColor="danger"
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isNewsIndexLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={newsIndexes?.list?.length ? newsIndexes?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={newsIndexes?.pagination?.total}
                                            customPageCount={Math.ceil(Number(newsIndexes?.pagination?.total) / Number(newsIndexes?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isNewsIndexLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
                <Modal
                    isOpen={detail !== null} centered={true} size="md"
                >
                    <ModalHeader toggle={() => setDetail((_prev) => null)}>
                        {!!detail?.id ? (detail?.is_preview ? t('Button Detail') : t('Button Update')) : t('Button Create')}
                    </ModalHeader>
                    <ModalBody className="pt-4">
                        <Form detail={detail} indexType={TREND_INDEX_TYPE.NEWS_INDEX} indexTypes={[]} frequencyTypes={listFrequency || []} dataSources={listDataSource || []} triggerRefresh={handleRefresh} handleClose={() => setDetail((_prev) => null)} />
                    </ModalBody>
                </Modal>
                <ModalConfirm
                    header={t('Button Delete')}
                    isOpen={idDelete !== null}
                    isLoading={isConfirmLoading}
                    onClose={onCloseConfirmClick}
                    onConfirm={handleActionDelete}
                />
            </div>
        </React.Fragment >
    );
};

export default NewsIndexManagement;