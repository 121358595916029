import { createAsyncThunk } from "@reduxjs/toolkit";
import paymentApi from "api/paymentApi";
import subscriptionApi from "api/subscriptionApi";
import { formatQueryParams } from "helpers/format";

export const getPaymentHistories = createAsyncThunk("Payment Histories", async (params: any = {}) => {
  try {
    const response = await paymentApi.getPaymentHistories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getSubscriptions = createAsyncThunk("Subscriptions", async (params: any = {}) => {
  try {
    const response = await subscriptionApi.subscriptions(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

// Begin::Subscription
export const getSubscription = async (id: number) => {
  try {
    const response = await subscriptionApi.getSubscription(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postSubscription = async (data: any = {}) => {
  try {
    const response = await subscriptionApi.postSubscription(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putSubscription = async (id: number, data: any = {}) => {
  try {
    const response = await subscriptionApi.putSubscription(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteSubscription = async (id: number, data: any = {}) => {
  try {
    const response = await subscriptionApi.deleteSubscription(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// End::Subscription