import { createSlice } from "@reduxjs/toolkit";
import { ICategory, ICondition, IConfigHistory } from "api/types/_category";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCategories, getConditions, getConfigHistories } from "./thunk";

export interface IState {
  categories: ResponseData<ICategory[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,

  conditions: ResponseData<ICondition[]> & PaginationResponse | null,
  isConditionLoading: boolean,
  isConditionSuccess: boolean,

  histories: ResponseData<IConfigHistory[]> & PaginationResponse | null,
  isHistoryLoading: boolean,
  isHistorySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  conditions: null,
  isConditionLoading: false,
  isConditionSuccess: false,

  histories: null,
  isHistoryLoading: false,
  isHistorySuccess: false,

  error: {},
};

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    changeStatusRankingCampaignsAction(state: any, action: any) {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    //categories
    builder.addCase(getCategories.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategories.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });
    builder.addCase(getCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });

    //conditions
    builder.addCase(getConditions.pending, (state: IState, action: any) => {
      state.isConditionLoading = true
    });
    builder.addCase(getConditions.fulfilled, (state: IState, action: any) => {
      state.conditions = action.payload.data;
      state.isConditionSuccess = true;
      state.isConditionLoading = false;
    });
    builder.addCase(getConditions.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isConditionSuccess = false;
      state.isConditionLoading = false;
    });

    //histories
    builder.addCase(getConfigHistories.pending, (state: IState, action: any) => {
      state.isHistoryLoading = true
    });
    builder.addCase(getConfigHistories.fulfilled, (state: IState, action: any) => {
      state.histories = action.payload.data;
      state.isHistorySuccess = true;
      state.isHistoryLoading = false;
    });
    builder.addCase(getConfigHistories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHistorySuccess = false;
      state.isHistoryLoading = false;
    });


  },

});

export const {
  changeStatusRankingCampaignsAction
} = CategorySlice.actions;

export default CategorySlice.reducer;