import { ISubscription } from "api/types/_subscription";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { DEFAULT_FLAG, PLAN_TYPE, PLAN_TYPE_SUBSCRIPTION_OPTIONS, STATUS_SUBSCRIPTION_OPTIONS, TYPE_SUBSCRIPTION_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import { postSubscription, putSubscription } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ISubscription | null;
    numberNext?: number,
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormSubscription = ({
    isModal = false,
    id,
    detail,
    numberNext = 0,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const STATUS_SUBSCRIPTION_OPTIONS_LANG = STATUS_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_SUBSCRIPTION_OPTIONS_LANG = TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG = PLAN_TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                sort_no: Number(values?.sort_no || 0),
                top_limit: values?.top_limit === "" ? -1 : Number(values?.top_limit || 0),
                number_of_accounts: values?.number_of_accounts === "" ? -1 : Number(values?.number_of_accounts || 0),
                monthly_price: values?.monthly_price === "" ? -1 : Number(values?.monthly_price || 0),
                yearly_price: values?.yearly_price === "" ? -1 : Number(values?.yearly_price || 0),
                period_month: Number(values?.period_month || 0),
                type: Number(values?.type ? values?.type?.value : TYPE_SUBSCRIPTION_OPTIONS_LANG[0]?.value), // 0 | 1 | 2 | 3
                plan_type: Number(values?.plan_type ? values?.plan_type?.value : PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG[0]?.value), // 0 | 1 | 2 | 3 | 4 | 5
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_SUBSCRIPTION_OPTIONS_LANG[0]?.value), // 0 | 1
                user_stats_flg: Number(values?.user_stats_flg || DEFAULT_FLAG?.OFF), // 0 | 1
                realtime_price_flg: Number(values?.realtime_price_flg || DEFAULT_FLAG?.OFF), // 0 | 1
                stock_index_flg: Number(values?.stock_index_flg || DEFAULT_FLAG?.OFF), // 0 | 1
                comment_analysis_flg: Number(values?.comment_analysis_flg || DEFAULT_FLAG?.OFF), // 0 | 1
                export_flg: Number(values?.export_flg || DEFAULT_FLAG?.OFF), // 0 | 1
            };
            const response: any = detail?.id ? await putSubscription(detail?.id, data) : await postSubscription(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        number_of_accounts: Yup.string().nullable(),
        period_month: Yup.string().required(`${t("This is required")}`),
        monthly_price: Yup.string(),
        yearly_price: Yup.string(),
        sort_no: Yup.string().required(`${t("This is required")}`),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        plan_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        top_limit: Yup.string().nullable(),
        user_stats_flg: Yup.number().nullable(),
        realtime_price_flg: Yup.number().nullable(),
        stock_index_flg: Yup.number().nullable(),
        comment_analysis_flg: Yup.number().nullable(),
        export_flg: Yup.number().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            number_of_accounts: 0,
            period_month: 0,
            monthly_price: 0,
            yearly_price: 0,
            sort_no: numberNext || 0,
            top_limit: 20,
            is_active: null,
            type: null,
            plan_type: null,
            user_stats_flg: 0,
            realtime_price_flg: 0,
            stock_index_flg: 0,
            comment_analysis_flg: 0,
            export_flg: 0,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_number_of_accounts = valueDefault?.number_of_accounts;
        const v_period_month = valueDefault?.period_month;
        const v_monthly_price = valueDefault?.monthly_price;
        const v_yearly_price = valueDefault?.yearly_price;

        const v_sort_no = valueDefault?.sort_no;
        const v_top_limit = valueDefault?.top_limit;
        const v_is_active = STATUS_SUBSCRIPTION_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        const v_type = TYPE_SUBSCRIPTION_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.type)
        );

        const v_plan_type = PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.plan_type)
        );

        const v_user_stats_flg = Number(valueDefault?.user_stats_flg || 0);
        const v_realtime_price_flg = Number(valueDefault?.realtime_price_flg || 0);
        const v_stock_index_flg = Number(valueDefault?.stock_index_flg || 0);
        const v_comment_analysis_flg = Number(valueDefault?.comment_analysis_flg || 0);
        const v_export_flg = Number(valueDefault?.export_flg || 0);

        formik.setFieldValue("number_of_accounts", Number(v_number_of_accounts) < 0 ? '' : v_number_of_accounts);
        formik.setFieldValue("monthly_price", Number(v_monthly_price) < 0 ? '' : v_monthly_price);
        formik.setFieldValue("yearly_price", Number(v_yearly_price) < 0 ? '' : v_yearly_price);
        formik.setFieldValue("top_limit", Number(v_top_limit) < 0 ? '' : v_top_limit);
        formik.setFieldValue("period_month", v_period_month);

        formik.setFieldValue("sort_no", v_sort_no);
        formik.setFieldValue("is_active", v_is_active);
        formik.setFieldValue("type", v_type);
        formik.setFieldValue("plan_type", v_plan_type);

        formik.setFieldValue("user_stats_flg", v_user_stats_flg);
        formik.setFieldValue("realtime_price_flg", v_realtime_price_flg);
        formik.setFieldValue("stock_index_flg", v_stock_index_flg);
        formik.setFieldValue("comment_analysis_flg", v_comment_analysis_flg);
        formik.setFieldValue("export_flg", v_export_flg);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="is_active"
                                                        dataList={STATUS_SUBSCRIPTION_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.is_active ||
                                                            STATUS_SUBSCRIPTION_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("is_active", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.is_active && formik.errors.is_active ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.is_active}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Type Subscription")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="type"
                                                        dataList={TYPE_SUBSCRIPTION_OPTIONS_LANG || []}
                                                        placeholder={`${t("Type Subscription")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.type ||
                                                            TYPE_SUBSCRIPTION_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("type", event)
                                                        }
                                                        colors={['secondary', 'primary', 'success', 'warning']}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.type && formik.errors.type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Plan Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="plan_type"
                                                        dataList={PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG || []}
                                                        placeholder={`${t("Plan Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.plan_type ||
                                                            PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("plan_type", event)
                                                        }
                                                        colors={['secondary', 'primary', 'success', 'danger', 'warning', 'dark']}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.plan_type && formik.errors.plan_type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.plan_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Sequence")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="sort_no"
                                                        name="sort_no"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.sort_no)}
                                                        placeholder={`${t("Sequence")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("sort_no", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.sort_no && formik.errors.sort_no ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.sort_no}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Monthly Price")}
                                                        {String((formik?.values?.plan_type as any)?.value) !== String(PLAN_TYPE.ENTERPRISE) && <span className="text-danger"> *</span>}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="monthly_price"
                                                        name="monthly_price"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.monthly_price)}
                                                        placeholder={`${String((formik?.values?.plan_type as any)?.value) === String(PLAN_TYPE.ENTERPRISE) ? t('Negotiable') : t("Monthly Price")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("monthly_price", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.monthly_price && formik.errors.monthly_price ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.monthly_price}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Yearly Price")}
                                                        {String((formik?.values?.plan_type as any)?.value) !== String(PLAN_TYPE.ENTERPRISE) && <span className="text-danger"> *</span>}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="yearly_price"
                                                        name="yearly_price"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.yearly_price)}
                                                        placeholder={`${String((formik?.values?.plan_type as any)?.value) === String(PLAN_TYPE.ENTERPRISE) ? t('Negotiable') : t("Yearly Price")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("yearly_price", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.yearly_price && formik.errors.yearly_price ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.yearly_price}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Top Limit")}
                                                        {String((formik?.values?.plan_type as any)?.value) !== String(PLAN_TYPE.ENTERPRISE) && <span className="text-danger"> *</span>}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="top_limit"
                                                        name="top_limit"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.top_limit)}
                                                        placeholder={`${String((formik?.values?.plan_type as any)?.value) === String(PLAN_TYPE.ENTERPRISE) ? t('Unlimited') : t("Top Limit")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("top_limit", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.top_limit && formik.errors.top_limit ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.top_limit}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} className="pe-0 pe-md-0 mb-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Number Of Accounts")}
                                                        {String((formik?.values?.plan_type as any)?.value) !== String(PLAN_TYPE.ENTERPRISE) && <span className="text-danger"> *</span>}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="number_of_accounts"
                                                        name="number_of_accounts"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.number_of_accounts)}
                                                        placeholder={`${String((formik?.values?.plan_type as any)?.value) === String(PLAN_TYPE.ENTERPRISE) ? t('Negotiable') : t("Number Of Accounts")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("number_of_accounts", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.number_of_accounts && formik.errors.number_of_accounts ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.number_of_accounts}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} className="pe-0 pe-md-0 mb-2">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Analysis Period")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="period_month"
                                                        name="period_month"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.period_month)}
                                                        placeholder={`${t("Analysis Period")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("period_month", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.period_month && formik.errors.period_month ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.period_month}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('user_stats_flg')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="user_stats_flg"
                                                        name="user_stats_flg"
                                                        checked={!!formik?.values?.user_stats_flg}
                                                        onChange={(event: any) => formik.setFieldValue('user_stats_flg', Number(event?.target.checked))}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <Label className="form-check-label" for="user_stats_flg">{t('user_stats_flg')}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('realtime_price_flg')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="realtime_price_flg"
                                                        name="realtime_price_flg"
                                                        checked={!!formik?.values?.realtime_price_flg}
                                                        onChange={(event: any) => formik.setFieldValue('realtime_price_flg', Number(event?.target.checked))}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <Label className="form-check-label" for="realtime_price_flg">{t('realtime_price_flg')}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('stock_index_flg')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="stock_index_flg"
                                                        name="stock_index_flg"
                                                        checked={!!formik?.values?.stock_index_flg}
                                                        onChange={(event: any) => formik.setFieldValue('stock_index_flg', Number(event?.target.checked))}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <Label className="form-check-label" for="stock_index_flg">{t('stock_index_flg')}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('comment_analysis_flg')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="comment_analysis_flg"
                                                        name="comment_analysis_flg"
                                                        checked={!!formik?.values?.comment_analysis_flg}
                                                        onChange={(event: any) => formik.setFieldValue('comment_analysis_flg', Number(event?.target.checked))}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <Label className="form-check-label" for="comment_analysis_flg">{t('comment_analysis_flg')}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('export_flg')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="export_flg"
                                                        name="export_flg"
                                                        checked={!!formik?.values?.export_flg}
                                                        onChange={(event: any) => formik.setFieldValue('export_flg', Number(event?.target.checked))}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <Label className="form-check-label" for="export_flg">{t('export_flg')}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '180px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Subscription") : t("Button Create Subscription")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default FormSubscription;
