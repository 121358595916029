import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IMedia, IOfficialMedia, IOfficialMediaRanking,  } from "./types/_media";
const api = new APIClient();

const path = '/medias';
const pathOfficial = '/official-medias';
const pathOfficialRanking = '/official-media-rankings'
const mediaApi = {
  medias(params: any):Promise<AxiosResponse<ResponseData<IMedia[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getMedia(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postMedia(dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putAccountMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}/account`;
    return api.put(url, dataForm);
  },
  allMedias(params: any = {}) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `/masters/medias`;
    return api.get(url, params)
  },

  officialmedias(params: any):Promise<AxiosResponse<ResponseData<IOfficialMedia[]> & PaginationResponse, any>> {
    const url = `${pathOfficial}`
    return api.get(url, params)
  },
  getOfficialMedia(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IOfficialMedia>, any>>  {
    const url = `${pathOfficial}/${id}`;
    return api.get(url, params)
  },
  postOfficialMedia(dataForm: any) : Promise<AxiosResponse<ResponseData<IOfficialMedia>, any>>  {
    const url = `${pathOfficial}`;
    return api.post(url, dataForm)
  },
  putOfficialMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IOfficialMedia>, any>>  {
    const url = `${pathOfficial}/${id}`;
    return api.put(url, dataForm)
  },
  deleteOfficialMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IOfficialMedia>, any>>  {
    const url = `${pathOfficial}/${id}`;
    return api.delete(url, dataForm)
  },

  officialMediaRanking(params: any):Promise<AxiosResponse<ResponseData<IOfficialMediaRanking[]> & PaginationResponse, any>> {
    const url = `${pathOfficialRanking}`
    return api.get(url, params)
  },
}
export default mediaApi
