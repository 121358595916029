import { createAsyncThunk } from "@reduxjs/toolkit";
import accountApi from 'api/accountApi';
import { formatQueryParams } from "helpers/format";

export const getAccounts = createAsyncThunk("Accounts", async (params: any = {}) => {
  try {
    const response = await accountApi.accounts(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getAccount = async (id: string | number, params: any = {}) => {
  try {
    const response = await accountApi.getAccount(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postAccount = async (data: any = {}) => {
  try {
    const response = await accountApi.postAccount(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccount = async (id: string | number, data: any = {}) => {
  try {
    const response = await accountApi.putAccount(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAccount = async (id: string | number, data: any = {}) => {
  try {
    const response = await accountApi.deleteAccount(id, data);
    return response;
  } catch (error) {
    return error;
  }
};