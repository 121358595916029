import { createAsyncThunk } from "@reduxjs/toolkit";
import trendIndexApi from 'api/trendIndexApi';
import { formatQueryParams } from "helpers/format";

export const getTrendIndexes = createAsyncThunk("Trend Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getSearchIndexes = createAsyncThunk("Search Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getSocialIndexes = createAsyncThunk("Social Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getViralIndexes = createAsyncThunk("Viral Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getNewsIndexes = createAsyncThunk("News Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getStockIndexes = createAsyncThunk("Stock Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getParticipationIndexes = createAsyncThunk("Participation Indexes" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexes(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getTrendIndexConfigs = createAsyncThunk("Trend Index Configs" , async (params: any = {}) => {
  try{
    const response = await trendIndexApi.trendIndexConfigs(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

// Begin :: Trend Index
export const getTrendIndex = async (id: number) => {
  try{
    const response = await trendIndexApi.getTrendIndex(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postTrendIndex = async (data: any = {}, params: any = {}) => {
  try{
    const response = await trendIndexApi.postTrendIndex(data, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const putTrendIndex = async (id:number, data: any = {}) => {
  try{
    const response = await trendIndexApi.putTrendIndex(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteTrendIndex = async (id:number, data: any = {}) => {
  try{
    const response = await trendIndexApi.deleteTrendIndex(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
// End :: Trend Index

// Begin :: Trend Index Config

export const getTrendIndexConfig = async (id: number) => {
  try{
    const response = await trendIndexApi.getTrendIndexConfig(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postTrendIndexConfig = async (data: any = {}, params: any = {}) => {
  try{
    const response = await trendIndexApi.postTrendIndexConfig(data, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const putTrendIndexConfig = async (id:number, data: any = {}) => {
  try{
    const response = await trendIndexApi.putTrendIndexConfig(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteTrendIndexConfig = async (id:number, data: any = {}) => {
  try{
    const response = await trendIndexApi.deleteTrendIndexConfig(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

// End :: Trend Index Config