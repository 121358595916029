import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticApi from "api/statisticApi";
import { formatQueryParams } from "helpers/format";

export const getIndexLogs = createAsyncThunk(
  "Index Logs",
  async (params: any = {}) => {
    try {
      const response = await statisticApi.indexLogs(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserVotes = createAsyncThunk(
  "User Vote",
  async (params: any = {}) => {
    try {
      const response = await statisticApi.userVotes(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserVotesChart = createAsyncThunk(
  "User Vote Line Chart",
  async (params: any = {}) => {
    try {
      const response = await statisticApi.userVotesChart(
        formatQueryParams(params)
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
