import { AxiosResponse } from "axios";
import { serialize } from "helpers/format";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ISubscription } from "./types/_subscription";
const api = new APIClient();

const path = '/subscriptions';
const subscriptionApi = {
  subscriptions(params: any): Promise<AxiosResponse<ResponseData<ISubscription[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getSubscription(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ISubscription>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postSubscription(dataForm: any, params: any = {}): Promise<AxiosResponse<ResponseData<ISubscription>, any>> {
    const url = `${path}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putSubscription(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ISubscription>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteSubscription(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ISubscription>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, { data: dataForm })
  },
}
export default subscriptionApi;
