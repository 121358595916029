import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';


import configurationApi from 'api/configurationApi';
import { formatQueryParams } from "helpers/format";

export const getConfigurations = createAsyncThunk("Configurations" , async (params: any = {}) => {
  try{
    const response = await configurationApi.configurations(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const postConfiguration = async (data: any = {}) => {
  try{
    const response = await configurationApi.postConfiguration(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putConfiguration = async (data: any = {}) => {
  try{
    const response = await configurationApi.putConfiguration(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteConfiguration = async (id: string | number , data: any = {}) => {
  try{
    const response = await configurationApi.deleteConfiguration(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
