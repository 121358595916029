import { ICategory } from "api/types/_category";
import { IMedia, IOfficialMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_AUTO_SELECTED_OPTIONS, STATUS_OFFICIAL_MEDIA_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import { postOfficialMedia, putOfficialMedia } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IOfficialMedia | null;
    medias: IMedia[],
    categories: ICategory[],
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormOfficialMedia = ({
    isModal = false,
    id,
    detail,
    medias = [],
    categories = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const STATUS_OFFICIAL_MEDIA_OPTIONS_LANG = STATUS_OFFICIAL_MEDIA_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const STATUS_AUTO_SELECTED_OPTIONS_LANG = STATUS_AUTO_SELECTED_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));


    const MEDIA_OPTIONS_LANG = medias?.reduce(
        (arr: any, item: any) => [...arr, { value: String(item?.id), label: item?.name }], []
    );

    const CATEGORY_OPTIONS_LANG = categories?.reduce(
        (arr: any, item: any) => [...arr, { value: String(item?.id), label: item?.name }], []
    );

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            // const mediasData = (
            //     !!detail?.id ? {
            //         media_id: Number(values?.medias?.value)
            //     } : {
            //         media_ids: values?.medias?.map((item: any) => Number(item?.value))
            //     });
            const configData = (
                !!detail?.id ? ({
                    is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_OFFICIAL_MEDIA_OPTIONS_LANG[0]?.value), // 0 | 1,
                    is_auto_selected: Number(values?.is_auto_selected ? values?.is_auto_selected?.value : STATUS_AUTO_SELECTED_OPTIONS_LANG[0]?.value), // 0 | 1,
                }) : {}
            );
            const data = {
                name: values?.name,
                email: values?.email,
                request_limit: Number(values?.request_limit || 0),
                category_ids: values?.category_ids?.map((category: any) => Number(category?.value)) || [],
                // ...mediasData,
                ...configData,
            };
            const response: any = detail?.id ? await putOfficialMedia(detail?.id, data) : await postOfficialMedia(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        category_ids: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a label")}`),
                    value: Yup.string().required(`${t("Please provide a value")}`),
                })
            ).required(`${t("This is required")}`)
            .min(1, `${t("At least 1 category is required")}`),
        name: Yup.string().required(`${t("This is required")}`),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, `${t('Please enter a valid email address')}`).required(`${t("This is required")}`),
        request_limit: Yup.string().required(`${t("This is required")}`),
        // medias: !!detail?.id ? Yup.object().shape({
        //     label: Yup.string().required(`${t("Please provide a label")}`),
        //     value: Yup.string().required(`${t("Please provide a value")}`),
        // }).required(`${t("This is required")}`) : Yup.array()
        //     .of(
        //         Yup.object().shape({
        //             label: Yup.string().required(`${t("Please provide a label")}`),
        //             value: Yup.string().required(`${t("Please provide a value")}`),
        //         })
        //     ).required(`${t("This is required")}`)
        //     .min(1, `${t("At least 1 media is required")}`),
        is_auto_selected: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            category_ids: null,
            name: '',
            email: '',
            request_limit: '0',
            // medias: null,
            is_active: null,
            is_auto_selected: null,
            api_key: '',
            api_secret: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_email = valueDefault?.email;
        const v_request_limit = valueDefault?.request_limit;
        const category_ids = valueDefault?.categories?.map((item: any) => String(item?.id));
        const v_category_ids = CATEGORY_OPTIONS_LANG?.filter((item) => (category_ids?.includes(String(item?.value))));
        // const v_medias = MEDIA_OPTIONS_LANG?.find((item) => (String(valueDefault?.media_id) === String(item?.value)));

        const v_is_active = STATUS_OFFICIAL_MEDIA_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        const v_is_auto_selected = STATUS_AUTO_SELECTED_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_auto_selected)
        );

        const v_api_key = valueDefault?.api_key || '';
        const v_api_secret = valueDefault?.api_secret || '';

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("email", v_email);
        formik.setFieldValue("request_limit", v_request_limit);
        formik.setFieldValue("category_ids", v_category_ids);
        // formik.setFieldValue("medias", v_medias);


        formik.setFieldValue("is_active", v_is_active);
        formik.setFieldValue("is_auto_selected", v_is_auto_selected);

        formik.setFieldValue("api_key", v_api_key);
        formik.setFieldValue("api_secret", v_api_secret);

    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand("copy", true, text);
        }
        toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
    };

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {!!detail?.id && (<Row className="mt-2 pe-0">
                                                <Col md={6} lg={6} className="ps-2 pe-0 ps-md-0 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Auto-selection Status")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownStatus
                                                            name="is_auto_selected"
                                                            dataList={STATUS_AUTO_SELECTED_OPTIONS_LANG || []}
                                                            placeholder={`${t("Auto-selection Status")}...`}
                                                            className="dropdown-status-rounded"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.is_auto_selected ||
                                                                STATUS_OFFICIAL_MEDIA_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("is_auto_selected", event)
                                                            }
                                                            colors={["danger", "success"]}
                                                            disabled={!!detail?.is_preview}
                                                        />
                                                        {formik.touched.is_auto_selected && formik.errors.is_auto_selected ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.is_auto_selected}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6} lg={6} className="ps-2 pe-0 ps-md-3 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Status")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownStatus
                                                            name="is_active"
                                                            dataList={STATUS_OFFICIAL_MEDIA_OPTIONS_LANG || []}
                                                            placeholder={`${t("Status")}...`}
                                                            className="dropdown-status-rounded"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.is_active ||
                                                                STATUS_OFFICIAL_MEDIA_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("is_active", event)
                                                            }
                                                            colors={["danger", "success"]}
                                                            disabled={!!detail?.is_preview}
                                                        />
                                                        {formik.touched.is_active && formik.errors.is_active ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.is_active}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>)}
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Categories")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownMultiOption
                                                        name="category"
                                                        dataList={CATEGORY_OPTIONS_LANG}
                                                        placeholder={`${t("Categories")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.category_ids}
                                                        onChangeSelect={(e: any) => formik.setFieldValue("category_ids", e)}
                                                        isHasOptionAll={false}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.category_ids && formik.errors.category_ids ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.category_ids}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Email")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                        autocomplete={false}
                                                        value={formik?.values?.email}
                                                        placeholder={`${t("Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("email", event?.target?.value || "")}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.errors.email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div className="position-relative">
                                                    <Label className="form-label" for="exceed_request_per_hour">
                                                        {t('Number of request limit')}
                                                        <span className="text-danger"> *</span>
                                                    </Label>
                                                    <Input className="form-control text-start"
                                                        type="text"
                                                        id="exceed_request_per_hour"
                                                        name="exceed_request_per_hour"
                                                        value={formatNumberWithCommas(formik?.values?.request_limit)}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = Number(onlyNumbers);
                                                            formik.setFieldValue("request_limit", s);
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    <div
                                                        style={{ bottom: 10, right: 20 }}
                                                        className="position-absolute fw-medium text-secondary"
                                                    >
                                                        {t('per day')}
                                                    </div>
                                                </div>
                                                {formik.touched.request_limit && formik.errors.request_limit ? (
                                                    <div className="text-danger mt-2">
                                                        {formik.errors.request_limit}
                                                    </div>
                                                ) : null}
                                            </Col>
                                            {!!detail?.is_preview && (<>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("API Key")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_key"
                                                                name="api_key"
                                                                disabled={true}
                                                                type="text"
                                                                value={formik?.values?.api_key}
                                                                placeholder={`${t("API Key")}...`}
                                                            />
                                                        </div>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_key || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </>)}
                                            {/* <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {!!detail?.id ? t("Media") : t("Medias")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    {!!detail?.id ? <DropdownOption
                                                        name="medias"
                                                        dataList={MEDIA_OPTIONS_LANG}
                                                        placeholder={`${t("Media")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.medias}
                                                        onChangeSelect={(e: any) => formik.setFieldValue("medias", e)}
                                                        isHasOptionAll={false}
                                                    /> :
                                                        <DropdownMultiOption
                                                            name="medias"
                                                            dataList={MEDIA_OPTIONS_LANG}
                                                            placeholder={`${t("Medias")}...`}
                                                            className="search-filter-category-type dropdown-status-rounded "
                                                            classNamePrefix="name-prefix"
                                                            initialValue={formik?.values?.medias}
                                                            onChangeSelect={(e: any) => formik.setFieldValue("medias", e)}
                                                            isHasOptionAll={false}
                                                        />}
                                                    {formik.touched.medias && formik.errors.medias ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.medias}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '180px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Official Media") : t("Button Create Official Media")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormOfficialMedia;
