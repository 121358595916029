export default function calculatePercentages(arr: number[]) {
  const total = arr.reduce((sum, num) => sum + num, 0);

  const percentages = arr.map((num) => {
    return parseFloat((num === 0 ? 0 : (num / total) * 100).toFixed(0));
  });
  if (total > 0) {
    const totalPercentage = percentages.reduce((sum, num) => sum + num, 0);
    const adjustment = parseFloat((100 - totalPercentage).toFixed(0));
    percentages[percentages.length - 1] += adjustment;
  }

  return percentages;
}
