import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import NoImage from '../../../../assets/images/no-photo-available.png';

import * as Yup from "yup";
import LoadingSkeleton from "./LoadingSkeleton";

const Actor = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,

            };
            // const response: any = await putActor(data);
            // if (response?.code === 200) {
            //     setIsLoading((_prev) => false);
            //     toast(
            //         `${t("The process has been completed.")}`,
            //         CONFIG_OPTION_TOAST_NORMAL
            //     );
            //     triggerRefresh && triggerRefresh();
            // } else {
            //     setIsLoading((_prev) => false);
            //     toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            // }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            image_url: '',
            name: '',
            gender: '',
            birthday: '',
            body: '',
            education: '',
            debut: '',
            agency: '',
            categories: '',
            activities: '',
            awards: '',
            projects: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        // setTimeout(() => {
        //     formik.setErrors({});
        // }, 300);
        // if (keyword_info) {
        //     formik.setFieldValue("image_url", keyword_info?.image_url);
        //     formik.setFieldValue("name", keyword_info?.name);
        //     formik.setFieldValue("gender", keyword_info?.gender);
        //     formik.setFieldValue("birthday", keyword_info?.birthday);
        //     formik.setFieldValue("body", keyword_info?.body);
        //     formik.setFieldValue("education", keyword_info?.education);
        //     formik.setFieldValue("debut", keyword_info?.debut);
        //     formik.setFieldValue("agency", keyword_info?.agency);
        //     formik.setFieldValue("categories", JSON.stringify(keyword_info?.categories));
        //     formik.setFieldValue("activities", JSON.stringify(keyword_info?.activities));
        //     formik.setFieldValue("awards", JSON.stringify(keyword_info?.awards));
        //     formik.setFieldValue("projects", JSON.stringify(keyword_info?.projects));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyword_info]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-w flex-wrap mb-3">
                    <div className="d-flex flex-column  gap-2 w-100">
                        <>
                            <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                                <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                        // backgroundColor: '#fff'
                                    }}
                                />
                            </div>
                            <div className="w-100">
                                <div>
                                    <h3 className="mb-1">
                                        {/* <input type="text" className="form-control" name="name" value={formik.values.name} onChange={formik.handleChange}
                                            style={{ fontSize: '1.2rem', fontWeight: '800' }}
                                        /> */}
                                        {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.name || '-')}
                                    </h3>
                                </div>
                                <table className="table table table-bordered mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Gender')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="gender" value={formik.values.gender} onChange={formik.handleChange} /> */}

                                                {!keyword_info?.name ? <LoadingSkeleton /> : t(String(keyword_info?.gender || '-').toUpperCase())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Birthday')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="birthday" value={formik.values.birthday} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.birthday || '-'}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Body')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="body" value={formik.values.body} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.body || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Education')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="education" value={formik.values.education} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.education || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Debut Date')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="debut" value={formik.values.debut} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.debut || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Agency')}</td>
                                            <td className="p-2 text-start">
                                                {/* <input type="text" className="form-control" name="agency" value={formik.values.agency} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.agency || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Categories')}</td>
                                            <td className="p-2 text-start">
                                                {/* <textarea rows={5} className="form-control" name="categories" value={formik.values.categories} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (Array.isArray(keyword_info?.categories) ? keyword_info?.categories.join(', ') : keyword_info?.categories || '')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Activities')}</td>
                                            <td className="p-2 text-start">
                                                {/* <textarea rows={5} className="form-control" name="activities" value={formik.values.activities} onChange={formik.handleChange} /> */}
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (Array.isArray(keyword_info?.activities) ? keyword_info?.activities.join(', ') : keyword_info?.activities || '')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Awards')}</td>
                                            <td className="p-2 text-start">
                                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                    {!keyword_info?.name ? <LoadingSkeleton /> : (Array.isArray(keyword_info?.awards) ? (keyword_info?.awards || [])?.map((award: any, index: number) => (<div>{index + 1}.  {award || ''}<br /></div>)) : keyword_info?.awards || '')}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Projects')}</td>
                                            <td className="p-2 text-start">
                                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                    {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.projects || [])?.map((project: any, index: number) => (<div>{index + 1}.  <span dangerouslySetInnerHTML={{ __html: project?.name }} /> <span className="text-muted ms-2">({project?.release_date || '-'})</span> <br /></div>))}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Personal Histories')}</td>
                                            <td className="p-2 text-start">
                                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                    {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.personal_histories || [])?.map((history: any, index: number) => (<div>{index + 1}.  {history || ''}<br /></div>))}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('News Searching Keyword')}</td>
                                            <td className="p-2 text-start">
                                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                    {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.noun_type_b || [])?.map((noun: any, index: number) => (<div>{index + 1}.  <span>{noun || ''}</span> <br /></div>))}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </div>
                </div>
            </form>
        </>
    )
}

export default React.memo(Actor);