import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IPaymentHistory } from "./types/_payment";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/payment';
const paymentApi = {
  getPaymentHistories(params: any): Promise<AxiosResponse<ResponseData<IPaymentHistory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
}
export default paymentApi;
