import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useRole } from "components/Hooks/UserHooks";


const Dashboard = () => {
  document.title = "Dashboard | Rankify";
  const { userPermissions } = useRole();
  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  useEffect(() => {
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  {isHavePermissionRole(ROLES_FOR_APP.DASHBOARD, userPermissions) && <Widget />}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
