import { createSlice } from "@reduxjs/toolkit";
import { IMember } from "api/types/_member";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getBusinesses, getMembers, getRegulars } from "./thunk";

export interface IState {
  members: ResponseData<IMember[]> & PaginationResponse | null,
  isMemberLoading: boolean,
  isMemberSuccess: boolean,

  regulars: ResponseData<IMember[]> & PaginationResponse | null,
  isRegularLoading: boolean,
  isRegularSuccess: boolean,

  businesses: ResponseData<IMember[]> & PaginationResponse | null,
  isBusinessLoading: boolean,
  isBusinessSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  members: null,
  isMemberLoading: false,
  isMemberSuccess: false,

  regulars: null,
  isRegularLoading: false,
  isRegularSuccess: false,

  businesses: null,
  isBusinessLoading: false,
  isBusinessSuccess: false,

  error: {},
};

const MemberSlice = createSlice({
  name: "Member",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get members
    builder.addCase(getMembers.pending, (state: IState, action: any) => {
      state.isMemberLoading = true
    });
    builder.addCase(getMembers.fulfilled, (state: IState, action: any) => {
      state.members = action.payload.data;
      state.isMemberSuccess = true;
      state.isMemberLoading = false;
    });
    builder.addCase(getMembers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMemberSuccess = false;
      state.isMemberLoading = false;
    });

    //get Regulars
    builder.addCase(getRegulars.pending, (state: IState, action: any) => {
      state.isRegularLoading = true
    });
    builder.addCase(getRegulars.fulfilled, (state: IState, action: any) => {
      state.regulars = action.payload.data;
      state.isRegularSuccess = true;
      state.isRegularLoading = false;
    });
    builder.addCase(getRegulars.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRegularSuccess = false;
      state.isRegularLoading = false;
    });

    //get Businesses
    builder.addCase(getBusinesses.pending, (state: IState, action: any) => {
      state.isBusinessLoading = true
    });
    builder.addCase(getBusinesses.fulfilled, (state: IState, action: any) => {
      state.businesses = action.payload.data;
      state.isBusinessSuccess = true;
      state.isBusinessLoading = false;
    });
    builder.addCase(getBusinesses.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isBusinessSuccess = false;
      state.isBusinessLoading = false;
    });
  },
});

export default MemberSlice.reducer;