import { ICategory } from "api/types/_category";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_AUTO_SELECTED_OPTIONS, STATUS_OFFICIAL_MEDIA_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllCategoriesSimple, getAllMedias, getOfficialMediaRanking as onGetOfficialMediaRanking } from "../../../store/thunks";
const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const OfficialMediaRanking = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_OFFICIAL_MEDIA_OPTIONS_LANG = STATUS_OFFICIAL_MEDIA_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const STATUS_AUTO_SELECTED_OPTIONS_LANG = STATUS_AUTO_SELECTED_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        category_id: withDefault(StringParam, ''),
        media_id: withDefault(StringParam, ''),
        is_active: withDefault(StringParam, ''),
        // is_auto_selected: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Media;

    const MediaProperties = createSelector(
        selectLayoutState,
        (state) => ({
            officialMediaRanking: state.officialMediaRanking,
            isOfficialMediaRankingSuccess: state.isOfficialMediaRankingSuccess,
            isOfficialMediaRankingLoading: state.isOfficialMediaRankingLoading,
            error: state.error,
        })
    );

    const { officialMediaRanking, isOfficialMediaRankingLoading } = useSelector(MediaProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_OFFICIAL_MEDIA_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);
    const [autoSelectedSearch, setAutoSelectedSearch] = useState<Option | null>(STATUS_AUTO_SELECTED_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_auto_selected))[0]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>(null);
    const [categorySearch, setCategorySearch] = useState<Option | null>(null);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);
    const [listCategory, setListCategory] = useState<ICategory[]>([]);

    useEffect(() => {
        dispatch(onGetOfficialMediaRanking(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            category_id: categorySearch?.value || '',
            media_id: mediaSearch?.value || '',
            is_active: statusSearch?.value || '',
            is_auto_selected: autoSelectedSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            category_id: '',
            media_id: '',
            is_active: '',
            is_auto_selected: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setMediaSearch(null);
        setCategorySearch(null);
        setStatusSearch(null);
        setAutoSelectedSearch(null);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Waiting Rank'),
                accessor: "ranking",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
                                {cell?.value}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Category (Level 1)'),
                accessor: "category",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
                                {item?.category?.name || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Media Name'),
                accessor: "media",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
                                {item?.media?.name || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_OFFICIAL_MEDIA_OPTIONS_LANG, STATUS_AUTO_SELECTED_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1, res2]: any = await Promise.all([getAllMedias(), getAllCategoriesSimple({ depth: 1 })]);
                setListMedia((_prev) => res1?.data || []);
                setListCategory((_prev: any) => res2?.data || []);
                if (query?.media) {
                    setMediaSearch((_prev) => {
                        const item = res1?.data?.find((item: any) => (String(query?.media || '') === String(item?.value || '')));
                        return ({
                            label: item?.name,
                            value: `${item?.id || ''}`,
                        });
                    });
                }
                if (query?.category_id) {
                    setCategorySearch((_prev) => {
                        const item = res2?.data?.find((item: any) => (String(query?.category_id || '') === String(item?.id || '')));
                        return ({
                            label: item?.name,
                            value: `${item?.id || ''}`,
                        });
                    });
                }
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        document.title = `${t("Official Media Ranking")} - ${t("Official Media Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Official Media Ranking')} pageTitle={t('Official Media Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={officialMediaRanking?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Category (Level 1)')} isShow={true}>
                                                    <DropdownOption
                                                        name="category"
                                                        dataList={listCategory?.map((item: any) => ({ value: String(item?.id), label: item?.name }))}
                                                        placeholder={`${t("Category")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={categorySearch || null}
                                                        onChangeSelect={(e: any) => setCategorySearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Category'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownOption
                                                        name="category"
                                                        dataList={listMedia?.map((item: any) => ({ value: String(item?.id), label: item?.name }))}
                                                        placeholder={`${t("Media")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={mediaSearch || null}
                                                        onChangeSelect={(e: any) => setMediaSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Media'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            {/* <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Auto-selection Status')} isShow={true}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_AUTO_SELECTED_OPTIONS_LANG || []}
                                                        placeholder={`${t("Auto-selection Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={autoSelectedSearch || null}
                                                        onChangeSelect={(e: any) => setAutoSelectedSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={true}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_OFFICIAL_MEDIA_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={4} xxl={4} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isOfficialMediaRankingLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={officialMediaRanking?.list?.length ? officialMediaRanking?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={officialMediaRanking?.pagination?.total}
                                            customPageCount={Math.ceil(Number(officialMediaRanking?.pagination?.total) / Number(officialMediaRanking?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isOfficialMediaRankingLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OfficialMediaRanking;