import { createSlice } from "@reduxjs/toolkit";
import { IAccount } from "api/types/_account";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getAccounts } from "./thunk";

export interface IState {
  accounts: ResponseData<IAccount[]> & PaginationResponse | null,
  isAccountLoading: boolean,
  isAccountSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  accounts: null,
  isAccountLoading: false,
  isAccountSuccess: false,

  error: {},
};

const AccountSlice = createSlice({
  name: "Account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get accounts
    builder.addCase(getAccounts.pending, (state: IState, action: any) => {
      state.isAccountLoading = true
    });
    builder.addCase(getAccounts.fulfilled, (state: IState, action: any) => {
      state.accounts = action.payload.data;
      state.isAccountSuccess = true;
      state.isAccountLoading = false;
    });
    builder.addCase(getAccounts.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAccountSuccess = false;
      state.isAccountLoading = false;
    });
  },
});

export default AccountSlice.reducer;