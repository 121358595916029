import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IAccount } from "./types/_account";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/user-sub-accounts';
const accountApi = {
  accounts(params: any): Promise<AxiosResponse<ResponseData<IAccount[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getAccount(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IAccount>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postAccount(dataForm: any): Promise<AxiosResponse<ResponseData<IAccount>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putAccount(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAccount>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteAccount(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IAccount>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default accountApi;
