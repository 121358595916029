import React from 'react';

// Define the interface for the component props
interface LoadingSkeletonProps {
    width?: string;  // Width of the skeleton (optional, default is 100%)
    height?: string; // Height of the skeleton (optional, default is 20px)
    count?: number;  // Number of skeleton elements (optional, default is 1)
    borderRadius?: string; // Border radius to create rounded or circular shapes (optional, default is 4px)
}

// LoadingSkeleton functional component
const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
    width = '100%',       // Default width is 100%
    height = '29px',      // Default height is 20px
    count = 1,            // Default to one skeleton element
    borderRadius = '4px', // Default border radius is 4px
}) => {
    return (
        <>
            {/* Map through the count and create skeleton elements */}
            {[...Array(count)].map((_, index) => (
                <div
                    key={index}  // Key for each skeleton element
                    className="skeleton"  // Apply the skeleton CSS class
                    style={{ width, height, borderRadius }}  // Apply width, height, and borderRadius from props
                />
            ))}
        </>
    );
};

export default LoadingSkeleton;  // Export the component to use in other parts of the app
