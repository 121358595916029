import { ICategory, ICondition } from "api/types/_category";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_AUTO_SELECTED_OPTIONS, STATUS_OFFICIAL_MEDIA_OPTIONS } from "helpers/constans";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postCondition, putCondition } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ICondition | null;
    categories: ICategory[],
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormCategoryQualification = ({
    isModal = false,
    id,
    detail,
    categories = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const CATEGORY_OPTIONS_LANG = categories?.reduce(
        (arr: any, item: any) => [...arr, { value: String(item?.id), label: item?.name }], []
    );

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const date_range = values?.date_range;
            const data = {
                ...(!!detail?.id ? {} : { category_id: Number(values?.category?.value) }),
                date_start: (date_range && date_range[0]) ? moment(date_range[0]).format('YYYY-MM-01') : '',
                date_end: (date_range && date_range[1]) ? moment(date_range[1]).format('YYYY-MM-01') : '',
                num_of_news_required: Number(values?.num_of_news_required)
            };
            const response: any = detail?.id ? await putCondition(detail?.id, data) : await postCondition(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        category: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).required(`${t("This is required")}`),
        date_range: Yup.array()
            .of(Yup.date().nullable())
            .test(
                'date-range-length',
                t('Please provide both start and end dates'),
                value => value && value.length === 2 && value.every(v => v !== null)
            ).required(t('Please provide both start and end dates')),
        num_of_news_required: Yup.string().required(`${t("This is required")}`),
    });


    const formik = useFormik({
        initialValues: {
            category: null,
            date_range: null,
            num_of_news_required: '0'
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    function isValidDate(dateString: string) {
        const date = moment(dateString, "YYYY-MM-DD", true);
        return date.isValid();
    }

    const handleSetValueForm = (valueDefault: any) => {
        const v_category = CATEGORY_OPTIONS_LANG?.find((item) => (String(valueDefault?.category_id) === String(item?.value)));
        const v_date_range = [isValidDate(valueDefault?.date_start) ? new Date(valueDefault?.date_start) : null, isValidDate(valueDefault?.date_end) ? new Date(valueDefault?.date_end) : null];
        const v_num_of_news_required = valueDefault?.num_of_news_required
        formik.setFieldValue("category", v_category);
        formik.setFieldValue("date_range", v_date_range);
        formik.setFieldValue("num_of_news_required", v_num_of_news_required);

    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Category (Level 1)")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="category"
                                                        disabled={!!detail?.id}
                                                        dataList={CATEGORY_OPTIONS_LANG}
                                                        placeholder={`${t("Category (Level 1)")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.category}
                                                        onChangeSelect={(e: any) => formik.setFieldValue("category", e)}
                                                        isHasOptionAll={false}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.category && formik.errors.category ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.category}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Date Range (months)")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div>
                                                        <DatePicker
                                                            selectsRange={true}
                                                            className="form-control search"
                                                            wrapperClassName="w-100"
                                                            placeholderText={`${t('Date Range (months)')}`}
                                                            startDate={(formik?.values?.date_range as any) ? (formik?.values?.date_range as any)[0] : null}
                                                            endDate={(formik?.values?.date_range as any) ? (formik?.values?.date_range as any)[1] : null}
                                                            dateFormat={'yyyy-MM'}
                                                            showMonthYearPicker
                                                            showFullMonthYearPicker
                                                            showTwoColumnMonthYearPicker
                                                            isClearable={true}
                                                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                            onChange={(e: any) => {
                                                                formik.setFieldValue("date_range", e)
                                                            }}
                                                            disabled={!!detail?.is_preview}
                                                        />
                                                    </div>
                                                    {formik.touched.date_range && formik.errors.date_range ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.date_range}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Mandatory Article Count")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            id="num_of_news_required"
                                                            name="num_of_news_required"
                                                            autocomplete={false}
                                                            value={formik?.values?.num_of_news_required}
                                                            placeholder={`${t("Mandatory Article Count ")}...`}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, "");
                                                                const s = Number(onlyNumbers);
                                                                formik.setFieldValue("num_of_news_required", s)
                                                            }}
                                                            disabled={!!detail?.is_preview}
                                                        />
                                                    </div>
                                                    {formik.touched.num_of_news_required && formik.errors.num_of_news_required ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.num_of_news_required}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '180px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Condition") : t("Button Create Condition")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormCategoryQualification;
