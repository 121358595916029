import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getConfigurations as onGetConfigurations, putConfiguration } from "../../../store/thunks";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale("en", en);
registerLocale("ko", ko);

const IndexSetting = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 500),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    key: withDefault(StringParam, ''),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Configuration;

  const ConfigurationProperties = createSelector(
    selectLayoutState,
    (state) => ({
      configurations: state.configurations,
      isConfigurationSuccess: state.isConfigurationSuccess,
      isConfigurationLoading: state.isConfigurationLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { configurations, error, isConfigurationLoading } = useSelector(ConfigurationProperties);

  const [configs, setConfigs] = useState<any>({});

  useEffect(() => {
    dispatch(onGetConfigurations(query));
  }, [JSON.stringify(query)]);

  const handleSubmit = async () => {
    try {
      setIsLoading((_prev) => true);
      const data = Object.entries(configs)?.map(([key, value]) => ({
        key: key,
        value: `${value}`,
      }));
      const response: any = await putConfiguration({ configs: data });
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      const cof = (configurations?.list || [])?.reduce((ob: any, item: any) => {
        return ((item?.group === 'index') ? ({ ...ob, [item?.key]: item?.value }) : ob);
      }, {});
      setConfigs((prev: any) => cof);
    };
    handleCallAllOption();
  }, [JSON.stringify(configurations?.list)]);


  useEffect(() => {
    document.title = `${t('Index Setting')} - ${t('System Settings')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Index Setting')} pageTitle={t('System Settings')} />
          {isHavePermissionRole(ROLES_FOR_APP.SYSTEM_SETTINGS, userPermissions) && (
            <Row>
              <Col sm={12} md={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  <div className="card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-3">
                      <Col sm={0} md={2}></Col>
                      <Col sm={12} md={8} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                        <div className="border border-dashed border-primary p-4 position-relative" style={{ borderRadius: '8px' }}>
                          <span className="h6 position-absolute text-primary mb-0" style={{
                            top: 0,
                            transform: 'translate(-5px, -50%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 5px',
                          }}
                          >{t('Title & Body Score')}
                            <TooltipCustom
                              title={t("When calculating the index, scores are assigned based on whether the keywords are included in the title and the body")}
                              className="d-inline-block vertical-align-middle ms-1"
                              style={{ transform: 'translateY(1px)' }}
                            >
                              <i className="ri-question-line align-bottom  text-primary" ></i>
                            </TooltipCustom>
                          </span>
                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('index_title_score')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.index_title_score)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const onlyNumbers = input.replace(/\D/g, '');
                                    const s = Number(onlyNumbers);
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      index_title_score: s,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('index_body_score')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.index_body_score)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const onlyNumbers = input.replace(/\D/g, '');
                                    const s = Number(onlyNumbers);
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      index_body_score: s,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div className="border border-dashed border-primary p-4 position-relative mt-3" style={{ borderRadius: '8px' }}>
                          <span className="h6 position-absolute text-primary mb-0" style={{
                            top: 0,
                            transform: 'translate(-5px, -50%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 5px',
                          }}
                          >{t('Start time for index collection')}
                            <TooltipCustom
                              title={t("Index data is collected from the specified start time")}
                              className="d-inline-block vertical-align-middle ms-1"
                              style={{ transform: 'translateY(1px)' }}
                            >
                              <i className="ri-question-line align-bottom  text-primary" ></i>
                            </TooltipCustom>
                          </span>
                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('index_initial_past_days')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.index_initial_past_days)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const onlyNumbers = input.replace(/\D/g, '');
                                    const s = Number(onlyNumbers);
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      index_initial_past_days: s,
                                    }))
                                  }}
                                />
                                <div
                                  style={{ bottom: 10, right: 20 }}
                                  className="position-absolute fw-medium text-secondary"
                                >
                                  {t('day ago')}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="border border-dashed border-primary p-4 position-relative mt-3" style={{ borderRadius: '8px' }}>
                          <span className="h6 position-absolute text-primary mb-0" style={{
                            top: 0,
                            transform: 'translate(-5px, -50%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 5px',
                          }}
                          >{t('Max date to display index')}
                            <TooltipCustom
                              title={t("If the reference date for displaying index data is not set to the most recent date, it will display data up to two days prior.")}
                              className="d-inline-block vertical-align-middle ms-1"
                              style={{ transform: 'translateY(1px)' }}
                            >
                              <i className="ri-question-line align-bottom  text-primary" ></i>
                            </TooltipCustom>
                          </span>
                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="index_max_date_to_display_index_setting">{t('Max date to display index')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="index_max_date_to_display_index_setting"
                                  name="index_max_date_to_display_index_setting"
                                  value={formatNumberWithCommas(configs?.index_max_date_to_display_index_setting)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const onlyNumbers = input.replace(/\D/g, '');
                                    const s = Number(onlyNumbers);
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      index_max_date_to_display_index_setting: s,
                                    }))
                                  }}
                                />
                                <div
                                  style={{ bottom: 10, right: 20 }}
                                  className="position-absolute fw-medium text-secondary"
                                >
                                  {t('day ago')}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="border border-dashed border-primary p-4 position-relative mt-3 d-none" style={{ borderRadius: '8px' }}>
                          <span className="h6 position-absolute text-primary mb-0" style={{
                            top: 0,
                            transform: 'translate(-5px, -50%)',
                            backgroundColor: 'var(--vz-card-bg)',
                            padding: '0 5px',
                          }}
                          >{t('General')}</span>
                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('general_logo_url')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={configs?.general_logo_url}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      general_logo_url: input,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('general_timezone_setting')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.general_timezone_setting)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      general_timezone_setting: input,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('general_language_setting')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.general_language_setting)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      general_language_setting: input,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('general_super_admin_email')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.general_super_admin_email)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      general_super_admin_email: input,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={5} lg={5} className="mb-3">
                              <div className="mb-0">
                                <Label className="form-label" for="exceed_request_per_hour">{t('general_super_admin_password')}</Label>
                                <Input className="form-control text-start"
                                  type="text"
                                  id="exceed_request_per_hour"
                                  name="exceed_request_per_hour"
                                  value={formatNumberWithCommas(configs?.general_super_admin_password)}
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    setConfigs((_prev: any) => ({
                                      ..._prev,
                                      general_super_admin_password: input,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_SETTING_INDEX_WEIGHT, userPermissions) && <Col sm={12} className="hstack gap-1 justify-content-end mt-3 mt-md-2">
                          <div>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleSubmit}
                              disabled={isLoading}
                              style={{ width: '130px' }}
                            >
                              {isLoading ? (<Spinner size="sm"></Spinner>) : t('Button Update')}
                            </button>
                          </div>
                        </Col>}
                      </Col>
                      <Col sm={0} md={2}></Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>

      </div>
    </React.Fragment >
  );
};

export default IndexSetting;