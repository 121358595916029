import { createAsyncThunk } from "@reduxjs/toolkit";
import participationApi from 'api/participationApi';
import { formatQueryParams } from "helpers/format";

export const getParticipationTemplates = createAsyncThunk("Participation Templates", async (params: any = {}) => {
  try {
    const response = await participationApi.participationTemplates(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getParticipationCategories = createAsyncThunk("Participation Categories", async (params: any = {}) => {
  try {
    const response = await participationApi.participationCategories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getParticipationTickets = createAsyncThunk("Participation Tickets", async (params: any = {}) => {
  try {
    const response = await participationApi.participationTickets(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

// Begin :: Template
export const getParticipationTemplate = async (id: number) => {
  try {
    const response = await participationApi.getParticipationTemplate(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postParticipationTemplate = async (data: any = {}, params: any = {}) => {
  try {
    const response = await participationApi.postParticipationTemplate(data, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putParticipationTemplate = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.putParticipationTemplate(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteParticipationTemplate = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.deleteParticipationTemplate(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// End :: Template



// Begin :: Template
export const getParticipationCategory = async (id: number) => {
  try {
    const response = await participationApi.getParticipationCategory(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postParticipationCategory = async (data: any = {}, params: any = {}) => {
  try {
    const response = await participationApi.postParticipationCategory(data, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putParticipationCategory = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.putParticipationCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteParticipationCategory = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.deleteParticipationCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// End :: Template




// Begin :: Ticket
export const getParticipationTicket = async (id: number) => {
  try {
    const response = await participationApi.getParticipationTicket(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postParticipationTicket = async (data: any = {}, params: any = {}) => {
  try {
    const response = await participationApi.postParticipationTicket(data, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putParticipationTicket = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.putParticipationTicket(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteParticipationTicket = async (id: number, data: any = {}) => {
  try {
    const response = await participationApi.deleteParticipationTicket(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// End :: Ticket