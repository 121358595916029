import { AxiosResponse } from "axios";
import { serialize } from "helpers/format";
import { APIClient } from "./axiosClient";
import { IParticipationCategory, IParticipationTemplate, IParticipationTicket } from "./types/_participation";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const pathTemplate = '/survey-forms';
const pathCategory = '/survey-categories';
const pathTicket = '/tickets'
const participationApi = {
  participationTemplates(params: any): Promise<AxiosResponse<ResponseData<IParticipationTemplate[]> & PaginationResponse, any>> {
    const url = `${pathTemplate}`
    return api.get(url, params)
  },
  getParticipationTemplate(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IParticipationTemplate>, any>> {
    const url = `${pathTemplate}/${id}`;
    return api.get(url, params)
  },
  postParticipationTemplate(dataForm: any, params: any): Promise<AxiosResponse<ResponseData<IParticipationTemplate>, any>> {
    const url = `${pathTemplate}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putParticipationTemplate(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationTemplate>, any>> {
    const url = `${pathTemplate}/${id}`;
    return api.put(url, dataForm)
  },
  deleteParticipationTemplate(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationTemplate>, any>> {
    const url = `${pathTemplate}/${id}`;
    return api.delete(url, { data: dataForm })
  },

  participationCategories(params: any): Promise<AxiosResponse<ResponseData<IParticipationCategory[]> & PaginationResponse, any>> {
    const url = `${pathCategory}`
    return api.get(url, params)
  },
  getParticipationCategory(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IParticipationCategory>, any>> {
    const url = `${pathCategory}/${id}`;
    return api.get(url, params)
  },
  postParticipationCategory(dataForm: any, params: any): Promise<AxiosResponse<ResponseData<IParticipationCategory>, any>> {
    const url = `${pathCategory}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putParticipationCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationCategory>, any>> {
    const url = `${pathCategory}`;
    return api.put(url, dataForm)
  },
  deleteParticipationCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationCategory>, any>> {
    const url = `${pathCategory}`;
    return api.delete(url, { data: dataForm })
  },


  participationTickets(params: any): Promise<AxiosResponse<ResponseData<IParticipationTicket[]> & PaginationResponse, any>> {
    const url = `${pathTicket}`
    return api.get(url, params)
  },
  getParticipationTicket(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IParticipationTicket>, any>> {
    const url = `${pathTicket}/${id}`;
    return api.get(url, params)
  },
  postParticipationTicket(dataForm: any, params: any): Promise<AxiosResponse<ResponseData<IParticipationTicket>, any>> {
    const url = `${pathTicket}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putParticipationTicket(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationTicket>, any>> {
    const url = `${pathTicket}/${id}`;
    return api.put(url, dataForm)
  },
  deleteParticipationTicket(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IParticipationTicket>, any>> {
    const url = `${pathTicket}/${id}`;
    return api.delete(url, { data: dataForm })
  },
}
export default participationApi
