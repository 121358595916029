import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IIndexLog } from "./types/_statistic";
import { IUserVote, IUserVoteLineChart } from "./types/_userVote";
const api = new APIClient();

const path = "/statistics";
const statisticApi = {
  indexLogs(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IIndexLog[]> & PaginationResponse, any>
  > {
    const url = `${path}/index-logs`;
    return api.get(url, params);
  },
  userVotes(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IUserVote[]> & PaginationResponse, any>
  > {
    const url = `/user-votes`;
    return api.get(url, params);
  },
  userVotesChart(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IUserVoteLineChart[]> & PaginationResponse, any>
  > {
    const url = `/user-votes/charts`;
    return api.get(url, params);
  },
};
export default statisticApi;
