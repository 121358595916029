import { ICategory } from "api/types/_category";
import { IFrequency, IIndex } from "api/types/_master";
import { Option } from "api/types/_public";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  CATEGORY_RANK_TYPE,
  CATEGORY_TYPE,
  COLORS_CATEGORY_STATUS,
  COLORS_GROUP_TYPE_CATEGORY_STATUS,
  DISPLAY_OPTIONS,
  GROUP_TYPE_CATEGORY_OPTIONS,
  STATUS_CATEGORY_OPTIONS,
} from "helpers/constans";
import {
  formatNumberWithCommas,
  formatNumberWithoutCommas,
} from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import ModalListKeyword from "pages/Keyword/ModalListKeyword";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  changeStatusRankingCampaigns,
  deleteCategory,
  getFrequencies,
  getIndexes,
  getCategories as onGetCategories,
  postKeyword,
  putCategory,
  putClassificationCategory,
  putFrequencyTypeCategory,
  putGroupTypeCategory,
  putIndexConfigsCategory,
} from "../../../store/thunks";
import CategoryTree from "../CategoryTree";
import DropdownOption from "components/Common/DropdownOption";
import ModalCategoryHistory from "../ModalCategoryHistory";
import ModalDetailKeyword from "../../Keyword/ModalDetailKeyword";
import { IKeyword } from "api/types/_keyword";

const SORT_BY_DEFAULT = "id";
const ORDER_BY_DEFAULT = "DESC";

registerLocale("en", en);
registerLocale("ko", ko);

interface Props {
  titlePage: string;
  typeCategory: CATEGORY_TYPE;
}

const CategoryList = ({ typeCategory, titlePage }: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_CATEGORY_OPTIONS_LANG = STATUS_CATEGORY_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );

  const GROUP_TYPE_CATEGORY_OPTIONS_LANG = GROUP_TYPE_CATEGORY_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );


  const DISPLAY_OPTIONS_LANG = DISPLAY_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    type: withDefault(NumberParam, typeCategory),
    name: withDefault(StringParam, ""),
    index_types: withDefault(StringParam, ""),
    frequency_types: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    is_rank_enabled: withDefault(StringParam, ""),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Category;

  const CategoryProperties = createSelector(selectLayoutState, (state) => ({
    categories: state.categories,
    isCategorySuccess: state.isCategorySuccess,
    isCategoryLoading: state.isCategoryLoading,
    error: state.error,
  }));

  // Inside your component
  const { categories, error, isCategoryLoading } =
    useSelector(CategoryProperties);

  const [listIndex, setListIndex] = useState<IIndex[]>([]);
  const [listFrequency, setListFrequency] = useState<IFrequency[]>([]);

  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

  const [indexSearch, setIndexSearch] = useState<Option[] | null>(null);

  const [frequencySearch, setFrequencySearch] = useState<Option[] | null>(null);

  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_CATEGORY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.status)
    )[0]
  );

  const [isRankEnabledSearch, setIsRankEnabledSearch] = useState<Option | null>(
    DISPLAY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.status)
    )[0]
  );

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [itemSetIndex, setItemSetIndex] = useState<ICategory & { type_rank_selecting?: string } | null>(null);
  const [itemSetFrequency, setItemSetFrequency] = useState<ICategory | null>(
    null
  );

  const [itemSetCollectDateStart, setItemSetCollectDateStart] =
    useState<ICategory | null>(null);

  const [addKeywordOfCategory, setAddKeywordOfCategory] = useState<any>(null);
  const [inputKeywords, setInputKeywords] = useState<string>("");

  const [categoryGetKeywords, setCategoryGetKeywords] = useState<any>(null);

  const [isOpenCreateCategory, setIsOpenCreateCategory] = useState<boolean>(false);

  const [categoryHistories, setCategoryHistories] = useState<ICategory | null>(null);

  const [categorySetGroupType, setCategorySetGroupType] = useState<ICategory & { is_confirm_update?: boolean } | null>(null);

  const [categorySetClassification, setCategorySetClassification] = useState<ICategory | null>(null);

  useEffect(() => {
    dispatch(onGetCategories(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      type: typeCategory,
      name: nameSearch || "",
      index_types:
        (indexSearch || [])?.map((item: any) => item?.value).join(",") || "",
      frequency_types:
        (frequencySearch || [])?.map((item: any) => item?.value).join(",") ||
        "",
      status: statusSearch?.value || "",
      is_rank_enabled: isRankEnabledSearch?.value || "",
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        type: typeCategory,
        name: "",
        index_types: "",
        frequency_types: "",
        status: "",
        is_rank_enabled: "",
        sort_by: SORT_BY_DEFAULT,
        order_by: ORDER_BY_DEFAULT,
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setNameSearch("");
    setIndexSearch(null);
    setFrequencySearch(null);
    setStatusSearch({ label: t("All Status"), value: "" });
    setIsRankEnabledSearch({ label: t("All"), value: "" });
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        filterable: false,
        sortable: false,
        thWidth: 50,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ width: "50px" }}>
            <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
          </div>
        ),
      },
      {
        Header: t("Status"),
        accessor: "statuses",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          return (
            <div className="text-center" translate="yes" style={{ minWidth: "80px" }}>
              {(cell?.value || [])?.map((status: number) => (
                <span
                  className={`rounded-pill badge bg-${COLORS_CATEGORY_STATUS[status] || "secondary"
                    }`}
                >
                  {
                    STATUS_CATEGORY_OPTIONS_LANG?.find(
                      (item: any) => String(item?.value) === String(status)
                    )?.label
                  }
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("Type"),
        accessor: "group_type",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div translate="yes" className="text-center cursor-pointer" style={{ minWidth: "80px" }}>
              {!!cell?.value ? <span
                className={`rounded-pill badge bg-${COLORS_GROUP_TYPE_CATEGORY_STATUS[String(cell?.value)] || "secondary"
                  }`}
              >
                {GROUP_TYPE_CATEGORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}
              </span> : <div className="text-primary text-center" onClick={() => setCategorySetGroupType(item)}>+</div>}
            </div>
          );
        },
      },
      {
        Header: t("Level 1"),
        accessor: "level_1",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const level: any = item?.group_categories?.[0] || null;
          const classification_parent = item?.group_categories?.find((cl: any) => !!cl?.classification);
          return (
            <div style={{ minWidth: "120px" }} translate="yes" className={`text-center d-flex align-items-center justify-content-center cursor-pointer`}>
              {level?.name || "-"}
              {!!level?.name && (!!classification_parent?.classification) && ((Number(classification_parent?.depth) === 1)) && <span className="ms-1 text-danger">*</span>}
            </div>
          );
        },
      },
      {
        Header: t("Level 2"),
        accessor: "level_2",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const level: any = item?.group_categories?.[1] || null;
          const classification_parent = item?.group_categories?.find((cl: any) => !!cl?.classification);
          return (
            <div style={{ minWidth: "120px" }} translate="yes" className={`text-center d-flex align-items-center justify-content-center cursor-pointer`}>
              {level?.name || "-"}
              {!!level?.name && (!!classification_parent?.classification) && ((Number(classification_parent?.depth) === 2)) && <span className="ms-1 text-danger">*</span>}
            </div>
          );
        },
      },
      {
        Header: t("Level 3"),
        accessor: "level_3",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const level: any = item?.group_categories?.[2] || null;
          const classification_parent = item?.group_categories?.find((cl: any) => !!cl?.classification);
          return (
            <div style={{ minWidth: "120px" }} translate="yes" className={`text-center d-flex align-items-center justify-content-center cursor-pointer`}>
              {level?.name || "-"}
              {!!level?.name && (!!classification_parent?.classification) && ((Number(classification_parent?.depth) === 3)) && <span className="ms-1 text-danger">*</span>}
            </div>
          );
        },
      },
      {
        Header: t("Level 4"),
        accessor: "level_4",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const level: any = item?.group_categories?.[3] || null;
          const classification_parent = item?.group_categories?.find((cl: any) => !!cl?.classification);
          return (
            <div style={{ minWidth: "120px" }} translate="yes" className={`text-center d-flex align-items-center justify-content-center cursor-pointer`}>
              {level?.name || "-"}
              {!!level?.name && (!!classification_parent?.classification) && ((Number(classification_parent?.depth) === 4)) && <span className="ms-1 text-danger">*</span>}
            </div>
          );
        },
      },
      {
        Header: t("Level 5"),
        accessor: "level_5",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const level: any = item?.group_categories?.[4] || null;
          const classification_parent = item?.group_categories?.find((cl: any) => !!cl?.classification);
          return (
            <div style={{ minWidth: "120px" }} translate="yes" className={`text-center d-flex align-items-center justify-content-center cursor-pointer`}>
              {level?.name || "-"}
              {!!level?.name && (!!classification_parent?.classification) && ((Number(classification_parent?.depth) === 5)) && <span className="ms-1 text-danger">*</span>}
            </div>
          );
        },
      },
      {
        Header: t("Keywords"),
        accessor: "keyword_count",
        filterable: false,
        sortable: false,
        thWidth: 100, thClass:
          "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: "100px" }} className="text-center">
              {formatNumberWithCommas(item?.keyword_count || 0)}
            </div>
          );
        },
      },
      {
        Header: t("Display On/Off"),
        accessor: "is_rank_enabled",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div
              className="form-check form-switch text-center mb-0"
              style={{ minWidth: "100px" }}
            >
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="fill_rate"
                name="fill_rate"
                checked={!!cell?.value}
                onChange={(event: any) => {
                  handleActionUpdateStatusRankingCategory(
                    item?.id,
                    Number(event?.target.checked)
                  );
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("Index"),
        accessor: "index_configs",
        filterable: false,
        sortable: false,
        thClass: "text-center align-middle",
        thWidth: 320,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={2}>
                  {t("Index")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "160px" }}
                >
                  {t("Popular")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "160px" }}
                >
                  {t("Issue")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const popular_index_configs = (item?.popular_index_configs || [])?.map((i: any) => {
            const indx = listIndex?.find(
              (v: any) => String(i?.index_type) === String(v?.id)
            );
            return {
              name: i18n?.language === "en" ? indx?.name : indx?.name_kr,
              ratio: i?.ratio,
            };
          });
          const issue_index_configs = (item?.issue_index_configs || [])?.map((i: any) => {
            const indx = listIndex?.find(
              (v: any) => String(i?.index_type) === String(v?.id)
            );
            return {
              name: i18n?.language === "en" ? indx?.name : indx?.name_kr,
              ratio: i?.ratio,
            };
          });
          return (
            <div className="d-flex text-center">
              <div className="align-self-center" style={{ width: "160px" }}>
                <>
                  <div
                    style={{ minWidth: "150px" }}
                    className="cursor-pointer"
                    onClick={() => setItemSetIndex((prev) => ({ ...item, type_rank_selecting: CATEGORY_RANK_TYPE.POPULAR }))}
                  >
                    {popular_index_configs?.length ? (
                      <div className="text-primary text-start">
                        {popular_index_configs?.map((e: any) => (
                          <div>
                            {e?.name} : {e?.ratio} %
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-primary text-center">+</div>
                    )}
                  </div>
                </>
              </div>
              <div className="align-self-center" style={{ width: "160px", borderLeft: "1px solid #eaeaea" }}>
                <>
                  <div
                    style={{ minWidth: "150px" }}
                    className="cursor-pointer link"
                    onClick={() => setItemSetIndex((prev) => ({ ...item, type_rank_selecting: CATEGORY_RANK_TYPE.ISSUE }))}
                  >
                    {issue_index_configs?.length ? (
                      <div className="text-primary text-start ps-4">
                        {issue_index_configs?.map((e: any) => (
                          <div>
                            {e?.name} : {e?.ratio} %
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-primary text-center">+</div>
                    )}
                  </div>
                </>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("Frequency"),
        accessor: "frequencies",
        filterable: false,
        sortable: false,
        thWidth: 110,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr =
            (cell?.value || [])?.length > 1
              ? Array(...(cell?.value || [])).sort((a: number, b: number) => {
                return a - b;
              })
              : cell?.value || [];
          const items = arr?.map((i: any) => {
            const freq = listFrequency?.find(
              (v: any) => String(i) === String(v?.id)
            );
            return {
              name: i18n?.language === "en" ? freq?.name : (freq?.name_korea || freq?.name_kr),
            };
          });
          return (
            <>
              <div
                style={{ minWidth: "100px" }}
                className="cursor-pointer link  text-primary"
                onClick={() => setItemSetFrequency((prev) => item)}
              >
                {items?.length ? (
                  <div className="text-primary text-center">
                    {items?.map((e: any) => (
                      <div>{e?.name}</div>
                    ))}
                  </div>
                ) : (
                  <div className="text-primary text-center">+</div>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Keyword"),
        thClass: "text-center align-middle",
        thWidth: 90,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center pe-2" style={{ minWidth: "90px" }}>
              <ul className="list-inline justify-content-end hstack gap-2 mb-0">
                {isHavePermissionRole(
                  ROLES_FOR_APP.KEYWORD_MANAGEMENT,
                  userPermissions
                ) &&
                  formatNumberWithoutCommas(item?.keyword_count) > 0 && (
                    <TooltipCustom
                      title={t("Button List Keyword")}
                      id={`update-cp-${item?.id}`}
                    >
                      <li className="list-inline-item edit " title="Edit">
                        <Link
                          className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setInputKeywords((prev: any) => "");
                            setCategoryGetKeywords(item || null);
                          }}
                        >
                          <i className="ri-bar-chart-horizontal-line"></i>
                        </Link>
                      </li>
                    </TooltipCustom>
                  )}
                {isHavePermissionRole(
                  ROLES_FOR_APP.CATEGORY_UPDATE,
                  userPermissions
                ) && (
                    <TooltipCustom
                      title={t("Button Create Keyword")}
                      id={`update-cp-${item?.id}`}
                    >
                      <li className="list-inline-item edit " title="Edit">
                        <Link
                          className="btn btn-sm btn-soft-success d-inline-block edit-item-btn"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setInputKeywords((prev: any) => "");
                            setAddKeywordOfCategory(item || []);
                          }}
                        >
                          <i className="ri-add-fill"></i>
                        </Link>
                      </li>
                    </TooltipCustom>
                  )}
              </ul>
            </div>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Action"),
        thClass: "text-center align-middle",
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center" style={{ minWidth: "120px" }}>
              <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                {/* <TooltipCustom
                  title={t('Button Update Classification')}
                  id={`update-cp-${item?.id}`}
                >
                  <li className="list-inline-item edit " title="Edit">
                    <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCategorySetClassification(item);
                      }}>
                      <i className="ri-pencil-fill"></i>
                    </Link>
                  </li>
                </TooltipCustom> */}
                <TooltipCustom
                  title={t('Button Config History')}
                  id={`update-cp-${item?.id}`}
                >
                  <li className="list-inline-item edit " title="Edit">
                    <Link className="btn btn-sm btn-soft-warning d-inline-block edit-item-btn" to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCategoryHistories(item);
                      }}>
                      <i className="ri-time-line"></i>
                    </Link>
                  </li>
                </TooltipCustom>
                {isHavePermissionRole(
                  ROLES_FOR_APP.CATEGORY_DELETE,
                  userPermissions
                ) && (
                    <TooltipCustom
                      title={t("Button Delete Category")}
                      id={`detail-cp-${item?.id}`}
                    >
                      <li className="list-inline-item" title="Remove">
                        <Link
                          className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleConfirmDelete(item);
                          }}
                        >
                          <i className="ri-delete-bin-5-fill"></i>
                        </Link>
                      </li>
                    </TooltipCustom>
                  )}
              </ul>
            </div>
          );
        },
      },
    ],
    [
      i18n?.language,
      userPermissions,
      JSON.stringify(listIndex),
      JSON.stringify(listFrequency),
      JSON.stringify(query),
      JSON.stringify(categories?.list),
    ]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: ICategory) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCategory(idDelete);
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  };
  // End::Delete

  const handleActionUpdateIndexCategory = async () => {
    if (!itemSetIndex) {
      return;
    }
    try {
      setIsLoading((_prev) => true);
      const response: any = await putIndexConfigsCategory(itemSetIndex?.id, {
        index_configs: itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs'],
        rank_type: Number(itemSetIndex?.type_rank_selecting)
      });
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsLoading((_prev) => false);
        setItemSetIndex((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionUpdateFrequencyCategory = async () => {
    if (!itemSetFrequency) {
      return;
    }
    try {
      setIsLoading((_prev) => true);
      const response: any = await putFrequencyTypeCategory(
        itemSetFrequency?.id,
        { frequency_types: itemSetFrequency?.frequencies }
      );
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsLoading((_prev) => false);
        setItemSetFrequency((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionUpdateCollectDateStartCategory = async () => {
    if (!itemSetCollectDateStart) {
      return;
    }
    try {
      setIsLoading((_prev) => true);
      const response: any = await putCategory(itemSetCollectDateStart?.id, {
        collect_date_start: itemSetCollectDateStart?.collect_date_start,
      });
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsLoading((_prev) => false);
        setItemSetCollectDateStart((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: addKeywordOfCategory?.id,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, []),
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setAddKeywordOfCategory((prev: any) => null);
        setInputKeywords((prev: any) => "");
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        // dispatch(onGetKeywords(query));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };



  const handleActionUpdateStatusRankingCategory = async (
    category_id: number,
    status_ranking: number
  ) => {
    try {
      setIsLoading((_prev) => true);
      const response: any = await putCategory(category_id, {
        is_rank_enabled: status_ranking,
      });
      if (response?.code === 200) {
        const newState: any = (categories?.list || [])?.map((item: any) => {
          const val: number = Number(item?.id || "");
          return category_id === val
            ? { ...item, is_rank_enabled: status_ranking }
            : item;
        });
        dispatch(
          changeStatusRankingCampaigns({
            ...categories,
            list: newState,
          })
        );
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionUpdateClassificationCategory = async () => {
    try {
      if (!categorySetClassification?.id) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putClassificationCategory(categorySetClassification?.id, {
        classification: categorySetClassification?.classification || '',
      });
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsLoading((_prev) => false);
        setCategorySetClassification((_prev: any) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleActionConfirmUpdateGroupTypeCategory = () => {
    setCategorySetGroupType((_prev: any) => ({ ..._prev, is_confirm_update: true }));
  }

  const onCloseConfirmUpdateTypeCategoryClick = () => {
    setCategorySetGroupType((_prev: any) => ({ ..._prev, is_confirm_update: false }));
  }

  const handleActionUpdateTypeCategory = async () => {
    try {
      if (!categorySetGroupType?.id) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putGroupTypeCategory(categorySetGroupType?.id, {
        group_type: Number(categorySetGroupType?.group_type || GROUP_TYPE_CATEGORY_OPTIONS_LANG[0]?.value),
      });
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsLoading((_prev) => false);
        setCategorySetGroupType((_prev: any) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleOnCloseCreateCategory = () => {
    setIsOpenCreateCategory(() => false);
    dispatch(onGetCategories(query));
  };

  const handleOnCloseCategoryGetKeywords = () => {
    setCategoryGetKeywords(() => null);
  };

  const frequenciesType = (freq_value: number[] = []) => {
    const arr =
      freq_value?.length > 1
        ? Array(...freq_value).sort((a: number, b: number) => {
          return a - b;
        })
        : freq_value;
    const items = arr?.map((i: any) => {
      const freq = listFrequency?.find((v: any) => String(i) === String(v?.id));
      return {
        name: i18n?.language === "en" ? freq?.name : (freq?.name_korea || freq?.name_kr),
      };
    });
    return items;
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res1, res2]: any = await Promise.all([
          getIndexes(),
          getFrequencies(),
        ]);
        setListIndex((_prev) => res1?.data || []);
        setListFrequency((_prev) => res2?.data || []);

        if (query?.index_types) {
          setIndexSearch((_prev) => {
            const items = res1?.data?.filter((item: any) =>
              String(query?.index_types || "")
                .split(",")
                .includes(String(item?.id || ""))
            );
            return items?.map((item: any) => ({
              label: i18n?.language === "en" ? item?.name : item?.name_kr,
              value: `${item?.id || ""}`,
            }));
          });
        } else {
          setIndexSearch(null);
        }

        if (query?.frequency_types) {
          setFrequencySearch((_prev) => {
            const items = res2?.data?.filter((item: any) =>
              String(query?.frequency_types || "")
                .split(",")
                .includes(String(item?.id || ""))
            );
            return items?.map((item: any) => ({
              label: i18n?.language === "en" ? item?.name : (item?.name_korea || item?.name_kr),
              value: `${item?.id || ""}`,
            }));
          });
        } else {
          setFrequencySearch(null);
        }

        if (!query?.name) {
          setNameSearch("");
        }

        if (!query?.status) {
          setStatusSearch({ label: t("All Status"), value: "" });
        }

        if (!query?.is_rank_enabled) {
          setIsRankEnabledSearch({ label: t("All"), value: "" });
        }



      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, [typeCategory]);

  useEffect(() => {
    if (indexSearch) {
      const valStr = indexSearch?.map((item: any) => item?.value);
      setIndexSearch((_prev) => {
        const items = listIndex?.filter((item: any) =>
          valStr.includes(String(item?.id || ""))
        );
        return items?.map((item: any) => ({
          label: i18n?.language === "en" ? item?.name : item?.name_kr,
          value: `${item?.id || ""}`,
        }));
      });
    }

    if (frequencySearch) {
      const valStr = frequencySearch?.map((item: any) => item?.value);
      setFrequencySearch((_prev) => {
        const items = listFrequency?.filter((item: any) =>
          valStr.includes(String(item?.id || ""))
        );
        return items?.map((item: any) => ({
          label: i18n?.language === "en" ? item?.name : (item?.name_korea || item?.name_kr),
          value: `${item?.id || ""}`,
        }));
      });
    }

    document.title = `${t(titlePage)} - ${t("Category Management")} | Rankify`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t(titlePage)}
            pageTitle={t("Category Management")}
          />
          {isHavePermissionRole(
            ROLES_FOR_APP.CATEGORY_MANAGEMENT,
            userPermissions
          ) && (
              <Row>
                <Col sm={12}>
                  <Card
                    id="customerList"
                    style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                  >
                    <CardHeader className="border-0">
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              <span className="me-2">{t("Total")}:</span>
                              <CountUp
                                start={0}
                                end={categories?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </h5>
                          </div>
                        </div>
                        {isHavePermissionRole(
                          ROLES_FOR_APP.CATEGORY_CREATE,
                          userPermissions
                        ) && (
                            <div className="col-sm-auto">
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-success add-btn rounded-pill"
                                  id="create-btn"
                                  onClick={() => setIsOpenCreateCategory(true)} //navigate(`${ROUTES.CATEGORY_TREE}`)
                                >
                                  {t("Edit Category")}
                                </button>
                              </div>
                            </div>
                          )}
                      </Row>
                    </CardHeader>
                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={6}
                          md={6}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Category Name")}
                            isShow={!!nameSearch}
                          >
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Category Name")}...`}
                              value={nameSearch}
                              onChange={(e) => setNameSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper label={t("Display On/Off")} isShow={true}>
                            <DropdownOption
                              name="status"
                              dataList={DISPLAY_OPTIONS_LANG || []}
                              placeholder={`${t("Display On/Off")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={isRankEnabledSearch || null}
                              onChangeSelect={(e: any) =>
                                setIsRankEnabledSearch(e)
                              }
                              isHasOptionAll={true}
                              optionAll={{ label: t("All"), value: "" }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Status")}
                            isShow={!!statusSearch?.value}
                          >
                            <DropdownStatus
                              name="status"
                              dataList={STATUS_CATEGORY_OPTIONS_LANG || []}
                              placeholder={`${t("Status")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={statusSearch || null}
                              onChangeSelect={(e: any) => setStatusSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t("All Status"), value: "" }}
                              otherColor="dark"
                              colors={COLORS_CATEGORY_STATUS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Index")}
                            isShow={!!indexSearch?.length}
                          >
                            <DropdownMultiOption
                              name="index"
                              dataList={listIndex?.map((item) => ({
                                value: String(item?.id),
                                label: `${i18n?.language === "en"
                                  ? item?.name
                                  : item?.name_kr
                                  }`,
                              }))}
                              placeholder={`${t("Index")}...`}
                              className="search-filter-category-type dropdown-status-rounded "
                              classNamePrefix="name-prefix"
                              initialValue={indexSearch || null}
                              onChangeSelect={(e: any) => setIndexSearch(e)}
                              isHasOptionAll={false}
                              optionAll={{ label: t("All Index"), value: "" }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Frequency")}
                            isShow={!!frequencySearch?.length}
                          >
                            <DropdownMultiOption
                              name="frequency"
                              dataList={listFrequency?.map((item) => ({
                                value: String(item?.id),
                                label: `${i18n?.language === "en"
                                  ? item?.name
                                  : (item?.name_korea || item?.name_kr)
                                  }`,
                              }))}
                              placeholder={`${t("Frequency")}...`}
                              className="search-filter-category-type dropdown-status-rounded "
                              classNamePrefix="name-prefix"
                              initialValue={frequencySearch || null}
                              onChangeSelect={(e: any) => setFrequencySearch(e)}
                              isHasOptionAll={false}
                              optionAll={{ label: t("All Frequency"), value: "" }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          xl={12}
                          xxl={2}
                          className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={searchData}
                              disabled={isCategoryLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={categories?.list?.length ? categories?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={categories?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(categories?.pagination?.total) /
                          Number(categories?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isCategoryLoading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
        <Modal
          isOpen={!!itemSetIndex}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={() => setItemSetIndex(null)}>
            {t("Select Index")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <div className="table-responsive mt-3">
                <table className="table table-bordered table-nowrap align-middle mb-0">
                  <thead className="table-light text-muted">
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "40%" }}
                        className="text-center"
                      >
                        {t("Index Name")}
                      </th>
                      <th
                        scope="col"
                        style={{ width: "30%" }}
                        className="text-center"
                      >
                        {t("Select")}
                      </th>
                      <th
                        scope="col"
                        style={{ width: "30%" }}
                        className="text-center"
                      >
                        {t("Weight")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listIndex?.map((item) => (
                      <tr key={item?.value}>
                        <td className="text-center">
                          {i18n?.language === "en" ? item?.name : item?.name_kr}
                        </td>
                        <td className="text-center">
                          <div className="form-check notification-check text-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                !!itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.find(
                                  (i: any) =>
                                    String(i?.index_type) === String(item?.id)
                                )
                              }
                              id={`check_${item?.id}`}
                              onChange={(event) => {
                                setItemSetIndex((prev: any) => {
                                  return {
                                    ...prev,
                                    [itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']: event.target.checked
                                      ? [
                                        ...prev?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs'],
                                        { index_type: item?.id, ratio: 0 },
                                      ]
                                      : prev?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.filter(
                                        (idx: any) =>
                                          idx?.index_type !== item?.id
                                      ),
                                  };
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <Input
                            className="form-control text-center py-1"
                            type="text"
                            disabled={
                              !itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.find(
                                (i: any) =>
                                  String(i?.index_type) === String(item?.id)
                              )
                            }
                            value={
                              itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.find(
                                (i: any) =>
                                  String(i?.index_type) === String(item?.id)
                              )?.ratio || 0
                            }
                            onChange={(event) => {
                              const input = event.target.value;
                              const onlyNumbers = input.replace(/\D/g, "");
                              const s =
                                Number(onlyNumbers) >= 100
                                  ? 100
                                  : Number(onlyNumbers);
                              setItemSetIndex((prev: any) => {
                                return {
                                  ...prev,
                                  [itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']: prev?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.map(
                                    (idx: any) => {
                                      return idx?.index_type === item?.id
                                        ? { ...idx, ratio: s }
                                        : idx;
                                    }
                                  ),
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  {!!itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.length &&
                    itemSetIndex?.[itemSetIndex?.type_rank_selecting === CATEGORY_RANK_TYPE.POPULAR ? 'popular_index_configs' : 'issue_index_configs']?.reduce(
                      (sum: number, item: any) => sum + Number(item?.ratio),
                      0
                    ) !== 100 ? (
                    <div className="text-danger my-1">
                      {t(
                        "The sum of the weights for the selected indices must be 100%"
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="hstack gap-2 justify-content-center mt-3 ">
                  <button
                    className="btn btn-soft-secondary rounded-pill fs-14"
                    type="button"
                    color="light"
                    onClick={() => {
                      !isLoading && setItemSetIndex(null);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    {t("Button Close")}
                  </button>
                  {isHavePermissionRole(
                    ROLES_FOR_APP.CATEGORY_SETTING_INDEX_WEIGHT,
                    userPermissions
                  ) && (
                      <button
                        className={`btn rounded-pill btn-primary fs-14`}
                        type="button"
                        disabled={isLoading}
                        onClick={handleActionUpdateIndexCategory}
                        style={{ minWidth: "100px" }}
                      >
                        {isLoading ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          t("Button Update")
                        )}
                      </button>
                    )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!itemSetFrequency}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={() => setItemSetFrequency(null)}>
            {t("Select Frequency")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <div className="table-responsive mt-3">
                <table className="table table-bordered table-nowrap align-middle mb-0">
                  <thead className="table-light text-muted">
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "60%" }}
                        className="text-center"
                      >
                        {t("Frequency")}
                      </th>
                      <th
                        scope="col"
                        style={{ width: "40%" }}
                        className="text-center"
                      >
                        {t("Select")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listFrequency?.map((item) => (
                      <tr key={item?.value}>
                        <td className="text-center">
                          {i18n?.language === "en"
                            ? item?.name
                            : (item?.name_korea || item?.name_kr)}
                        </td>
                        <td className="text-center">
                          <div className="form-check notification-check text-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={itemSetFrequency?.frequencies?.includes(
                                item?.id
                              )}
                              id={`check_frequencies_${item?.id}`}
                              onChange={(event) => {
                                setItemSetFrequency((prev: any) => {
                                  return {
                                    ...prev,
                                    frequencies: event.target.checked
                                      ? [...prev?.frequencies, item?.id]
                                      : prev?.frequencies?.filter(
                                        (idx: number) => idx !== item?.id
                                      ),
                                  };
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="hstack gap-2 justify-content-center mt-3 ">
                  <button
                    className="btn btn-soft-secondary rounded-pill fs-14"
                    type="button"
                    color="light"
                    onClick={() => {
                      !isLoading && setItemSetFrequency(null);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    {t("Button Close")}
                  </button>
                  <button
                    className={`btn rounded-pill btn-primary fs-14`}
                    type="button"
                    disabled={isLoading}
                    onClick={handleActionUpdateFrequencyCategory}
                    style={{ minWidth: "100px" }}
                  >
                    {isLoading ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      t("Button Update")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!itemSetCollectDateStart}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={() => setItemSetCollectDateStart(null)}>
            {t("Select Start Date")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <div className="table-responsive">
                <div
                  className="date-picker-wrapper-custom"
                  style={{ minHeight: "80px" }}
                >
                  <div className="mb-2 mt-2">{t("Start Date")}</div>
                  <div>
                    <DatePicker
                      className="form-control search"
                      placeholderText={`${t("Date")}`}
                      value={
                        itemSetCollectDateStart?.collect_date_start || undefined
                      }
                      selected={
                        itemSetCollectDateStart?.collect_date_start
                          ? new Date(
                            itemSetCollectDateStart?.collect_date_start
                          )
                          : undefined
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={false}
                      minDate={new Date()}
                      locale={i18n?.language === "ko" ? "ko" : "en"}
                      onChange={(value: Date | null) => {
                        setItemSetCollectDateStart((_prev: any) => ({
                          ..._prev,
                          collect_date_start: value
                            ? moment(new Date(value)).format("Y-MM-DD")
                            : "",
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="hstack gap-2 justify-content-center mt-3 ">
                  <button
                    className="btn btn-soft-secondary rounded-pill fs-14"
                    type="button"
                    color="light"
                    onClick={() => {
                      !isLoading && setItemSetCollectDateStart(null);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    {t("Button Close")}
                  </button>
                  <button
                    className={`btn rounded-pill btn-primary fs-14`}
                    type="button"
                    disabled={isLoading}
                    onClick={handleActionUpdateCollectDateStartCategory}
                    style={{ minWidth: "100px" }}
                  >
                    {isLoading ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      t("Button Update")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={addKeywordOfCategory !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setAddKeywordOfCategory(null)}>
            {t("Button Create Keyword")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Category")}
                </Col>
                <Col sm={12} md={10} lg={10} className="mt-3 mt-sm-3 mt-md-2">
                  <div className="text-muted fs-14">
                    {addKeywordOfCategory?.group_categories
                      ?.map((item: any) => item?.name)
                      .join(" > ")}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Keyword")}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords(
                        (prev: any) => event?.target?.value || ""
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => {
                    !isLoading && setAddKeywordOfCategory(null);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || !inputKeywords}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: "100px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    t("Button Update")
                  )}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isOpenCreateCategory}
          centered={true}
          size="xl"
          className="modal-fullscreen"
          scrollable={false}
        >
          <ModalHeader toggle={handleOnCloseCreateCategory}>
            {t("Edit Category")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <CategoryTree typeCategory={typeCategory} />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!categoryGetKeywords}
          centered={true}
          size="xl"
          className="modal-fullscreen"
          scrollable={false}
        >
          <ModalHeader toggle={() => handleOnCloseCategoryGetKeywords()}>
            {t("Button List Keyword")}
            <h6 className="ms-4 text-muted d-inline-block">
              ({t("Category")}:{" "}
              {(categoryGetKeywords?.group_categories || [])
                ?.map((item: any) => item?.name)
                .join(" > ")}
              )
            </h6>
            <span className="mx-2">-</span>
            <h6 className="text-muted d-inline-block">
              ({t("Frequency")}:{" "}
              {frequenciesType(categoryGetKeywords?.frequencies || [])
                ?.map((item: any) => item?.name)
                .join(", ")}
              )
            </h6>
          </ModalHeader>
          <ModalBody className="pt-3" style={{ minHeight: "80dvh" }}>
            <ModalListKeyword
              categoryId={categoryGetKeywords?.id}
              category={categoryGetKeywords}
              frequencies={listFrequency}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!categoryHistories}
          centered={true}
          size="lg"
          scrollable={false}
        >
          <ModalHeader toggle={() => setCategoryHistories(null)}>
            {t("Button Config History")}
          </ModalHeader>
          <ModalBody className="pt-3" style={{ minHeight: "80dvh" }}>
            <ModalCategoryHistory
              categoryId={categoryHistories?.id}
              listIndex={listIndex}
              listFrequency={listFrequency}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!categorySetClassification}
          centered={true}
          size="md"
          scrollable={true}
        >
          <ModalHeader toggle={() => setCategorySetClassification((_prev) => null)}>
            {t("Classification Label")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={4} lg={3} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Category")}
                </Col>
                <Col sm={12} md={8} lg={9} className="mt-3 mt-sm-3 mt-md-2">
                  <div className="text-muted fs-14">
                    {categorySetClassification?.group_categories
                      ?.map((item: any) => item?.name)
                      .join(" > ")}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4 mb-5">
                <Col sm={12} md={4} lg={i18n?.language === "en" ? 4 : 3} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Classification Label")}
                </Col>
                <Col sm={12} md={8} lg={i18n?.language === "en" ? 8 : 9}>
                  {/* <Input
                    type="text"
                    id="classification"
                    name="classification"
                    autocomplete={false}
                    value={categorySetClassification?.classification || ''}
                    placeholder={`${t("Classification Label")}...`}
                    onChange={(event: any) => {
                      setCategorySetClassification(
                        (_prev: any) => ({ ..._prev, classification: event?.target?.value || "" })
                      );
                    }}
                  /> */}
                  <div className="form-check form-switch mb-1 mt-2">
                    <Input className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="classification"
                      name="classification"
                      checked={!!categorySetClassification?.classification}
                      onChange={(event: any) => {
                        setCategorySetClassification(
                          (_prev: any) => ({ ..._prev, classification: event?.target.checked ? categorySetClassification?.name : '' })
                        );
                      }}
                    />
                    <Label className="form-check-label" for="classification">{categorySetClassification?.name || ''}</Label>
                  </div>
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => {
                    !isLoading && setCategorySetClassification((_prev) => null);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading}
                  onClick={handleActionUpdateClassificationCategory}
                  style={{ minWidth: "100px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    t("Button Update")
                  )}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!categorySetGroupType}
          centered={true}
          size="md"
          scrollable={true}
        >
          <ModalHeader toggle={() => setCategorySetGroupType((_prev) => null)}>
            {t("Type")}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Category")}
                </Col>
                <Col sm={12} md={10} lg={10} className="mt-3 mt-sm-3 mt-md-2">
                  <div className="text-muted fs-14">
                    {categorySetGroupType?.group_categories
                      ?.map((item: any) => item?.name)
                      .join(" > ")}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4" style={{ minHeight: '140px' }}>
                <Col sm={12} md={2} lg={2} className="mt-3 mt-sm-3 mt-md-2">
                  {t("Type")}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <DropdownStatus
                    name="status"
                    dataList={GROUP_TYPE_CATEGORY_OPTIONS_LANG || []}
                    placeholder={`${t("Type")}...`}
                    className="dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    initialValue={GROUP_TYPE_CATEGORY_OPTIONS_LANG?.find((e) => String(e?.value) === String(categorySetGroupType?.group_type)) || GROUP_TYPE_CATEGORY_OPTIONS_LANG[0]}
                    onChangeSelect={(e: any) => {
                      setCategorySetGroupType(
                        (_prev: any) => ({ ..._prev, group_type: Number(e?.value) })
                      );
                    }}
                    isHasOptionAll={false}
                    otherColor="dark"
                    colors={COLORS_GROUP_TYPE_CATEGORY_STATUS}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => {
                    !isLoading && setCategorySetGroupType((_prev) => null);
                  }}
                  style={{ minWidth: "100px" }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading}
                  onClick={handleActionConfirmUpdateGroupTypeCategory}
                  style={{ minWidth: "100px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    t("Button Update")
                  )}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t("Button Update Type Category")}
          title={t('Are you sure you want to update?')}
          content={t("After confirming the update action, the data will be permanently updated and this field can't edit, when user set this value first. Do you want to proceed with the update action.")}
          isOpen={!!categorySetGroupType?.is_confirm_update}
          isLoading={isLoading}
          isShowIcon={false}
          classButtonConfirm="btn-primary"
          classIconButtonConfirm=''
          textButtonConfirm={t("Button Update")}
          onClose={onCloseConfirmUpdateTypeCategoryClick}
          onConfirm={handleActionUpdateTypeCategory}
        />

        <ModalConfirm
          header={t("Button Delete Category")}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />

      </div>
    </React.Fragment >
  );
};

export default CategoryList;
