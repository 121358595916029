
import { formatNumberWithCommas } from "helpers/format";
import React from "react";
import { Input } from "reactstrap";

interface InputsRangeProps {
  nameFrom?: string,
  nameTo?: string,
  valueFrom?: string,
  valueTo?: string,
  placeholderFrom?: string,
  placeholderTo?: string,
  labelFrom?: string,
  labelTo?: string,
  isTypeNumber?: boolean,
  onChangeFrom?: (e: any) => void;
  onChangeTo?: (e: any) => void;
  onKeyDownFrom?: (e: any) => void;
  onKeyDownTo?: (e: any) => void;

}

const InputsRange = ({
  nameFrom = '',
  nameTo = '',
  valueFrom = '',
  valueTo = '',
  placeholderFrom = '',
  placeholderTo = '',
  labelFrom = '',
  labelTo = '',
  isTypeNumber = false,
  onChangeFrom,
  onChangeTo,
  onKeyDownFrom,
  onKeyDownTo,
}: InputsRangeProps) => {

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="position-relative w-100">
          <Input
            name={nameFrom || ''}
            type="text"
            autoComplete="off"
            className="form-control search"
            placeholder={placeholderFrom || ''}
            value={isTypeNumber ? formatNumberWithCommas(valueFrom) : valueFrom}
            onChange={(e) => {
              let input = e.target.value;
              let onlyNumbers = String(input).replace(/\D/g, '');

              // Remove leading zeroes if the number is greater than 0
              if (onlyNumbers.startsWith('0') && onlyNumbers.length > 1) {
                onlyNumbers = onlyNumbers.replace(/^0+/, '');
              }

              onChangeFrom && onChangeFrom(isTypeNumber ? onlyNumbers : input);
            }}
            onKeyDown={(e) => { onKeyDownFrom && onKeyDownFrom(e) }}
            style={{ paddingRight: '30px' }}
          />
          <span
            className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer  ${!valueFrom && 'd-none'}`}
            onClick={(e) => { onChangeFrom && onChangeFrom('') }}
            style={{
              right: '8px',
              top: 5,
              fontSize: '20px',
              color: 'var(--vz-header-item-sub-color)'
            }}
          ></span>
          {labelFrom && (valueFrom !== '') && (<label style={{
            position: 'absolute',
            left: '5px',
            top: '0px',
            marginBottom: '0px',
            transform: 'translateY(-50%)',
            padding: `0px 5px`,
            color: '#c2c2c2',
            fontSize: '12px',
            fontWeight: '400',
            borderRadius: '3px',
            backgroundColor: 'var(--vz-secondary-bg)',
          }}>
            {labelFrom}
          </label>)}
        </div>

        <div className="p-2">
          →
        </div>
        <div className="position-relative w-100">
          <Input
            name={nameTo || ''}
            type="text"
            autoComplete="off"
            className="form-control search"
            placeholder={placeholderTo || ''}
            value={isTypeNumber ? formatNumberWithCommas(valueTo) : valueTo}
            onChange={(e) => {
              let input = e.target.value;
              let onlyNumbers = String(input).replace(/\D/g, '');

              // Remove leading zeroes if the number is greater than 0
              if (onlyNumbers.startsWith('0') && onlyNumbers.length > 1) {
                onlyNumbers = onlyNumbers.replace(/^0+/, '');
              }

              onChangeTo && onChangeTo(isTypeNumber ? onlyNumbers : input);
            }}
            onKeyDown={(e) => { onKeyDownTo && onKeyDownTo(e) }}
            style={{ paddingRight: '30px' }}
          />
          <span
            className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer ${!valueTo && 'd-none'}`}
            onClick={(e) => { onChangeTo && onChangeTo('') }}
            style={{
              right: '8px',
              top: 5,
              fontSize: '20px',
              color: 'var(--vz-header-item-sub-color)'
            }}
          ></span>
          {labelTo && (valueTo !== '') && (<label style={{
            position: 'absolute',
            left: '5px',
            top: '0px',
            marginBottom: '0px',
            transform: 'translateY(-50%)',
            padding: `0px 5px`,
            color: '#c2c2c2',
            fontSize: '12px',
            fontWeight: '400',
            borderRadius: '3px',
            backgroundColor: 'var(--vz-secondary-bg)',
          }}>
            {labelTo}
          </label>)}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputsRange;
