import { useEffect, useRef } from "react";

const ItemRender = (props: any) => {
  const { node, updateNodeToIsEdit, updateNode } = props;
  const inputRef = useRef<any>(null);
  const handleIsEdit = () => {
    updateNodeToIsEdit && updateNodeToIsEdit(props);
  };
  const handleUpdateNode = () => {
    if (inputRef?.current) {
      updateNode && updateNode(props, inputRef?.current, false);
      inputRef.current = '';
    }
  }
  const onChangeValue = (e: any) => {
    inputRef.current = e.target.value;
    updateNode(props, e.target.value, true);
  }

  useEffect(() => {
    if (node?.isEdit) {
      // console.log('node', node);
      // setTimeout(() => {
      //   inputRef?.current?.focus();
      // }, 300)
      // inputRef.current = node?.title;
    }
    return () => {
      inputRef.current = null;
    }
  }, [node?.isEdit])

  return (
    <div style={{ width: '150px' }} className="d-flex align-items-center" onClick={() => handleIsEdit()}>
      <input
        ref={inputRef}
        type="text"
        className={`form-control search ${node?.isEdit ? '' : 'd-none'}`}
        style={{ width: '100%', height: '30px' }}
        value={node?.title || ''}
        onChange={onChangeValue}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleUpdateNode();
          }
        }}
      />
      <div className={`text-overflow-ellipsis ${node?.isEdit ? 'd-none' : ''}`} >{node?.title || ''}</div>
    </div>
  );
};

export default ItemRender;

