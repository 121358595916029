import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import memberApi from 'api/memberApi';
import { formatQueryParams } from "helpers/format";

export const getMembers = createAsyncThunk("Members", async (params: any = {}) => {
  try {
    const response = await memberApi.members(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getRegulars = createAsyncThunk("Regulars", async (params: any = {}) => {
  try {
    const response = await memberApi.members(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getBusinesses = createAsyncThunk("Businesses", async (params: any = {}) => {
  try {
    const response = await memberApi.members(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getMembersByNormal = async (params: any = {}) => {
  try {
    const response = await memberApi.members(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const getMember = async (id: string | number, params: any = {}) => {
  try {
    const response = await memberApi.getMember(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postMember = async (data: any = {}) => {
  try {
    const response = await memberApi.postMember(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putMember = async (id: string | number, data: any = {}) => {
  try {
    const response = await memberApi.putMember(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMember = async (id: string | number, data: any = {}) => {
  try {
    const response = await memberApi.deleteMember(id, data);
    return response;
  } catch (error) {
    return error;
  }
};