import { createAsyncThunk } from "@reduxjs/toolkit";
import masterApi from "api/masterApi";
import { formatQueryParams } from "helpers/format";

export const getIndexes = async (params:any = {}) => {
  try{
    const response = await masterApi.indexes(params);
    return response;
  }catch (error) {
    return error;
  }
};

export const getFrequencies = async (params:any = {}) => {
  try{
    const response = await masterApi.frequencies(params);
    return response;
  }catch (error) {
    return error;
  }
};

export const getDataSources = async (params:any = {}) => {
  try{
    const response = await masterApi.allDataSources(params);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllCategoriesNormal =  async (params: any = {}) => {
  try{
    const response = await masterApi.allCategories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllCategories = createAsyncThunk("All Categories" , async (params: any = {}) => {
  try{
    const response = await masterApi.allCategories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getAllCategoriesSimple =  async (params: any = {}) => {
  try{
    const response = await masterApi.allCategoriesSimple(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};


export const getAllMenus =  async (params: any = {}) => {
  try{
    const response = await masterApi.allMenus(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllParticipationTemplates =  async (params: any = {}) => {
  try{
    const response = await masterApi.allParticipationTemplates(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

