import { IMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_MEMBER_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postMember, putMember } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IMedia | null;
    memberType: string;
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormMember = ({
    isModal = false,
    id,
    detail,
    memberType,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [keyShow, setKeyShow] = useState<boolean>(false);

    const STATUS_MEMBER_OPTIONS_LANG = STATUS_MEMBER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                ...(!!detail?.id ? {} : { member_type: memberType, }),
                name: values?.name,
                email: values?.email,
                password: values?.password,
                phone_number: values?.phone_number,
                business_name: values?.business_name,
                business_registration_number: values?.business_registration_number,
                address: values?.address,
                contact_name: values?.contact_name,
                contact_phone_number: values?.contact_phone_number,
                contact_email: values?.contact_email,
                contact_position: values?.contact_position,
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_MEMBER_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putMember(detail?.id, data) : await postMember(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        phone_number: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).max(30, `${t('Phone number should not exceed 30 digits')}`).nullable(),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, `${t('Please enter a valid email address')}`).required(`${t("This is required")}`),
        password: !!detail?.id ? Yup.string().min(8, t('Password must be at least 8 characters')).nullable() : Yup.string().min(8, t('Password must be at least 8 characters')).required(`${t("This is required")}`),

        business_name: Yup.string().nullable(),
        business_registration_number: Yup.string().nullable(),
        address: Yup.string().nullable(),

        contact_name: Yup.string().nullable(),
        contact_phone_number: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).max(30, `${t('Phone number should not exceed 30 digits')}`).nullable(),
        contact_email: Yup.string().email(`${t('Please enter a valid email address')}`).matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, `${t('Please enter a valid email address')}`).nullable(),
        contact_position: Yup.string().nullable(),

        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            phone_number: '',
            email: '',
            password: '',
            business_name: '',
            business_registration_number: '',
            address: '',
            contact_name: '',
            contact_phone_number: '',
            contact_email: '',
            contact_position: '',
            is_active: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_phone_number = valueDefault?.phone_number;
        const v_email = valueDefault?.email;
        const v_password = valueDefault?.password;

        const v_business_name = valueDefault?.business_name;
        const v_business_registration_number = valueDefault?.business_registration_number;
        const v_address = valueDefault?.address;

        const v_contact_name = valueDefault?.contact_name;
        const v_contact_phone_number = valueDefault?.contact_phone_number;
        const v_contact_email = valueDefault?.contact_email;
        const v_contact_position = valueDefault?.contact_position;

        const v_is_active = STATUS_MEMBER_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("phone_number", v_phone_number);
        formik.setFieldValue("email", v_email);
        formik.setFieldValue("password", v_password);

        formik.setFieldValue("business_name", v_business_name);
        formik.setFieldValue("business_registration_number", v_business_registration_number);
        formik.setFieldValue("address", v_address);

        formik.setFieldValue("contact_name", v_contact_name);
        formik.setFieldValue("contact_phone_number", v_contact_phone_number);
        formik.setFieldValue("contact_email", v_contact_email);
        formik.setFieldValue("contact_position", v_contact_position);

        formik.setFieldValue("is_active", v_is_active);

    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Row className="mt-2 pe-0">
                                                <Col md={5} lg={5} className="ps-2 pe-0 ps-md-0 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Status")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownStatus
                                                            name="is_active"
                                                            dataList={STATUS_MEMBER_OPTIONS_LANG || []}
                                                            placeholder={`${t("Status")}...`}
                                                            className="dropdown-status-rounded"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.is_active ||
                                                                STATUS_MEMBER_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("is_active", event)
                                                            }
                                                            colors={["danger", "success"]}
                                                            disabled={detail?.is_preview}
                                                        />
                                                        {formik.touched.is_active && formik.errors.is_active ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.is_active}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={7} lg={7} className="pe-0 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Member Name")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            autocomplete={false}
                                                            value={formik?.values?.name}
                                                            placeholder={`${t("Member Name")}...`}
                                                            onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                            disabled={detail?.is_preview}
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-4 mb-2" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col lg={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Email")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                autocomplete={false}
                                                                value={formik?.values?.email}
                                                                placeholder={`${t("Email")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("email", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.email}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Phone Number")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="phone_number"
                                                                name="phone_number"
                                                                autocomplete={false}
                                                                value={formik?.values?.phone_number}
                                                                placeholder={`${t("Contact Person Phone")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("phone_number", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.phone_number && formik.errors.phone_number ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.phone_number}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} className="mt-3">
                                                        <label className="form-label">
                                                            {t("Password")}
                                                            {!detail?.is_preview && !detail?.id && <span className="text-danger"> *</span>}
                                                        </label>
                                                        <div className="d-flex">
                                                            <div className="position-relative auth-pass-inputgroup w-100 ">
                                                                <Input
                                                                    className="form-control"
                                                                    id="password"
                                                                    name="password"
                                                                    type={keyShow ? "text" : "password"}
                                                                    value={formik?.values?.password}
                                                                    placeholder={`${t("Password")}...`}
                                                                    onChange={(event: any) => formik.setFieldValue("password", event?.target?.value || "")}
                                                                    disabled={detail?.is_preview}
                                                                    style={{ paddingRight: '40px' }}
                                                                />
                                                                <button
                                                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                                    onClick={() => setKeyShow(!keyShow)}
                                                                    type="button"
                                                                    id="password-addon"
                                                                >
                                                                    <i className="ri-eye-fill align-middle"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.password}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-4 mb-2" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col lg={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Business Name")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="business_name"
                                                                name="business_name"
                                                                autocomplete={false}
                                                                value={formik?.values?.business_name}
                                                                placeholder={`${t("Business Name")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("business_name", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.business_name && formik.errors.business_name ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.business_name}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Business Registration Number")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="business_registration_number"
                                                                name="business_registration_number"
                                                                autocomplete={false}
                                                                value={formik?.values?.business_registration_number}
                                                                placeholder={`${t("Business Registration Number")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("business_registration_number", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.business_registration_number && formik.errors.business_registration_number ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.business_registration_number}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Address")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="address"
                                                                name="address"
                                                                autocomplete={false}
                                                                value={formik?.values?.address}
                                                                placeholder={`${t("Address")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("address", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.address && formik.errors.address ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.address}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-4 mb-0" style={{ borderRadius: '5px' }}>
                                                <Row className="mt-1">
                                                    <Col lg={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Contact Person Name")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="contact_name"
                                                                name="contact_name"
                                                                autocomplete={false}
                                                                value={formik?.values?.contact_name}
                                                                placeholder={`${t("Contact Person Name")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("contact_name", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.contact_name && formik.errors.contact_name ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.contact_name}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Contact Person Position")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="contact_position"
                                                                name="contact_position"
                                                                autocomplete={false}
                                                                value={formik?.values?.contact_position}
                                                                placeholder={`${t("Contact Person Position")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("contact_position", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.contact_position && formik.errors.contact_position ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.contact_position}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Contact Person Email")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="contact_email"
                                                                name="contact_email"
                                                                autocomplete={false}
                                                                value={formik?.values?.contact_email}
                                                                placeholder={`${t("Contact Person Email")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("contact_email", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.contact_email && formik.errors.contact_email ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.contact_email}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Contact Person Phone")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="contact_phone_number"
                                                                name="contact_phone_number"
                                                                autocomplete={false}
                                                                value={formik?.values?.contact_phone_number}
                                                                placeholder={`${t("Contact Person Phone")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("contact_phone_number", event?.target?.value || "")}
                                                                disabled={detail?.is_preview}
                                                            />
                                                            {formik.touched.contact_phone_number && formik.errors.contact_phone_number ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.contact_phone_number}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Member") : t("Button Create Member")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormMember;
