import { IFrequency, IIndex } from "api/types/_master";
import CopyWrapper from "components/Common/CopyWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import { createSelector } from "reselect";
import {
  getConfigHistories as onGetConfigHistories
} from "../../../store/thunks";
import { CATEGORY_CONFIG_HISTORY_TYPE, TYPE_CONFIG_HISTORY_CATEGORY_OPTIONS } from "helpers/constans";

const SORT_BY_DEFAULT = "id";
const ORDER_BY_DEFAULT = "DESC";

registerLocale("en", en);
registerLocale("ko", ko);

interface Props {
  categoryId?: string | number;
  listIndex?: IIndex[];
  listFrequency?: IFrequency[];
}

const ModalCategoryHistory = ({
  categoryId,
  listIndex = [],
  listFrequency = [],

}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useState({
    page: 1,
    limit: 20,
    sort_by: SORT_BY_DEFAULT,
    order_by: ORDER_BY_DEFAULT,
    category_id: categoryId || "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const TYPE_CONFIG_HISTORY_CATEGORY_OPTIONS_LANG = TYPE_CONFIG_HISTORY_CATEGORY_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Category;

  const CategoryProperties = createSelector(selectLayoutState, (state) => ({
    histories: state.histories,
    isHistorySuccess: state.isHistorySuccess,
    isHistoryLoading: state.isHistoryLoading,
    error: state.error,
  }));

  // Inside your component
  const { histories, error, isHistoryLoading } = useSelector(CategoryProperties);


  useEffect(() => {
    if (!isHistoryLoading) {
      dispatch(onGetConfigHistories(query));
    }
  }, [dispatch, query]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "date",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: "120px" }}>
              {String(item?.date || "").replace("week", t("Week"))}
            </div>
          );
        },
      },
      {
        Header: t('Type'),
        accessor: "type",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-start',
        Cell: (cell: any) => {
          return (
            <div className="text-start" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${['secondary', 'success', 'danger', 'primary',][cell?.value] || 'secondary'}`}>{TYPE_CONFIG_HISTORY_CATEGORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Category"),
        accessor: "category.name",
        filterable: false,
        sortable: false,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ minWidth: "120px" }}>
            {cell?.value}
          </div>
        ),
      },
      {
        Header: t("Index"),
        accessor: "data",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const items = (item?.data || [])?.map((i: any) => {
            const indx = listIndex?.find(
              (v: any) => String(i?.index_type) === String(v?.id)
            );
            return {
              name: i18n?.language === "en" ? indx?.name : indx?.name_kr,
              ratio: i?.ratio,
            };
          });
          return (
            <>
              <div
                style={{ minWidth: "140px" }}
                className="cursor-pointer link"
              >
                {([CATEGORY_CONFIG_HISTORY_TYPE.INDEX_CONFIG, CATEGORY_CONFIG_HISTORY_TYPE.ISSUE_INDEX_CONFIG] as string[])?.includes(`${item?.type}`) && items?.length ? (
                  <div className="text-start">
                    {items?.map((e: any) => (
                      <div>
                        {e?.name} : {e?.ratio} %
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">-</div>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Frequency"),
        accessor: "frequencies",
        filterable: false,
        sortable: false,
        thWidth: 110,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr =
            (item?.data || [])?.length > 1
              ? Array(...(item?.data || [])).sort((a: number, b: number) => {
                return a - b;
              })
              : item?.data || [];
          const items = arr?.map((i: any) => {
            const freq = listFrequency?.find(
              (v: any) => String(i) === String(v?.id)
            );
            return {
              name: i18n?.language === "en" ? freq?.name : (freq?.name_korea || freq?.name_kr),
            };
          });
          return (
            <>
              <div
                style={{ minWidth: "100px" }}
                className="cursor-pointer"
              >
                {String(item?.type) === CATEGORY_CONFIG_HISTORY_TYPE.FREQUENCY && items?.length ? (
                  <div className="text-center">
                    {items?.map((e: any) => (
                      <div>{e?.name}</div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">-</div>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    [
      i18n?.language,
      userPermissions,
      JSON.stringify(query),
      JSON.stringify(listFrequency),
      JSON.stringify(listIndex),
    ]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, page: page + 1 };
    });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  return (
    <React.Fragment>
      <div>
        <div>
          {isHavePermissionRole(
            ROLES_FOR_APP.KEYWORD_MANAGEMENT,
            userPermissions
          ) && (
              <Row>
                <Col sm={12}>
                  <Card
                    id="customerList"
                    style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                  >
                    <CardHeader className="border-0">
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              <span className="me-2">{t("Total")}:</span>
                              <CountUp
                                start={0}
                                end={histories?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </h5>
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={histories?.list?.length ? histories?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={histories?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(histories?.pagination?.total) /
                          Number(histories?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isHistoryLoading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <ToastContainer closeButton={false} limit={1} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalCategoryHistory;
