import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import NoImage from '../../../../assets/images/no-photo-available.png';

import * as Yup from "yup";
import LoadingSkeleton from "./LoadingSkeleton";

const Singer = ({ keyword_info = {} }: any) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,

            };
            // const response: any = await putActor(data);
            // if (response?.code === 200) {
            //     setIsLoading((_prev) => false);
            //     toast(
            //         `${t("The process has been completed.")}`,
            //         CONFIG_OPTION_TOAST_NORMAL
            //     );
            //     triggerRefresh && triggerRefresh();
            // } else {
            //     setIsLoading((_prev) => false);
            //     toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            // }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            image_url: '',
            name: '',
            gender: '',
            birthday: '',
            star_sign: '',
            real_name: '',
            nationality: '',
            debut_date: '',
            active_years: '',
            activity_type: '',
            agency: '',
            genre: '',
            group_members: '',
            albums: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        // setTimeout(() => {
        //     formik.setErrors({});
        // }, 300);
        // if (keyword_info) {
        //     formik.setFieldValue("image_url", keyword_info?.image_url);
        //     formik.setFieldValue("name", keyword_info?.name);
        //     formik.setFieldValue("gender", keyword_info?.gender);
        //     formik.setFieldValue("birthday", keyword_info?.birthday);
        //     formik.setFieldValue("star_sign", keyword_info?.star_sign);
        //     formik.setFieldValue("real_name", keyword_info?.real_name);
        //     formik.setFieldValue("nationality", keyword_info?.nationality);
        //     formik.setFieldValue("debut_date", keyword_info?.debut_date);
        //     formik.setFieldValue("active_years", keyword_info?.active_years);
        //     formik.setFieldValue("activity_type", keyword_info?.activity_type);
        //     formik.setFieldValue("agency", keyword_info?.agency);
        //     formik.setFieldValue("genre", JSON.stringify(keyword_info?.genre));
        //     formik.setFieldValue("group_members", JSON.stringify(keyword_info?.group_members));
        //     formik.setFieldValue("albums", JSON.stringify(keyword_info?.albums));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyword_info]);

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-2 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#eaeaea'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {/* <input type="text" className="form-control" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                        style={{ fontSize: '1.2rem', fontWeight: '800' }}
                                    /> */}
                                    {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.name || '-'}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Gender')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.gender} onChange={(e) => formik.setFieldValue("gender", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : t(String(keyword_info?.gender || '-').toUpperCase())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Birthday')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.birthday} onChange={(e) => formik.setFieldValue("birthday", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.birthday || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Star Sign')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.star_sign} onChange={(e) => formik.setFieldValue("star_sign", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.star_sign || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Real Name')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.real_name} onChange={(e) => formik.setFieldValue("real_name", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.real_name || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Nationality')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.nationality} onChange={(e) => formik.setFieldValue("nationality", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.nationality || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Debut Date')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.debut_date} onChange={(e) => formik.setFieldValue("debut_date", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.debut_date || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Active Years')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.active_years} onChange={(e) => formik.setFieldValue("active_years", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.active_years || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Activity Type')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.activity_type} onChange={(e) => formik.setFieldValue("activity_type", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : t(String(keyword_info?.activity_type || '-').toUpperCase())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Celeb of Agency')}</td>
                                        <td className="p-2 text-start">
                                            {/* <input type="text" className="form-control" value={formik.values.agency} onChange={(e) => formik.setFieldValue("agency", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.agency || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Genre')}</td>
                                        <td className="p-2 text-start">
                                            {/* <textarea rows={5} className="form-control" value={formik.values.genre} onChange={(e) => formik.setFieldValue("genre", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : (Array.isArray(keyword_info?.genre) ? keyword_info?.genre.join(', ') : keyword_info?.genre || '')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Group Members')}</td>
                                        <td className="p-2 text-start">
                                            {/* <textarea rows={5} className="form-control" value={formik.values.group_members} onChange={(e) => formik.setFieldValue("group_members", e.target.value)} /> */}
                                            {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.group_members || [])?.map((member: any) => (<>{member?.name} ({member?.code}) <br /></>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Awards')}</td>
                                        <td className="p-2 text-start">
                                            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (Array.isArray(keyword_info?.awards) ? (keyword_info?.awards || [])?.map((award: any, index: number) => (<div>{index + 1}.  {award || ''}<br /></div>)) : keyword_info?.awards || '')}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Descriptions')}</td>
                                        <td className="p-2 text-start">

                                            {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.descriptions || [])?.map((description: any) => (<><div dangerouslySetInnerHTML={{ __html: description }} /> <br /></>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Albums')}</td>
                                        <td className="p-2 text-start">
                                            {/* <textarea rows={5} className="form-control" value={formik.values.albums} onChange={(e) => formik.setFieldValue("albums", e.target.value)} /> */}

                                            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.albums || [])?.map((album: any, index: number) => (<div>{index + 1}.  <span dangerouslySetInnerHTML={{ __html: album?.name }} /> <span className="text-muted ms-2">({album?.release_date || '-'})</span> <br /></div>))}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Related Info')}</td>
                                        <td className="p-2 text-start">
                                            {!keyword_info?.name ? <LoadingSkeleton height="123px" /> : <textarea rows={5} className="form-control">
                                                {keyword_info?.related_info ? JSON.stringify(keyword_info?.related_info) : ''}
                                            </textarea>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('News Searching Keyword')}</td>
                                        <td className="p-2 text-start">
                                            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.noun_type_b || [])?.map((noun: any, index: number) => (<div>{index + 1}.  <span>{noun || ''}</span> <br /></div>))}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(Singer);