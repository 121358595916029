import { createSlice } from "@reduxjs/toolkit";
import { ICollecting, IKeyword, IStockKeyword } from "api/types/_keyword";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCollecting, getKeywords, getKeywordsByCategory, getKeywordsMonitor, getNewsSearchingKeyword, getStockKeywords } from "./thunk";

export interface IState {
  keywords: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  keywordsMonitor: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordMonitorLoading: boolean,
  isKeywordMonitorSuccess: boolean,

  stockKeywords: ResponseData<IStockKeyword[]> & PaginationResponse | null,
  isStockKeywordLoading: boolean,
  isStockKeywordSuccess: boolean,

  collecting: ResponseData<ICollecting[]> & PaginationResponse | null,
  isCollectingLoading: boolean,
  isCollectingSuccess: boolean,

  searching: ResponseData<IKeyword[]> & PaginationResponse | null,
  isSearchingLoading: boolean,
  isSearchingSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,

  keywordsMonitor: null,
  isKeywordMonitorLoading: false,
  isKeywordMonitorSuccess: false,

  stockKeywords: null,
  isStockKeywordLoading: false,
  isStockKeywordSuccess: false,

  collecting: null,
  isCollectingLoading: false,
  isCollectingSuccess: false,

  searching: null,
  isSearchingLoading: false,
  isSearchingSuccess: false,

  error: {},
};

const CategorySlice = createSlice({
  name: "Keyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //keywords
    builder.addCase(getKeywords.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(getKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(getKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });

    //keywords monitor
    builder.addCase(getKeywordsMonitor.pending, (state: IState, action: any) => {
      state.isKeywordMonitorLoading = true
    });
    builder.addCase(getKeywordsMonitor.fulfilled, (state: IState, action: any) => {
      state.keywordsMonitor = action.payload.data;
      state.isKeywordMonitorSuccess = true;
      state.isKeywordMonitorLoading = false;
    });
    builder.addCase(getKeywordsMonitor.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordMonitorSuccess = false;
      state.isKeywordMonitorLoading = false;
    });

    //stock keywords
    builder.addCase(getStockKeywords.pending, (state: IState, action: any) => {
      state.isStockKeywordLoading = true
    });
    builder.addCase(getStockKeywords.fulfilled, (state: IState, action: any) => {
      state.stockKeywords = action.payload.data;
      state.isStockKeywordSuccess = true;
      state.isStockKeywordLoading = false;
    });
    builder.addCase(getStockKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isStockKeywordSuccess = false;
      state.isStockKeywordLoading = false;
    });

    //keywords by category
    builder.addCase(getKeywordsByCategory.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(getKeywordsByCategory.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(getKeywordsByCategory.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });

    //collecting
    builder.addCase(getCollecting.pending, (state: IState, action: any) => {
      state.isCollectingLoading = true
    });
    builder.addCase(getCollecting.fulfilled, (state: IState, action: any) => {
      state.collecting = action.payload.data;
      state.isCollectingSuccess = true;
      state.isCollectingLoading = false;
    });
    builder.addCase(getCollecting.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCollectingSuccess = false;
      state.isCollectingLoading = false;
    });

    //searching
    builder.addCase(getNewsSearchingKeyword.pending, (state: IState, action: any) => {
      state.isSearchingLoading = true
    });
    builder.addCase(getNewsSearchingKeyword.fulfilled, (state: IState, action: any) => {
      state.searching = action.payload.data;
      state.isSearchingSuccess = true;
      state.isSearchingLoading = false;
    });
    builder.addCase(getNewsSearchingKeyword.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSearchingSuccess = false;
      state.isSearchingLoading = false;
    });
  },
});

export default CategorySlice.reducer;