import { ICategory, ICondition } from "api/types/_category";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_AUTO_SELECTED_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteCondition, getAllCategoriesSimple, getConditions as onGetConditions } from "../../../store/thunks";
import FormCategoryQualification from "./FormCategoryQualification";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const CategoryQualificationSetting = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_AUTO_SELECTED_OPTIONS_LANG = STATUS_AUTO_SELECTED_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        category_id: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Category;

    const CategoryProperties = createSelector(
        selectLayoutState,
        (state) => ({
            conditions: state.conditions,
            isConditionSuccess: state.isConditionSuccess,
            isConditionLoading: state.isConditionLoading,
            error: state.error,
        })
    );

    const { conditions, isConditionLoading } = useSelector(CategoryProperties);

    const [categorySearch, setCategorySearch] = useState<Option | null>(null);

    const [conditionDetail, setConditionDetail] = useState<ICondition | null>(null);

    const [listCategory, setListCategory] = useState<ICategory[]>([]);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetConditions(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            category_id: categorySearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            category_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setCategorySearch(null);
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setConditionDetail((_prev) => null); }
        resetData();
    }

    function countMonths(start_month: string = '', end_month: string = '') {
        if (!start_month || !end_month || start_month === 'Invalid date' || end_month === 'Invalid date') {
            return 0;
        }
        const startDate = moment(start_month, "YYYY-MM-DD");
        const endDate = moment(end_month, "YYYY-MM-DD");
        const months = endDate.diff(startDate, 'months');
        return months;
    }
    function formatDateToMonth(date_string: string = '') {
        const date = moment(date_string, "YYYY-MM-DD", true);
        return (!!date.isValid() ? moment(date_string).format("YYYY-MM") : '');
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Category (Level 1)'),
                accessor: "category",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '150px' }}>
                                {item?.category?.name || ''}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Date Range (months)'),
                accessor: "date_start",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '220px' }}>
                                <>{formatDateToMonth(item?.date_start || '')}</> <span className="px-2">→</span> <>{formatDateToMonth(item?.date_end || '')}</> <span className="ms-2">({countMonths(item?.date_start || '', item?.date_end || '')})</span>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Mandatory Article Count'),
                accessor: "num_of_news_required",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '170px' }}>
                                {formatNumberWithCommas(cell?.value || '0')}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Official Media Count'),
                accessor: "official_media_count",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '140px' }}>
                                {formatNumberWithCommas(cell?.value || '0')}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Condition')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setConditionDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Condition')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setConditionDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Media')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_AUTO_SELECTED_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IMedia) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteCondition(idDelete);
            if (response?.code === 200) {
                dispatch(onGetConditions(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleActionRefresh = () => {
        dispatch(onGetConditions(query));
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1]: any = await Promise.all([getAllCategoriesSimple({ depth: 1 })]);
                setListCategory((_prev: any) => res1?.data || []);
                if (query?.category_id) {
                    setCategorySearch((_prev) => {
                        const item = res1?.data?.find((item: any) => (String(query?.category_id || '') === String(item?.id || '')));
                        return ({
                            label: item?.name,
                            value: `${item?.id || ''}`,
                        });
                    });
                }
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        if (!query?.category_id) {
            setCategorySearch({ label: t('All Category'), value: '' });
        }

        document.title = `${t("Category Qualification Settings")} - ${t("Official Media Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Category Qualification Settings')} pageTitle={t('Official Media Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_SETTINGS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={conditions?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setConditionDetail((_prev) => ({} as ICondition))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Condition')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Category (Level 1)')} isShow={true}>
                                                    <DropdownOption
                                                        name="category"
                                                        dataList={listCategory?.map((item: any) => ({ value: String(item?.id), label: item?.name }))}
                                                        placeholder={`${t("Category")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={categorySearch || null}
                                                        onChangeSelect={(e: any) => setCategorySearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Category'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={8} xl={9} xxl={10} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isConditionLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={conditions?.list?.length ? conditions?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={conditions?.pagination?.total}
                                            customPageCount={Math.ceil(Number(conditions?.pagination?.total) / Number(conditions?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isConditionLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(conditionDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-500'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setConditionDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!conditionDetail?.id ? (!!conditionDetail?.is_preview ? t('Button Detail Condition') : t('Button Update Condition')) : t('Button Create Condition')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormCategoryQualification detail={conditionDetail} categories={listCategory || []} triggerRefresh={handleRefresh} handleClose={() => setConditionDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={conditionDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setConditionDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setConditionDetail((_prev) => null)}>
                                    {!!conditionDetail?.id ? (!!conditionDetail?.is_preview ? t('Button Detail Condition') : t('Button Update Condition')) : t('Button Create Condition')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormCategoryQualification detail={conditionDetail} categories={listCategory || []} triggerRefresh={handleRefresh} handleClose={() => setConditionDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Condition')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default CategoryQualificationSetting;