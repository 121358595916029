import { createSlice } from "@reduxjs/toolkit";
import { IMedia, IOfficialMedia, IOfficialMediaRanking } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getMedias, getOfficialMediaRanking, getOfficialMedias } from "./thunk";


export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  officialmedias: ResponseData<IOfficialMedia[]> & PaginationResponse | null,
  isOfficialMediaLoading: boolean,
  isOfficialMediaSuccess: boolean,

  officialMediaRanking: ResponseData<IOfficialMediaRanking[]> & PaginationResponse | null,
  isOfficialMediaRankingLoading: boolean,
  isOfficialMediaRankingSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  officialmedias: null,
  isOfficialMediaLoading: false,
  isOfficialMediaSuccess: false,

  officialMediaRanking: null,
  isOfficialMediaRankingLoading: false,
  isOfficialMediaRankingSuccess: false,

  error: {},
};

const MediaSlice = createSlice({
  name: "Media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });


    //get official medias
    builder.addCase(getOfficialMedias.pending, (state: IState, action: any) => {
      state.isOfficialMediaLoading = true
    });
    builder.addCase(getOfficialMedias.fulfilled, (state: IState, action: any) => {
      state.officialmedias = action.payload.data;
      state.isOfficialMediaSuccess = true;
      state.isOfficialMediaLoading = false;
    });
    builder.addCase(getOfficialMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isOfficialMediaSuccess = false;
      state.isOfficialMediaLoading = false;
    });

    //get official media ranking
    builder.addCase(getOfficialMediaRanking.pending, (state: IState, action: any) => {
      state.isOfficialMediaRankingLoading = true
    });
    builder.addCase(getOfficialMediaRanking.fulfilled, (state: IState, action: any) => {
      state.officialMediaRanking = action.payload.data;
      state.isOfficialMediaRankingSuccess = true;
      state.isOfficialMediaRankingLoading = false;
    });
    builder.addCase(getOfficialMediaRanking.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isOfficialMediaRankingSuccess = false;
      state.isOfficialMediaRankingLoading = false;
    });
  },
});

export default MediaSlice.reducer;