import { createSlice } from "@reduxjs/toolkit";
import { IPaymentHistory } from "api/types/_payment";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getPaymentHistories, getSubscriptions } from "./thunk";
import { ISubscription } from "api/types/_subscription";

export interface IState {
  paymentHistories: ResponseData<IPaymentHistory[]> & PaginationResponse | null,
  isPaymentHistoryLoading: boolean,
  isPaymentHistorySuccess: boolean,

  subscriptions: ResponseData<ISubscription[]> & PaginationResponse | null,
  isSubscriptionLoading: boolean,
  isSubscriptionSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  paymentHistories: null,
  isPaymentHistoryLoading: false,
  isPaymentHistorySuccess: false,

  subscriptions: null,
  isSubscriptionLoading: false,
  isSubscriptionSuccess: false,

  error: {},
};

const PaymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //participation templates
    builder.addCase(getPaymentHistories.pending, (state: IState, action: any) => {
      state.isPaymentHistoryLoading = true
    });
    builder.addCase(getPaymentHistories.fulfilled, (state: IState, action: any) => {
      state.paymentHistories = action.payload.data;
      state.isPaymentHistorySuccess = true;
      state.isPaymentHistoryLoading = false;
    });
    builder.addCase(getPaymentHistories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPaymentHistorySuccess = false;
      state.isPaymentHistoryLoading = false;
    });

    //subscriptions
    builder.addCase(getSubscriptions.pending, (state: IState, action: any) => {
      state.isSubscriptionLoading = true
    });
    builder.addCase(getSubscriptions.fulfilled, (state: IState, action: any) => {
      state.subscriptions = action.payload.data;
      state.isSubscriptionSuccess = true;
      state.isSubscriptionLoading = false;
    });
    builder.addCase(getSubscriptions.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSubscriptionSuccess = false;
      state.isSubscriptionLoading = false;
    })
  },
});

export default PaymentSlice.reducer;