import { IParticipationTemplate } from "api/types/_participation";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY, TYPE_PARTICIPATION_TEMPLATE_IS_VOTE, TYPE_PARTICIPATION_TEMPLATE_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postParticipationTemplate, putParticipationTemplate } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IParticipationTemplate | null;
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormTemplate = ({
    isModal = false,
    id,
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);


    const OPERATOR_OPTIONS_LANG = [{ label: '+', value: '+' }, { label: '-', value: '-' }, { label: '*', value: '*' }, { label: '/', value: '/' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const LIMIT_DAILY_OPTIONS_LANG = [{ label: 'No limit', value: '0' }, { label: '1 Time', value: '1' }, { label: '10 Time', value: '10' }, { label: '20 Time', value: '20' }, { label: '50 Time', value: '50' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG = TYPE_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                daily_limit_used: Number(values?.daily_limit_used?.value || 0),
                // question_content: values?.question_content || '',
                submission_limit: Number(values?.submission_limit || 0),
                type: Number(values?.type?.value || TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG[0]?.value),
                answers: (values?.answers || [])?.map((item: any) => ({
                    content: item?.content || '',
                    operator: item?.operator?.value || OPERATOR_OPTIONS_LANG[0]?.value,
                    value: Number(item?.value || 0)
                }))
            };
            const response: any = detail?.id ? await putParticipationTemplate(detail?.id, data) : await postParticipationTemplate(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        // question_content: Yup.string().nullable(),
        daily_limit_used: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        submission_limit: Yup.string().required(`${t("This is required")}`),
        type: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        answers: Yup.array()
            .of(
                Yup.object().shape({
                    content: Yup.string().test('is-required', `${t("This is required")}`,
                        function (value) {
                            const { type } = formik?.values;
                            if (!!type && String(type?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY)) {
                                return !!value;
                            }
                            return true;
                        }),
                    operator: Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                    }).nullable(),
                    value: Yup.string().test('is-required', `${t("This is required")}`,
                        function (value) {
                            const { type } = formik?.values;
                            if (!!type && String(type?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY)) {
                                return !!value;
                            }
                            return true;
                        }),
                }).nullable()
            ).test('is-required', `${t("This is required")}`,
                function (value) {
                    const { type } = this.parent;
                    if (!!type && String(type?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY)) {
                        return !!value;
                    }
                    return true;
                }
            ),
        // .required(`${t("This is required")}`)
        // .min(1, `${t("At least 1 tracking code is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            // question_content: '',
            type: TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG[0],
            daily_limit_used: null,
            submission_limit: '1',
            answers: [{
                content: '',
                operator: OPERATOR_OPTIONS_LANG[0],
                value: '0'
            }],
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_daily_limit_used = LIMIT_DAILY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.daily_limit_used));
        const v_type = TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.type));
        const v_submission_limit = valueDefault?.submission_limit;
        const v_answers = (valueDefault?.question?.answers || [])?.map((item: any) => (
            {
                content: item?.content || '',
                operator: OPERATOR_OPTIONS_LANG?.find((e) => String(e?.value) === String(item?.operator)),
                value: item?.value || 0,
            }
        ));
        formik.setFieldValue("name", v_name);
        formik.setFieldValue("daily_limit_used", v_daily_limit_used);
        formik.setFieldValue("type", v_type);
        formik.setFieldValue("submission_limit", v_submission_limit);
        formik.setFieldValue("answers", v_answers);
    };

    const handleAction = (typeAction: string, ind: number = 0) => {
        if (typeAction === 'REMOVE') {
            const answers = formik?.values?.answers?.filter((item, index) => index !== ind);
            formik.setFieldValue("answers", answers);
            return;
        }
        if (typeAction === 'ADD') {
            const answers = [...formik?.values?.answers, {
                content: '',
                operator: OPERATOR_OPTIONS_LANG[0],
                value: '0'
            }];
            formik.setFieldValue("answers", answers);
            return;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Row className="mt-2 pe-0">
                                                <Col md={12} lg={12} className="pe-0 pe-md-0">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Template Name")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            autocomplete={false}
                                                            value={formik?.values?.name}
                                                            placeholder={`${t("Template Name")}...`}
                                                            onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                            disabled={!!detail?.is_preview}
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Participation Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="operator"
                                                        dataList={TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Participation Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.type ||
                                                            TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue('type', event)
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {/* {formik.touched.type && formik.errors.type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.type}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-3 mt-4 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Create an Answers')}</span>
                                                {
                                                    (formik?.values?.answers || [])?.map((item: any, index: any) => (
                                                        <Row className="mb-2 align-items-center" key={index}>
                                                            <Col sm={5} md={5} lg={5} className="mb-2 mb-md-2">
                                                                <div>
                                                                    <Input
                                                                        type="text"
                                                                        id="content"
                                                                        name="content"
                                                                        autocomplete={false}
                                                                        disabled={String((formik?.values?.type as any)?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) || !!detail?.is_preview}
                                                                        value={(formik?.values?.answers?.[index] as any)?.content}
                                                                        placeholder={`${t("Answer Title")}...`}
                                                                        onChange={(event: any) => formik.setFieldValue(`answers[${index}]content`, event?.target?.value || "")}
                                                                        style={{
                                                                            ...((formik?.touched?.answers?.[index] as any)?.content && (formik?.errors?.answers?.[index] as any)?.content ? { borderColor: 'rgba(var(--vz-danger-rgb))' } : '')
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={6} sm={3} md={3} lg={3} className="mb-2 mb-md-2">
                                                                <div>
                                                                    <DropdownOption
                                                                        name="operator"
                                                                        dataList={OPERATOR_OPTIONS_LANG || []}
                                                                        placeholder={`${t("Operator")}...`}
                                                                        className="dropdown-status-rounded text-center"
                                                                        classNamePrefix="name-prefix"
                                                                        initialValue={
                                                                            (formik?.values?.answers?.[index] as any)?.operator ||
                                                                            OPERATOR_OPTIONS_LANG[0]
                                                                        }
                                                                        onChangeSelect={(event: any) =>
                                                                            formik.setFieldValue(`answers[${index}]operator`, event)
                                                                        }
                                                                        disabled={String((formik?.values?.type as any)?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) || !!detail?.is_preview}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={6} sm={3} md={3} lg={3} className="mb-2 mb-md-2">
                                                                <div>
                                                                    <Input
                                                                        type="text"
                                                                        id="value"
                                                                        name="value"
                                                                        autocomplete={false}
                                                                        value={formatNumberWithCommas((formik?.values?.answers?.[index] as any)?.value)}
                                                                        placeholder={`${t("Answer Score")}...`}
                                                                        onChange={(event: any) => {
                                                                            const input = event.target.value;
                                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                                            const s = onlyNumbers;
                                                                            formik.setFieldValue(`answers[${index}]value`, s)
                                                                        }}
                                                                        style={{
                                                                            ...((formik?.touched?.answers?.[index] as any)?.value && (formik?.errors?.answers?.[index] as any)?.value ? { borderColor: 'rgba(var(--vz-danger-rgb))' } : '')
                                                                        }}
                                                                        disabled={String((formik?.values?.type as any)?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) || !!detail?.is_preview}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={1} md={1} lg={1} className="text-end text-sm-start px-sm-0 mb-2 mb-md-2">
                                                                {(formik?.values?.answers?.length > 1 && (Number(index + 1) < Number(formik?.values?.answers?.length))) && (<button
                                                                    className="btn btn-danger btn-sm  rounded-pill fs-14"
                                                                    color="light"
                                                                    type="button"
                                                                    onClick={() => handleAction('REMOVE', index)}
                                                                    disabled={isLoading || String((formik?.values?.type as any)?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) || !!detail?.is_preview}

                                                                >
                                                                    <i className=" ri-delete-bin-4-line  align-middle" />
                                                                </button>)}
                                                                {(Number(index + 1) === Number(formik?.values?.answers?.length)) && (<button
                                                                    className="btn btn-success btn-sm  rounded-pill fs-14"
                                                                    color="light"
                                                                    type="button"
                                                                    onClick={() => handleAction('ADD')}
                                                                    disabled={isLoading || String((formik?.values?.type as any)?.value) === String(TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) || !!detail?.is_preview}
                                                                >
                                                                    <i className="ri-add-fill  align-middle" />
                                                                </button>)}
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </Col>
                                            <Col md={6} lg={6} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Number of duplicate answers")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="submission_limit"
                                                        name="submission_limit"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.submission_limit)}
                                                        placeholder={`${t("Number of duplicate answers")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("submission_limit", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.submission_limit && formik.errors.submission_limit ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.submission_limit}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Participation Limit (1 Day)")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="operator"
                                                        dataList={LIMIT_DAILY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Participation Limit (1 Day)")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.daily_limit_used ||
                                                            LIMIT_DAILY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue('daily_limit_used', event)
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.daily_limit_used && formik.errors.daily_limit_used ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.daily_limit_used}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Participation Template") : t("Button Create Participation Template")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormTemplate;
