import { IDataSource, IFrequency, IIndex } from "api/types/_master";
import { ISearchIndex } from "api/types/_trendindex";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    STATUS_TREND_INDEX_OPTIONS
} from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import { postTrendIndex, putTrendIndex } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ISearchIndex | null;
    indexType: number | string,
    indexTypes?: IIndex[];
    frequencyTypes?: IFrequency[];
    dataSources?: IDataSource[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const Form = ({
    detail,
    indexType,
    indexTypes = [],
    frequencyTypes = [],
    dataSources = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const STATUS_TREND_INDEX_OPTIONS_LANG = STATUS_TREND_INDEX_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const FREQUENCY_TYPE_OPTIONS_LANG = frequencyTypes?.reduce(
        (arr: any, item: any) =>
            item?.id ? [...arr, { value: String(item?.id), label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr) }] : arr, []
    );

    const DATA_SOURCE_OPTIONS_LANG = dataSources?.reduce(
        (arr: any, item: any) =>
            (item?.id) ? [...arr, { value: String(item?.id), label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr) }] : arr, []
    );

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                ratio: Number(values?.ratio || 0),
                index_type: Number(indexType),
                frequency_type: Number(values?.frequency_type?.value),
                data_source: Number(values?.data_source?.value),
                status: Number(values?.status ? values?.status?.value : STATUS_TREND_INDEX_OPTIONS_LANG[0]?.value), // 0 | 1,
                collect_method: 'api'
            };
            const response: any = detail?.id
                ? await putTrendIndex(detail?.id, data)
                : await postTrendIndex(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        data_source: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).required(`${t("This is required")}`),
        ratio: Yup.number().required(`${t("This is required")}`),
        frequency_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).required(`${t("This is required")}`),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            data_source: null,
            ratio: 100,
            // index_type: null,
            frequency_type: null,
            status: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {

        const v_name = valueDefault?.name;
        const v_ratio = valueDefault?.ratio;

        const v_data_source = DATA_SOURCE_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.data_source)
        );

        const v_frequency_type = FREQUENCY_TYPE_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.frequency_type)
        );

        const v_status = STATUS_TREND_INDEX_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("data_source", v_data_source);
        formik.setFieldValue("ratio", v_ratio);
        // formik.setFieldValue("index_type", v_index_type);
        formik.setFieldValue("frequency_type", v_frequency_type);
        formik.setFieldValue("status", v_status);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={6} lg={6}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_TREND_INDEX_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_TREND_INDEX_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "primary", "success"]}
                                                        otherColor="danger"
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.status && formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Weight")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="ratio"
                                                        name="ratio"
                                                        autocomplete={false}
                                                        value={formik?.values?.ratio}
                                                        placeholder={`${t("Weight")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, "");
                                                            const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                                                            formik.setFieldValue("ratio", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.ratio && formik.errors.ratio ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.ratio}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Media")}...`}
                                                        onChange={(event: any) =>
                                                            formik.setFieldValue(
                                                                "name",
                                                                event?.target?.value || ""
                                                            )
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Data Source")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="data_source"
                                                        dataList={DATA_SOURCE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Data Source")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.data_source || null}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("data_source", event)
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.data_source && formik.errors.data_source ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.data_source}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {/* <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Index Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="index_type"
                                                        dataList={INDEX_TYPE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Index Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.index_type || null}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("index_type", event)
                                                        }
                                                    />
                                                    {formik.touched.index_type &&
                                                        formik.errors.index_type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.index_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col> */}

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Frequency Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="frequency_type"
                                                        dataList={FREQUENCY_TYPE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Frequency Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.frequency_type || null}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("frequency_type", event)
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.frequency_type &&
                                                        formik.errors.frequency_type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.frequency_type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: "100px" }}
                                                        onClick={() => {
                                                            handleClose && handleClose();
                                                        }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (
                                                        <button
                                                            className="btn btn-primary rounded-pill fs-14"
                                                            color="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                            style={{ width: "150px" }}
                                                        >
                                                            {isLoading ? (
                                                                <Spinner size="sm" className="me-2"></Spinner>
                                                            ) : (
                                                                <>
                                                                    {" "}
                                                                    {detail?.id
                                                                        ? t("Button Update")
                                                                        : t("Button Create")}
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default Form;
