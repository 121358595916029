import { ICategory } from "api/types/_category";
import { IParticipationCategory, IParticipationTemplate } from "api/types/_participation";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownOptionCategory from "components/Common/DropdownOptionCategory";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_PARTICIPATION_CATEGORY_OPTIONS, TYPE_PARTICIPATION_TEMPLATE_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Row,
    Spinner
} from "reactstrap";
import { postParticipationCategory, putParticipationCategory } from "store/thunks";

import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IParticipationCategory | null;
    templates: IParticipationTemplate[],
    categories: ICategory[],
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormCategory = ({
    isModal = false,
    id,
    detail,
    templates = [],
    categories = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<any>(categories || []);
    const [categoryDropdown, setCategoryDropdown] = useState<any>({});

    const STATUS_PARTICIPATION_CATEGORY_OPTIONS_LANG = STATUS_PARTICIPATION_CATEGORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG = TYPE_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TEMPLATE_OPTIONS_LANG = templates?.map((item: any) => ({
        label: `[${TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((type) => String(type?.value) === String(item?.type))?.label || ''}] ${item?.name}`,
        value: String(item?.id)
    }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                category_id: Number(values?.category_id || ''),
                form_id: Number(values?.form_id?.value || TEMPLATE_OPTIONS_LANG[0]?.value),
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_PARTICIPATION_CATEGORY_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putParticipationCategory(detail?.id, data) : await postParticipationCategory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        category_id: Yup.string().required(`${t("This is required")}`),
        form_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            category_id: '',
            is_active: null,
            form_id: TEMPLATE_OPTIONS_LANG[0]
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_category_id = valueDefault?.category_id;
        const v_is_active = STATUS_PARTICIPATION_CATEGORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );
        const v_form_id = TEMPLATE_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.form_id)
        );
        formik.setFieldValue("category_id", v_category_id);
        formik.setFieldValue("form_id", v_form_id);
        formik.setFieldValue("is_active", v_is_active);

        for (const cate of ([...(valueDefault?.categories)]?.reverse())) {
            if (cate?.depth === 1) {
                const ca = optionCategoryDropdown?.find((c: any) => c?.id === cate?.id);
                setCategoryDropdown((prev: any) => ({ [`level_${cate?.depth}`]: { ...ca, value: ca?.id, label: ca?.name } }));
            } else {
                setCategoryDropdown((prev: any) => {
                    return ({
                        ...prev,
                        [`level_${cate?.depth}`]: {
                            value: cate?.id,
                            label: cate?.name,
                            subs: (prev?.[`level_${cate?.depth - 1}`]?.subs || [])?.find((c: any) => c?.id === cate?.id)?.subs || []
                        }
                    })
                });
            }
        }
    };

    useEffect(() => {
        formik.setFieldValue('category_id', categoryDropdown?.level_5?.value || categoryDropdown?.level_4?.value || categoryDropdown?.level_3?.value || categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value)
    }, [JSON.stringify(categoryDropdown)]);

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Row className={`mt-2 pe-0`} >
                                                <Col md={12} lg={12} className="pe-0 pe-md-0">
                                                    <Row>
                                                        <Col sm={12} md={12} lg={12} className='mt-3 mt-sm-3 mt-md-2'>
                                                            {t('Category')}
                                                        </Col>
                                                        <Col md={6} lg={6} className='mt-3'>
                                                            <LabelWrapper label={t('Level 1')} isShow={true}>
                                                                <DropdownOptionCategory
                                                                    name=""
                                                                    dataList={optionCategoryDropdown || [{ label: t('-----'), value: '' }]}
                                                                    placeholder={`${t("Level 1")}...`}
                                                                    className="search-filter-category-type dropdown-status-rounded"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={categoryDropdown?.level_1 || null}
                                                                    disabled={!!detail?.id}
                                                                    onChangeSelect={(e: any) => {
                                                                        setCategoryDropdown((prev: any) => ({
                                                                            ...prev,
                                                                            level_1: e,
                                                                        }))
                                                                    }}
                                                                    isHasOptionAll={true}
                                                                    optionAll={{ label: t('-----'), value: '' }}
                                                                />
                                                            </LabelWrapper>
                                                        </Col>
                                                        <Col md={6} lg={6} className='mt-3'>
                                                            <LabelWrapper label={t('Level 2')} isShow={true}>
                                                                <DropdownOptionCategory
                                                                    name=""
                                                                    dataList={categoryDropdown?.level_1?.subs || [{ label: t('-----'), value: '' }]}
                                                                    placeholder={`${t("Level 2")}...`}
                                                                    className="search-filter-category-type dropdown-status-rounded"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={categoryDropdown?.level_2 || null}
                                                                    disabled={!!detail?.id}
                                                                    onChangeSelect={(e: any) => {
                                                                        setCategoryDropdown((prev: any) => ({
                                                                            ...prev,
                                                                            level_2: e,
                                                                        }))
                                                                    }}
                                                                    isHasOptionAll={true}
                                                                    optionAll={{ label: t('-----'), value: '' }}
                                                                />
                                                            </LabelWrapper>
                                                        </Col>
                                                        <Col md={6} lg={6} className='mt-3'>
                                                            <LabelWrapper label={t('Level 3')} isShow={true}>
                                                                <DropdownOptionCategory
                                                                    name=""
                                                                    dataList={categoryDropdown?.level_2?.subs || [{ label: t('-----'), value: '' }]}
                                                                    placeholder={`${t("Level 3")}...`}
                                                                    className="search-filter-category-type dropdown-status-rounded"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={categoryDropdown?.level_3 || null}
                                                                    disabled={!!detail?.id}
                                                                    onChangeSelect={(e: any) => {
                                                                        setCategoryDropdown((prev: any) => ({
                                                                            ...prev,
                                                                            level_3: e,
                                                                        }))
                                                                    }}
                                                                    isHasOptionAll={true}
                                                                    optionAll={{ label: t('-----'), value: '' }}
                                                                />
                                                            </LabelWrapper>
                                                        </Col>
                                                        <Col md={6} lg={6} className='mt-3'>
                                                            <LabelWrapper label={t('Level 4')} isShow={true}>
                                                                <DropdownOptionCategory
                                                                    name=""
                                                                    dataList={categoryDropdown?.level_3?.subs || [{ label: t('-----'), value: '' }]}
                                                                    placeholder={`${t("Level 4")}...`}
                                                                    className="search-filter-category-type dropdown-status-rounded"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={categoryDropdown?.level_4 || null}
                                                                    disabled={!!detail?.id}
                                                                    onChangeSelect={(e: any) => {
                                                                        setCategoryDropdown((prev: any) => ({
                                                                            ...prev,
                                                                            level_4: e,
                                                                        }))
                                                                    }}
                                                                    isHasOptionAll={true}
                                                                    optionAll={{ label: t('-----'), value: '' }}
                                                                />
                                                            </LabelWrapper>
                                                        </Col>
                                                        <Col md={6} lg={6} className='mt-3'>
                                                            <LabelWrapper label={t('Level 5')} isShow={true}>
                                                                <DropdownOptionCategory
                                                                    name=""
                                                                    dataList={categoryDropdown?.level_4?.subs || [{ label: t('-----'), value: '' }]}
                                                                    placeholder={`${t("Level 5")}...`}
                                                                    className="search-filter-category-type dropdown-status-rounded"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={categoryDropdown?.level_5 || null}
                                                                    disabled={!!detail?.id}
                                                                    onChangeSelect={(e: any) => {
                                                                        setCategoryDropdown((prev: any) => ({ ...prev, level_5: e }))
                                                                    }}
                                                                    isHasOptionAll={true}
                                                                    optionAll={{ label: t('-----'), value: '' }}
                                                                />
                                                            </LabelWrapper>
                                                        </Col>
                                                    </Row>
                                                    {formik.touched.category_id && formik.errors.category_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.category_id || ''}
                                                        </div>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Col md={8} lg={8}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Participation Template")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="form_id"
                                                        dataList={TEMPLATE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Participation Template")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.form_id || (!detail?.is_preview ? TEMPLATE_OPTIONS_LANG[0] : null)}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue('form_id', event)
                                                        }
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {/* {formik.touched.form_id && formik.errors.form_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.form_id || ''}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </Col>
                                            <Col md={4} lg={4} className="ps-2 pe-0 ps-md-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="is_active"
                                                        dataList={STATUS_PARTICIPATION_CATEGORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.is_active ||
                                                            STATUS_PARTICIPATION_CATEGORY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("is_active", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.is_active && formik.errors.is_active ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.is_active}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Participation Category") : t("Button Create Participation Category")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormCategory;
