import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ITrendIndex, ITrendIndexConfig } from "api/types/_trendindex";
import { getParticipationCategories, getParticipationTemplates, getParticipationTickets } from "./thunk";
import { IParticipationCategory, IParticipationTemplate, IParticipationTicket } from "api/types/_participation";

export interface IState {
  participationTemplates: ResponseData<IParticipationTemplate[]> & PaginationResponse | null,
  isParticipationTemplateLoading: boolean,
  isParticipationTemplateSuccess: boolean,

  participationCategories: ResponseData<IParticipationCategory[]> & PaginationResponse | null,
  isParticipationCategoryLoading: boolean,
  isParticipationCategorySuccess: boolean,

  participationTickets: ResponseData<IParticipationTicket[]> & PaginationResponse | null,
  isParticipationTicketLoading: boolean,
  isParticipationTicketSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  participationTemplates: null,
  isParticipationTemplateLoading: false,
  isParticipationTemplateSuccess: false,

  participationCategories: null,
  isParticipationCategoryLoading: false,
  isParticipationCategorySuccess: false,

  participationTickets: null,
  isParticipationTicketLoading: false,
  isParticipationTicketSuccess: false,

  error: {},
};

const ParticipationSlice = createSlice({
  name: "Participation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //participation templates
    builder.addCase(getParticipationTemplates.pending, (state: IState, action: any) => {
      state.isParticipationTemplateLoading = true
    });
    builder.addCase(getParticipationTemplates.fulfilled, (state: IState, action: any) => {
      state.participationTemplates = action.payload.data;
      state.isParticipationTemplateSuccess = true;
      state.isParticipationTemplateLoading = false;
    });
    builder.addCase(getParticipationTemplates.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isParticipationTemplateSuccess = false;
      state.isParticipationTemplateLoading = false;
    });

    //participation categories
    builder.addCase(getParticipationCategories.pending, (state: IState, action: any) => {
      state.isParticipationCategoryLoading = true
    });
    builder.addCase(getParticipationCategories.fulfilled, (state: IState, action: any) => {
      state.participationCategories = action.payload.data;
      state.isParticipationCategorySuccess = true;
      state.isParticipationCategoryLoading = false;
    });
    builder.addCase(getParticipationCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isParticipationCategorySuccess = false;
      state.isParticipationCategoryLoading = false;
    });


    //participation tickets
    builder.addCase(getParticipationTickets.pending, (state: IState, action: any) => {
      state.isParticipationTicketLoading = true
    });
    builder.addCase(getParticipationTickets.fulfilled, (state: IState, action: any) => {
      state.participationTickets = action.payload.data;
      state.isParticipationTicketSuccess = true;
      state.isParticipationTicketLoading = false;
    });
    builder.addCase(getParticipationTickets.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isParticipationTicketSuccess = false;
      state.isParticipationTicketLoading = false;
    });
  },
});

export default ParticipationSlice.reducer;