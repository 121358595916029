import { IMedia } from "api/types/_media";
import { IParticipationCategory, IParticipationTemplate } from "api/types/_participation";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { CATEGORY_TYPE, MEMBER_TYPE, STATUS_PARTICIPATION_TEMPLATE_OPTIONS, TYPE_PARTICIPATION_TEMPLATE_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteParticipationCategory, getAllCategoriesNormal, getAllParticipationTemplates, getParticipationCategories as onGetParticipationCategories } from "../../../store/thunks";
import FormCategory from "../FormCategory";
import DropdownCategory from "components/Common/DropdownCategory";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ParticipationCategoryManagement = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG = STATUS_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG = TYPE_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        category_id: withDefault(StringParam, ''),
        is_active: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Participation;

    const ParticipationProperties = createSelector(
        selectLayoutState,
        (state) => ({
            participationCategories: state.participationCategories,
            isParticipationCategorySuccess: state.isParticipationCategorySuccess,
            isParticipationCategoryLoading: state.isParticipationCategoryLoading,
            error: state.error,
        })
    );

    const { participationCategories, isParticipationCategoryLoading } = useSelector(ParticipationProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [categorySearch, setCategorySearch] = useState<any | null>(null);

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

    const [categoryDetail, setCategoryDetail] = useState<IParticipationCategory | null>(null);

    const [itemDelete, setItemDelete] = useState<IParticipationCategory | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<any>([]);

    const [listParticipationTemplate, setListParticipationTemplate] = useState<IParticipationTemplate[]>([]);

    useEffect(() => {
        dispatch(onGetParticipationCategories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            is_active: statusSearch?.value || '',
            category_id: categorySearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            is_active: '',
            category_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setStatusSearch(null);
        setCategorySearch(null);
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setCategoryDetail((_prev) => null); }
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                thWidth: 60,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Participation Category Name'),
                accessor: "category_name",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const cate_full = item?.categories?.map((cate: any) => cate?.name).reverse()?.join(' > ') || '';
                    return (
                        <>
                            <div className="d-flex align-items-start" style={{ minWidth: '130px' }}>
                                <CopyWrapper contentCopy={cell?.value} >
                                    {cate_full}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Participation Template Name'),
                accessor: "form_name",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const TEMPLATE = listParticipationTemplate?.find((tpl: any) => String(tpl?.id) === String(item?.form_id));
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '130px' }}>
                                <CopyWrapper contentCopy={cell?.value} >
                                    {TEMPLATE ? `[${TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((type: any) => String(type?.value) === String(TEMPLATE?.type))?.label || ''}] ${TEMPLATE?.name || ''}` : ''}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "is_active",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },

            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Participation Category')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCategoryDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Participation Category')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCategoryDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Participation Template')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(listParticipationTemplate), JSON.stringify(query), STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IParticipationCategory) => {
        setItemDelete((_prev) => item);
    };

    const handleActionDelete = async () => {
        if (!itemDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteParticipationCategory(itemDelete?.id, {
                form_id: itemDelete?.form_id,
                category_id: itemDelete?.category_id,
            });
            if (response?.code === 200) {
                dispatch(onGetParticipationCategories(query));
                setIsConfirmLoading((_prev) => false);
                setItemDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setItemDelete((_prev) => null);
    }
    // End::Delete

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1, res2]: any = await Promise.all([getAllCategoriesNormal({ type: CATEGORY_TYPE.TREND_INDEX }), getAllParticipationTemplates({})]);
                setOptionCategoryDropdown((_prev: any) => res1?.data || []);
                setListParticipationTemplate((_prev: any) => res2?.data || []);
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t("Management Participation Categories")} - ${t("Participation Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Management Participation Categories')} pageTitle={t('Participation Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_MANAGEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={participationCategories?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setCategoryDetail((_prev) => ({} as IParticipationCategory))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Participation Category')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={4} xxl={4} className='mt-3 mt-md-2'>
                                                {/* <LabelWrapper label={t('Participation Category Name')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Participation Category Name')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper> */}
                                                <DropdownCategory
                                                    name="category"
                                                    placeholder={t('Category')}
                                                    isClearable={true}
                                                    dataList={optionCategoryDropdown || []}
                                                    initialValue={categorySearch ?? undefined}
                                                    onChangeCategory={(event) => {
                                                        setCategorySearch(event)
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={3} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isParticipationCategoryLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={participationCategories?.list?.length ? participationCategories?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={participationCategories?.pagination?.total}
                                            customPageCount={Math.ceil(Number(participationCategories?.pagination?.total) / Number(participationCategories?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isParticipationCategoryLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(categoryDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-600'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setCategoryDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!categoryDetail?.id ? (!!categoryDetail?.is_preview ? t('Button Detail Participation Category') : t('Button Update Participation Category')) : t('Button Create Participation Category')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormCategory detail={categoryDetail} templates={listParticipationTemplate} categories={optionCategoryDropdown} triggerRefresh={handleRefresh} handleClose={() => setCategoryDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={categoryDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setCategoryDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setCategoryDetail((_prev) => null)}>
                                    {!!categoryDetail?.id ? (!!categoryDetail?.is_preview ? t('Button Detail Participation Category') : t('Button Update Participation Category')) : t('Button Create Participation Category')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormCategory detail={categoryDetail} templates={listParticipationTemplate} categories={optionCategoryDropdown} triggerRefresh={handleRefresh} handleClose={() => setCategoryDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Participation Template')}
                isOpen={itemDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ParticipationCategoryManagement;