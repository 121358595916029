import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "api/types/_category";
import { ResponseData } from "api/types/_public";
import { getAllCategories } from "./thunk";

export interface IState {
  categories: ResponseData<ICategory[]> | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,
  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,
  
  error: {},
};

const MasterSlice = createSlice({
  name: "Master",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
  //all categories
  builder.addCase(getAllCategories.pending, (state: IState, action: any) => {
    state.isCategoryLoading = true
  });
  builder.addCase(getAllCategories.fulfilled, (state: IState, action: any) => {
    state.categories = action.payload.data;
    state.isCategorySuccess = true;
    state.isCategoryLoading = false;
  });
  builder.addCase(getAllCategories.rejected, (state: IState, action: any) => {
    state.error = action.payload.error || null;
    state.isCategorySuccess = false;
    state.isCategoryLoading = false;
  });
  },
});

export default MasterSlice.reducer;