import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll
} from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { IFrequency, IIndex } from 'api/types/_master';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DropdownMultiOption from 'components/Common/DropdownMultiOption';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import { useRole } from 'components/Hooks/UserHooks';
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import { deleteCategory, getFrequencies, getIndexes, getAllCategories as onGetAllCategories, postCategory, putCategories, putCategory, putClassificationCategory } from "../../../store/thunks";
import ItemRender from './ItemRender';
import { CATEGORY_GROUP_TYPE, CATEGORY_TYPE } from 'helpers/constans';

interface Props {
  typeCategory: CATEGORY_TYPE
  handleClose?: () => void
}

function getIndexLabels(indexes: any = [], value: any = [], language: string = 'ko') {
  const items = value?.map((item: any) => {
    const indx = indexes?.find((v: any) => String(item?.index_type) === String(v?.id));
    return ({
      name: (language === 'en' ? indx?.name : indx?.name),
      ratio: item?.ratio,
    })
  });
  return items?.map((item: any) => `${item?.name} (${item?.ratio})`).join(', ');
}

function getFrequencyLabels(frequencies: any = [], value: any = [], language: string = 'ko') {
  const items = frequencies?.filter((item: any) => (value?.includes(item?.id)));
  return items?.map((item: any) => (language === 'en' ? item?.name : (item?.name_korea || item?.name_kr))).join(', ');
}

function formatToTreeData(data: any, indexes: any = [], frequencies: any = [], language: string = 'ko') {
  return data?.map((item: any) => {
    const newItem = {
      id: `${item?.id || ''}`,
      title: item?.name || "",
      subtitle: `${item?.id || ''}`,
      isDirectory: true,
      expanded: false,
      isEdit: false,
      classification: item?.classification,
      group_type: item?.group_type,
      indexes: getIndexLabels(indexes, item?.index_configs, language),
      frequencies: getFrequencyLabels(frequencies, item?.frequencies, language),
      children: structuredClone(item?.subs) || []
    };
    if (newItem.children && newItem.children.length > 0) {
      newItem.children = formatToTreeData(newItem.children, indexes, frequencies, language);
    }
    return newItem;
  });
}

function formatTreeToSubmit(data: any) {
  return data.map((item: any) => {
    const newItem = {
      id: String(item?.id).includes('new_') ? null : Number(item?.id),
      name: item?.title || '',
      subs: structuredClone(item?.children || [])
    };
    if (newItem.subs && newItem.subs.length > 0) {
      newItem.subs = formatTreeToSubmit(newItem.subs);
    }
    return newItem;
  });
}

function formatTreeToSample(data: any) {
  return data.map((item: any) => {
    const newItem = {
      id: String(item?.id).includes('new_') ? null : Number(item?.id),
      name: item?.title,
      subs: structuredClone(item?.children || [])
    };
    if (newItem.subs && newItem.subs.length > 0) {
      newItem.subs = formatTreeToSubmit(newItem.subs);
    }
    return newItem;
  });
}

const countNodes = (nodes: any) => {
  let count = 0;

  const countRecursively = (nodes: any) => {
    nodes.forEach((node: any) => {
      count++;
      if (node.children && node.children.length > 0) {
        countRecursively(node.children);
      }
    });
  };

  countRecursively(nodes);
  return count;
};

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';

const CategoryTree = ({
  typeCategory,
  handleClose,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useState({
    time_request: 0,
    sort_by: SORT_BY_DEFAULT,
    order_by: ORDER_BY_DEFAULT,
    type: typeCategory,
    name: '',
    index_types: '',
    frequency_types: '',
  });

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Master;

  const CategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categories: state.categories,
      isCategorySuccess: state.isCategorySuccess,
      isCategoryLoading: state.isCategoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { categories, error, isCategoryLoading } = useSelector(CategoryProperties);

  const [itemDelete, setItemDelete] = useState<any>(null);

  const [listIndex, setListIndex] = useState<IIndex[]>([]);
  const [listFrequency, setListFrequency] = useState<IFrequency[]>([]);

  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

  const [indexSearch, setIndexSearch] = useState<Option[] | null>(null);

  const [frequencySearch, setFrequencySearch] = useState<Option[] | null>(null);

  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState<any>(0);
  const [searchFoundCount, setSearchFoundCount] = useState<any>(null);

  const [treeData, setTreeData] = useState<any>([]);

  const inputEl: any = useRef();

  const handleShowConfirmDelete = (item: any) => {
    setItemDelete((prev: any) => item);
  };

  const onCloseConfirmClick = () => {
    setItemDelete((_prev: any) => null);
  }

  useEffect(() => {
    if (!isCategoryLoading) {
      dispatch(onGetAllCategories(query));
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (categories) {
      setTreeData((_prev: any) => formatToTreeData(categories, listIndex, listFrequency, i18n?.language || '') || []);
    }
  }, [categories, listIndex, listFrequency]);


  const searchData = () => {
    setQuery((prev: any) => ({
      ...prev,
      type: typeCategory,
      name: nameSearch || undefined,
      index_types: (indexSearch || [])?.map((item: any) => item?.value).join(',') || '',
      frequency_types: (frequencySearch || [])?.map((item: any) => item?.value).join(',') || '',
      time_request: + new Date()
    }));
  };

  const resetData = () => {
    setQuery((prev: any) => ({
      ...prev,
      type: typeCategory,
      name: '',
      index_types: '',
      frequency_types: '',
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date()
    }));
    setNameSearch("");
    setIndexSearch(null);
    setFrequencySearch(null);
  };

  async function createNode() {
    const value = inputEl.current.value;
    if (value === "") {
      inputEl.current.focus();
      return;
    }
    const response: any = await postCategory({
      type: typeCategory,
      parent_id: null,
      name: value,
    }, query);
    if (response?.code === 200) {
      toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      let newNode: any = response?.data;
      let newTree: any = addNodeUnderParent({
        treeData,
        parentKey: undefined,
        expandParent: true,
        getNodeKey,
        newNode: {
          id: `${newNode?.id}`,
          title: value,
          subtitle: `${newNode?.id}`,
          classification: newNode?.classification,
          group_type: newNode?.group_type,
          isEdit: false,
          isDirectory: true,
          expanded: true,
        }
      });
      setTreeData((prev: any) => newTree?.treeData);
      inputEl.current.value = "";
    } else {
      toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
    }
  }

  function updateNodeToIsEdit(rowInfo: any) {
    const { node, path } = rowInfo;
    if (node?.isEdit) {
      return;
    }
    let newTree: any = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        old_title: node?.title,
        isEdit: true,
        dragDisabled: true,
      }
    });
    setTreeData(newTree);
  }

  function updateNodeToNotEdit(rowInfo: any) {
    const { node, path } = rowInfo;
    if ((String(node?.id)?.includes('new_'))) {
      return;
    }
    let newTree: any = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        title: node?.old_title,
        isEdit: false,
        dragDisabled: false,
      }
    });
    setTreeData(newTree);
  }

  async function updateNode(rowInfo: any, title: string, isEdit: boolean) {
    const { node, parentNode, path } = rowInfo;
    let newTree: any = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        title: title,
        isEdit: isEdit,
        dragDisabled: isEdit,
      }
    });
    if (isEdit) {
      setTreeData((prev: any) => newTree);
    } else if (!!title) {
      try {
        if ((String(node?.id)?.includes('new_'))) {
          const response: any = await postCategory({
            type: typeCategory,
            parent_id: parentNode?.id ? Number(parentNode?.id) : null,
            name: title,
          }, query);
          if (response?.code === 200) {
            toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            let newNode: any = response?.data;
            let newTree2: any = changeNodeAtPath({
              treeData: newTree,
              path,
              getNodeKey,
              newNode: {
                ...node,
                id: `${newNode?.id}`,
                subtitle: `${newNode?.id}`,
                classification: newNode?.classification,
                group_type: newNode?.group_type,
                isEdit: false,
                dragDisabled: false,
              }
            });
            setTreeData((prev: any) => newTree2);
          } else {
            toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
          }
        } else {
          const response: any = await putCategory(node?.id, { name: title });
          if (response?.code === 200) {
            toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            setTreeData((prev: any) => newTree);
          } else {
            toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
          }
        }
      } catch (error: any) {
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }

  }

  const updateClassification = (node: any, newClassification: string | null) => {
    node.classification = newClassification;

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => updateClassification(child, newClassification));
    }

    return node;
  };

  async function updateClassificationNode(rowInfo: any, classification: string | null, isEdit: boolean) {
    const { node, parentNode, path } = rowInfo;
    let newTree: any = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...(updateClassification(node, classification)),
        classification: classification,
        isEdit: isEdit,
        dragDisabled: isEdit,
      }
    });
    if (!isEdit) {
      try {
        const response: any = await putClassificationCategory(node?.id, { classification: classification });
        if (response?.code === 200) {
          toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
          setTreeData((prev: any) => newTree);
        } else {
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }

  }

  function addNodeChild(rowInfo: any) {
    let { path, node } = rowInfo;
    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        id: `new_${+ new Date()}`,
        title: '',
        subtitle: `new_${+ new Date()}`,
        classification: null, // node?.classification
        group_type: 0, // node?.group_type
        isEdit: true,
        isDirectory: true,
        expanded: true,
      }
    });
    setTreeData((prev: any) => newTree.treeData);
  }

  async function removeNode(rowInfo: any) {
    const { path, node } = rowInfo;
    try {
      if ((String(node?.id)?.includes('new_'))) {
        setTreeData((prev: any) =>
          removeNodeAtPath({
            treeData,
            path,
            getNodeKey
          })
        );
      } else {
        handleShowConfirmDelete(rowInfo);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  async function handleDeleteCategory() {
    try {
      const { node, path } = itemDelete;
      const response: any = await deleteCategory(node?.id);
      if (response?.code === 200) {
        setTreeData((prev: any) =>
          removeNodeAtPath({
            treeData,
            path,
            getNodeKey
          }))
        onCloseConfirmClick();
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  async function updateTreeData(treeDataNew: any) {
    const oldStateTree: any = structuredClone(treeData);
    const treeNew = formatTreeToSample(treeDataNew);
    const treeOld = formatTreeToSample(treeData);
    if (JSON.stringify(treeNew) === JSON.stringify(treeOld)) {
      setTreeData((prev: any) => treeDataNew);
      return;
    }
    try {
      setTreeData((prev: any) => treeDataNew);
      const dataForm = formatTreeToSubmit(treeDataNew);
      const response: any = await putCategories({
        type: typeCategory,
        data: dataForm,
        delete_ids: []
      }, query);
      if (response?.code === 200) {
        // toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        setTreeData((prev: any) => oldStateTree);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      setTreeData((prev: any) => oldStateTree);
      return error;
    }
  }


  function expand(expanded: any) {
    setTreeData(
      toggleExpandedForAll({
        treeData,
        expanded
      })
    );
  }

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res1, res2]: any = await Promise.all([getIndexes(), getFrequencies()]);
        setListIndex((_prev) => res1?.data || []);
        setListFrequency((_prev) => res2?.data || []);
        if (query?.index_types) {
          setIndexSearch((_prev) => {
            const items = res1?.data?.filter((item: any) => (String(query?.index_types || '').split(',')).includes(String(item?.value || '')));
            return items?.map((item: any) => (
              {
                label: i18n?.language === 'en' ? item?.name : item?.name_kr,
                value: `${item?.value || ''}`,
              }
            ));
          });
        }
        if (query?.frequency_types) {
          setFrequencySearch((_prev) => {
            const items = res1?.data?.filter((item: any) => (String(query?.frequency_types || '').split(',')).includes(String(item?.value || '')));
            return items?.map((item: any) => (
              {
                label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr),
                value: `${item?.value || ''}`,
              }
            ));
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (indexSearch) {
      const valStr = indexSearch?.map((item: any) => item?.value);
      setIndexSearch((_prev) => {
        const items = listIndex?.filter((item: any) => (valStr).includes(String(item?.value || '')));
        return items?.map((item: any) => (
          {
            label: i18n?.language === 'en' ? item?.name : item?.name_kr,
            value: `${item?.value || ''}`,
          }
        ));
      });
    }

    if (frequencySearch) {
      const valStr = frequencySearch?.map((item: any) => item?.value);
      setFrequencySearch((_prev) => {
        const items = listFrequency?.filter((item: any) => (valStr).includes(String(item?.value || '')));
        return items?.map((item: any) => (
          {
            label: i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr),
            value: `${item?.value || ''}`,
          }
        ));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (!isCategoryLoading) {
      setTimeout(() => {
        setSearchString((prev: any) => nameSearch);
      }, 100);
    }
  }, [isCategoryLoading]);

  return (
    <React.Fragment>
      <div className="mt-3">
        <Container fluid>
          <Row>
            <Col sm={12} className="px-0">
              <Card id="customerList" className="mb-0" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', minHeight: 'calc(100dvh - 90px)' }}>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t('Total')}:</span>
                          <span className="text-primary">{countNodes(treeData || [])} </span>
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 mt-0 pb-4 px-3 ">
                  <Row className="g-4 align-items-center mt-2">
                    <Col sm={6} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Category Name')} isShow={!!nameSearch}>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('Category Name')}...`}
                          value={nameSearch}
                          onChange={(e) => setNameSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={6} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Index')} isShow={!!indexSearch?.length}>
                        <DropdownMultiOption
                          name="index"
                          dataList={listIndex?.map((item) => ({ value: String(item?.value), label: `${i18n?.language === 'en' ? item?.name : item?.name_kr}` }))}
                          placeholder={`${t("Index")}...`}
                          className="search-filter-category-type dropdown-status-rounded "
                          classNamePrefix="name-prefix"
                          initialValue={indexSearch || null}
                          onChangeSelect={(e: any) => setIndexSearch(e)}
                          isHasOptionAll={false}
                          optionAll={{ label: t('All Index'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={6} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Frequency')} isShow={!!frequencySearch?.length}>
                        <DropdownMultiOption
                          name="frequency"
                          dataList={listFrequency?.map((item) => ({ value: String(item?.value), label: `${i18n?.language === 'en' ? item?.name : (item?.name_korea || item?.name_kr)}` }))}
                          placeholder={`${t("Frequency")}...`}
                          className="search-filter-category-type dropdown-status-rounded "
                          classNamePrefix="name-prefix"
                          initialValue={frequencySearch || null}
                          onChangeSelect={(e: any) => setFrequencySearch(e)}
                          isHasOptionAll={false}
                          optionAll={{ label: t('All Frequency'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={12} xl={3} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-2 "
                          onClick={searchData}
                          disabled={isCategoryLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className='table-custom-pagination'>
                  <div className={`infinite-loading-outer ${!!isCategoryLoading && 'is-show-loading'}`}>
                    <div className="infinite-loading-inner"></div>
                  </div>
                </div>
                <CardBody className="pt-3">
                  <Col md={12}>
                    <div className='d-flex justify-content-start mb-4'>
                      <input
                        name="category_name"
                        type="text"
                        className="form-control"
                        id="category_name"
                        placeholder={t('Category Name')}
                        style={{ maxWidth: '300px' }}
                        ref={inputEl}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            createNode && createNode();
                          }
                        }}
                      />
                      <button className="btn btn-success fs-14 ms-3"
                        style={{ width: '190px' }} type="button" color="light"
                        onClick={() => createNode()}
                      >
                        {t('Button Create Category In Level 1')}
                      </button>
                    </div>
                  </Col>
                  <Col md={12} className="mb-4 tree-category" style={{ minHeight: "1000px", height: '100dvh', }}>
                    <SortableTree
                      maxDepth={5}
                      treeData={treeData}
                      onChange={(treeData) => updateTreeData(treeData)}
                      searchQuery={searchString}
                      onlyExpandSearchedNodes={true}
                      searchFocusOffset={searchFocusIndex}
                      searchFinishCallback={(matches) => {
                        setSearchFoundCount(matches.length);
                        setSearchFocusIndex(
                          matches.length > 0 ? searchFocusIndex % matches.length : 0
                        );
                      }}
                      canDrag={({ node }: any) => !node.dragDisabled}
                      generateNodeProps={(rowInfo) => ({
                        title: <ItemRender {...rowInfo} updateNodeToIsEdit={updateNodeToIsEdit} updateNode={updateNode} />,
                        subtitle: <span></span>,
                        buttons: [
                          <div className="d-flex align-items-center h-100 fs-12 text-muted mx-2" >
                            {(!(String(rowInfo?.node?.id)?.includes('new_')) && !(rowInfo?.node?.isEdit)) && (String(rowInfo?.node?.group_type) === String(CATEGORY_GROUP_TYPE.PEOPLE)) && (
                              <>
                                <div
                                  className="form-check form-switch text-center mb-0"
                                  style={{ minWidth: "50px" }}
                                >
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name={`${rowInfo?.node?.id}-${rowInfo?.node?.title}-${rowInfo?.node?.classification}`}
                                    id={`${rowInfo?.node?.id}-${rowInfo?.node?.title}-${rowInfo?.node?.classification}`}
                                    checked={!!rowInfo?.node?.classification}
                                    onChange={(event: any) => {
                                      event.stopPropagation();
                                      updateClassificationNode(rowInfo, event?.target.checked ? rowInfo?.node?.title : null, false)
                                    }
                                    }
                                  />
                                  <label className="form-check-label fs-11 text-muted" htmlFor={`${rowInfo?.node?.id}-${rowInfo?.node?.title}-${rowInfo?.node?.classification}`}>
                                    {rowInfo?.node?.classification || ''}
                                  </label>
                                </div>
                              </>)}
                          </div>,
                          <div className='box-edit-node-tree'>
                            {(rowInfo?.path?.length < 5 && !(String(rowInfo?.node?.id).includes('new_') || rowInfo?.node?.isEdit)) && (
                              <button className="btn btn-sm btn-soft-success d-inline-block edit-item-btn ms-2 p-0"
                                style={{ height: '30px', width: '30px' }}
                                onClick={() => addNodeChild(rowInfo)}><i className="ri-add-fill fs-16"></i>
                              </button>
                            )}
                            {(String(rowInfo?.node?.id).includes('new_') || rowInfo?.node?.isEdit) && (
                              <button className="btn btn-sm btn-soft-success d-inline-block edit-item-btn ms-2 p-0"
                                style={{ height: '30px', width: '30px' }}
                                onClick={() => updateNode(rowInfo, rowInfo?.node?.title, false)}><i className="ri-check-line fs-16"></i>
                              </button>
                            )}
                            {(!(String(rowInfo?.node?.id)?.includes('new_')) && !!(rowInfo?.node?.isEdit)) && <button
                              className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn ms-2 p-0"
                              style={{ height: '30px', width: '30px' }}
                              onClick={(event) => updateNodeToNotEdit(rowInfo)}>
                              <i className="ri-close-fill fs-16"></i>
                            </button>}
                            <button
                              className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn ms-2 p-0"
                              style={{ height: '30px', width: '30px' }}
                              onClick={(event) => removeNode(rowInfo)}>
                              <i className="ri-delete-bin-7-line fs-16"></i>
                            </button>
                          </div>,
                          <div className="d-flex align-items-center h-100 fs-12 text-muted mx-2" >
                            {(rowInfo?.node?.indexes || rowInfo?.node?.frequencies) && (
                              <><span>{rowInfo?.node?.indexes || ''}</span>
                                <span className="mx-1">{rowInfo?.node?.indexes && rowInfo?.node?.frequencies ? '-' : ''}</span>
                                <span>{rowInfo?.node?.frequencies || ''}</span>
                              </>)}
                          </div>
                        ],
                        style: {
                          height: "40px"
                        }
                      })}
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer closeButton={false} limit={1} />
        </Container>
        <ModalConfirm
          header={t('Button Delete Category')}
          isOpen={itemDelete !== null}
          isShowIcon={false}
          // isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleDeleteCategory}
        />
      </div>
    </React.Fragment >
  );
};

export default CategoryTree;


