import { createAsyncThunk } from "@reduxjs/toolkit";
import keywordApi from 'api/keywordApi';
import { ENUM_STATUS_KEYWORD_RANKING } from "helpers/constans";
import { formatQueryParams } from "helpers/format";

export const getKeywords = createAsyncThunk("Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordsMonitor = createAsyncThunk("Keywords Monitor", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywordsMonitor(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getStockKeywords = createAsyncThunk("Stock Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.stockKeywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getKeywordsByCategory = createAsyncThunk("Keywords By Category", async (params: any = {}) => {
  try {
    const categoryId = params?.category_id;
    const paramsClone = structuredClone(params);
    delete paramsClone.category_id;
    const response = await keywordApi.keywordsByCategory(categoryId, formatQueryParams(paramsClone))
    return response;
  } catch (error) {
    return error;
  }
});

export const getCollecting = createAsyncThunk("Keywords Collecting", async (params: any = {}) => {
  try {
    const response = await keywordApi.collecting(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getNewsSearchingKeyword = createAsyncThunk("News Searching Keyword", async (params: any = {}) => {
  try {
    const response = await keywordApi.newsSearchingKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordSummariesStatus = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordSummariesStatus(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getKeyword = async (id: number) => {
  try {
    const response = await keywordApi.getKeyword(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeywordOfCategories = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeywordOfCategories(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const downloadExcelKeywords = async (params: any = {}) => {
  try {
    const response = await keywordApi.downloadExcelKeywords(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const changeStatusKeyword = async (data: any = {}) => {
  try {
    // cancel delete
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)) {
      const response = await keywordApi.deleteKeywordDeletion({ keyword_id: data?.id, category_id: data?.category_id });
      return response;
    }
    // add keyword to delete
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED)) {
      const response = await keywordApi.postKeywordDeletion({ keyword_id: data?.id, category_id: data?.category_id });
      return response;
    }
    // confirm delete
    const response = await keywordApi.putKeywordDeletion({ keyword_id: data?.id, category_id: data?.category_id });
    return response;

  } catch (error) {
    return error;
  }
};

