import { IParticipationTicket } from "api/types/_participation";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_PARTICIPATION_TICKET_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postParticipationTicket, putParticipationTicket } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IParticipationTicket | null;
    numberNext?: number,
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormTicket = ({
    isModal = false,
    id,
    detail,
    numberNext = 0,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const STATUS_PARTICIPATION_TICKET_OPTIONS_LANG = STATUS_PARTICIPATION_TICKET_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                amount: Number(values?.amount || 0),
                price_kr: Number(values?.price_kr || 0),
                sort_no: Number(values?.sort_no || 0),
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_PARTICIPATION_TICKET_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putParticipationTicket(detail?.id, data) : await postParticipationTicket(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        amount: Yup.string().required(`${t("This is required")}`),
        price_kr: Yup.string().required(`${t("This is required")}`),
        sort_no: Yup.string().required(`${t("This is required")}`),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            amount: 0,
            price_kr: 0,
            sort_no: numberNext || 0,
            is_active: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_amount = valueDefault?.amount;
        const v_price_kr = valueDefault?.price_kr;
        const v_sort_no = valueDefault?.sort_no;
        const v_is_active = STATUS_PARTICIPATION_TICKET_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        formik.setFieldValue("amount", v_amount);
        formik.setFieldValue("price_kr", v_price_kr);
        formik.setFieldValue("sort_no", v_sort_no);
        formik.setFieldValue("is_active", v_is_active);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="is_active"
                                                        dataList={STATUS_PARTICIPATION_TICKET_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.is_active ||
                                                            STATUS_PARTICIPATION_TICKET_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("is_active", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.is_active && formik.errors.is_active ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.is_active}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Sequence")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="sort_no"
                                                        name="sort_no"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.sort_no)}
                                                        placeholder={`${t("Sequence")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("sort_no", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.sort_no && formik.errors.sort_no ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.sort_no}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Vote Ticket")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="amount"
                                                        name="amount"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.amount)}
                                                        placeholder={`${t("Vote Ticket")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("amount", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.amount && formik.errors.amount ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.amount}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} className="pe-0 pe-md-0">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Price")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="price_kr"
                                                        name="price_kr"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.price_kr)}
                                                        placeholder={`${t("Price")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, '');
                                                            const s = onlyNumbers;
                                                            formik.setFieldValue("price_kr", s)
                                                        }}
                                                        disabled={!!detail?.is_preview}
                                                    />
                                                    {formik.touched.price_kr && formik.errors.price_kr ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.price_kr}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Participation Ticket") : t("Button Create Participation Ticket")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormTicket;
