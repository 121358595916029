import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IIndexLog } from "api/types/_statistic";
import { getIndexLogs, getUserVotes, getUserVotesChart } from "./thunk";
import { IUserVote, IUserVoteLineChart } from "api/types/_userVote";

export interface IState {
  indexLogs: (ResponseData<IIndexLog[]> & PaginationResponse) | null;
  isIndexLogLoading: boolean;
  isIndexLogSuccess: boolean;

  userVotes: (ResponseData<IUserVote[]> & PaginationResponse) | null;
  isUserVoteLoading: boolean;
  isUserVoteSuccess: boolean;

  userVotesLineChart:
  | (ResponseData<IUserVoteLineChart[]> & PaginationResponse)
  | null;
  isUserVoteLineChartLoading: boolean;
  isUserVoteLineChartSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  indexLogs: null,
  isIndexLogLoading: false,
  isIndexLogSuccess: false,

  userVotes: null,
  isUserVoteLoading: false,
  isUserVoteSuccess: false,

  userVotesLineChart: null,
  isUserVoteLineChartLoading: false,
  isUserVoteLineChartSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //index logs
    builder.addCase(getIndexLogs.pending, (state: IState, action: any) => {
      state.isIndexLogLoading = true;
    });
    builder.addCase(getIndexLogs.fulfilled, (state: IState, action: any) => {
      state.indexLogs = action.payload.data;
      state.isIndexLogSuccess = true;
      state.isIndexLogLoading = false;
    });
    builder.addCase(getIndexLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isIndexLogSuccess = false;
      state.isIndexLogLoading = false;
    });

    //user vote
    builder.addCase(getUserVotes.pending, (state: IState, action: any) => {
      state.isUserVoteLoading = true;
    });
    builder.addCase(getUserVotes.fulfilled, (state: IState, action: any) => {
      state.userVotes = action.payload.data;
      state.isUserVoteSuccess = true;
      state.isUserVoteLoading = false;
    });
    builder.addCase(getUserVotes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUserVoteSuccess = false;
      state.isUserVoteLoading = false;
    });

    //user vote chart
    builder.addCase(
      getUserVotesChart.pending,
      (state: IState, action: any) => {
        state.isUserVoteLineChartLoading = true;
      }
    );
    builder.addCase(
      getUserVotesChart.fulfilled,
      (state: IState, action: any) => {
        state.userVotesLineChart = action.payload.data;
        state.isUserVoteLineChartSuccess = true;
        state.isUserVoteLineChartLoading = false;
      }
    );
    builder.addCase(
      getUserVotesChart.rejected,
      (state: IState, action: any) => {
        state.error = action.payload.error || null;
        state.isUserVoteLineChartSuccess = false;
        state.isUserVoteLineChartLoading = false;
      }
    );
  },
});

export default StatisticSlice.reducer;
