import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategory, ICondition, IConfigHistory } from "./types/_category";
import { serialize } from "helpers/format";
const api = new APIClient();

const path = '/categories';
const categoryApi = {
  categories(params: any): Promise<AxiosResponse<ResponseData<ICategory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getCategory(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postCategory(dataForm: any, params: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, { data: dataForm })
  },
  putGroupTypeCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}/group-type`;
    return api.put(url, dataForm)
  },
  putClassificationCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}/classification`;
    return api.put(url, dataForm)
  },
  putCategories(dataForm: any, params: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}?${serialize(params)}`;
    return api.put(url, dataForm)
  },
  putIndexConfigsCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}/index-configs`;
    return api.put(url, dataForm)
  },
  putFrequencyTypeCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}/frequency-type`;
    return api.put(url, dataForm)
  },

  conditions(params: any): Promise<AxiosResponse<ResponseData<ICondition[]> & PaginationResponse, any>> {
    const url = `${path}/conditions`
    return api.get(url, params)
  },
  getCondition(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ICondition>, any>> {
    const url = `${path}/conditions/${id}`;
    return api.get(url, params)
  },
  postCondition(dataForm: any, params: any = {}): Promise<AxiosResponse<ResponseData<ICondition>, any>> {
    const url = `${path}/conditions?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putCondition(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICondition>, any>> {
    const url = `${path}/conditions/${id}`;
    return api.put(url, dataForm)
  },
  deleteCondition(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICondition>, any>> {
    const url = `${path}/conditions/${id}`;
    return api.delete(url, { data: dataForm })
  },
  getConfigHistories(params: any = {}): Promise<AxiosResponse<ResponseData<IConfigHistory>, any>> {
    const url = `${path}/config-history`;
    return api.get(url, params)
  },
}
export default categoryApi
