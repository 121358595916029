import { AxiosResponse } from "axios";
import { serialize } from "helpers/format";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ITrendIndex, ITrendIndexConfig } from "./types/_trendindex";
const api = new APIClient();

const path = '/indices/tasks';
const path_config = '/indices/configs'
const trendIndexApi = {
  trendIndexes(params: any):Promise<AxiosResponse<ResponseData<ITrendIndex[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getTrendIndex(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<ITrendIndex>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postTrendIndex(dataForm: any, params:any) : Promise<AxiosResponse<ResponseData<ITrendIndex>, any>>  {
    const url = `${path}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putTrendIndex(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITrendIndex>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteTrendIndex(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITrendIndex>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, {data: dataForm})
  },


  trendIndexConfigs(params: any):Promise<AxiosResponse<ResponseData<ITrendIndexConfig[]> & PaginationResponse, any>> {
    const url = `${path_config}`
    return api.get(url, params)
  },
  getTrendIndexConfig(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<ITrendIndexConfig>, any>>  {
    const url = `${path_config}/${id}`;
    return api.get(url, params)
  },
  postTrendIndexConfig(dataForm: any, params:any) : Promise<AxiosResponse<ResponseData<ITrendIndexConfig>, any>>  {
    const url = `${path_config}?${serialize(params)}`;
    return api.post(url, dataForm)
  },
  putTrendIndexConfig(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITrendIndexConfig>, any>>  {
    const url = `${path_config}/${id}`;
    return api.put(url, dataForm)
  },
  deleteTrendIndexConfig(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITrendIndexConfig>, any>>  {
    const url = `${path_config}/${id}`;
    return api.delete(url, {data: dataForm})
  },
}
export default trendIndexApi
