import { ICategory } from 'api/types/_category';
import { IKeyword } from 'api/types/_keyword';
import { IFrequency } from 'api/types/_master';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CollapseTag from 'components/Common/CollapseTag';
import DropdownCategory from 'components/Common/DropdownCategory';
import DropdownOptionCategory from 'components/Common/DropdownOptionCategory';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { ENUM_STATUS_KEYWORD_RANKING, FREQUENCY_OPTIONS, STATUS_KEYWORD_RANKING_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import ModalDetailKeyword from 'pages/Keyword/ModalDetailKeyword';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import calculatePercentages from 'utils';
import NoImage from '../../../assets/images/no-photo-available.png';
import { changeStatusKeyword, deleteKeyword, downloadExcelKeywords, getAllCategoriesNormal, getFrequencies, getKeyword, getKeywordsMonitor as onGetKeywordsMonitor, postKeyword, putKeyword } from "../../../store/thunks";
import ModalNewsSearchingKeyword from '../ModalNewsSearchingKeyword';


const typeQuery = {
  'key': StringParam,
}


const SORT_BY_DEFAULT = 'search_index';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale("en", en);
registerLocale("ko", ko);


const RankingMonitoring = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const TWO_DATE_AGO_DEFAULT = `${moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD")}`;

  const FREQUENCY_OPTIONS_LANG = FREQUENCY_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const STATUS_KEYWORD_RANKING_OPTIONS_LANG = STATUS_KEYWORD_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, ''),
    date: withDefault(StringParam, TWO_DATE_AGO_DEFAULT),
    keyword_deletion_status: withDefault(StringParam, String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywordsMonitor,
      isKeywordSuccess: state.isKeywordMonitorSuccess,
      isKeywordLoading: state.isKeywordMonitorLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [dateSearch, setDateSearch] = useState<string>(query?.date || TWO_DATE_AGO_DEFAULT);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_KEYWORD_RANKING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.keyword_deletion_status))[0]);

  const [frequencySearch, setFrequencySearch] = useState<Option | null>(
    FREQUENCY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.frequency_type)
    )[0]
  );

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<IKeyword | any | null>(null);

  const [keywordAnalysisNews, setKeywordAnalysisNews] = useState<{ keyword_id: number, date: string } | null>(null);

  const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<ICategory[]>([]);
  const [categoryDropdown, setCategoryDropdown] = useState<any>({});
  const [inputKeywords, setInputKeywords] = useState<string>('');

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(null);

  const [listFrequency, setListFrequency] = useState<IFrequency[]>([]);

  const [keywordDetail, setKeywordDetail] = useState<IKeyword | null | Object>(null);

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [keywordAction, setKeywordAction] = useState<{ id: number, status: number } | null>(null);

  useEffect(() => {
    dispatch(onGetKeywordsMonitor(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || '',
      category_id: categorySearch?.value || '',
      date: dateSearch,
      keyword_deletion_status: statusSearch?.value || '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      date: TWO_DATE_AGO_DEFAULT,
      keyword: '',
      category_id: '',
      keyword_deletion_status: String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED),
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch(TWO_DATE_AGO_DEFAULT);
    setKeywordSearch("");
    setCategorySearch(null);
    setFrequencySearch(null);
    setStatusSearch(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Ranking'),
        accessor: "ranking",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ width: '60px' }}>
            <div className="text-start">{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
          </div>
        ),
      },
      // {
      //   Header: t("Date"),
      //   accessor: "date",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: "align-middle",
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <div style={{ minWidth: "120px" }}>
      //         {String(item?.date || "").replace("week", t("Week"))}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: t('Status'),
        accessor: "keyword_deletion_status",
        filterable: false,
        sortable: false,
        thWidth: 80,
        thClass: 'text-center align-middle',
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '70px' }}>
              <span className={`rounded-pill badge bg-${['success', 'danger', 'primary',][Number(cell?.value || 0)] || 'success'}`}>{STATUS_KEYWORD_RANKING_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value || 0))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 180,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const info: any = item?.keyword_info;
          return (
            <div className="cursor-pointer d-flex align-items-center justify-content-between" style={{ minWidth: "160px" }} onClick={(e) => {
              e.stopPropagation();
              if (info) {
                handleCallKeywordDetail(item);
              }
            }}>
              {(info) &&
                <div style={{ width: '34px', height: '42px' }} className="me-2">
                  <img
                    alt={keyword?.keyword || ''}
                    loading="lazy" src={info?.image_url || info?.image || NoImage}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      backgroundColor: '#eaeaea'
                    }}
                  />
                </div>
              }
              <div style={{ width: '100px' }}>{cell?.value}</div>
              <div className='d-flex gap-2 justify-content-end' style={{ width: '60px' }}>
                {String(item?.keyword_deletion_status || 0) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED) && <button
                  className="btn btn-sm btn-outline-danger d-inline-block edit-item-btn"
                  style={{ fontSize: '11px', padding: '3px 5px', width: '55px' }}
                  onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ id: item?.id, category_id: item?.category_id, status: ENUM_STATUS_KEYWORD_RANKING.DELETED }) }}
                >
                  {t('Hide')}
                </button>}
                {String(item?.keyword_deletion_status || 0) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) && <>
                  <button className="btn btn-sm btn-secondary d-inline-block edit-item-btn"
                    style={{ fontSize: '11px', padding: '3px 5px', width: '55px' }}
                    onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ id: item?.id, category_id: item?.category_id, status: ENUM_STATUS_KEYWORD_RANKING.ACTIVATED }) }}
                  >
                    {t('Cancel')}
                  </button>
                  {/* {(isHavePermissionRole(ROLES_FOR_APP.KEYWORD_DELETE_CONFIRM, userPermissions)) && <button className="btn btn-sm btn-danger d-inline-block edit-item-btn"
                    style={{ fontSize: '11px', padding: '3px 5px', width: '55px' }}
                    onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ id: item?.id, category_id: item?.category_id, status: 9999999 }) }}
                  >
                    {t('Confirm')}
                  </button>} */}
                </>}
              </div>
            </div >
          )
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 280,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(category?.name);
              categoryName.push(subs.join(` > `));
            }
          }

          return (
            <div style={{ minWidth: '280px' }}>
              <CollapseTag tags={categoryName} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />
            </div>
          );
        },
      },
      {
        Header: t("Trend Index"),
        accessor: "index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Search Volume"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        thWidth: 450,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("Search Volume")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'search_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'search_index') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t("Search Index")}
                  {query?.sort_by === 'search_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Search Volume")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("News Weight")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "150px" }}>
                {formatNumberWithCommas(item.search_index)}
              </div>
              <div
                style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}
              >
                {formatNumberWithCommas(item.search_value)}
              </div>
              <div style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.news_ratio)} %
              </div>
            </div>
          );
        },
      },
      // {
      //   Header: t('Search Index'),
      //   accessor: "search_index",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 150,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: '120px' }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('News Index'),
      //   accessor: "news_index",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 170,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${'150px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t("News Count"),
      //   accessor: "news_count",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: 150,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => (
      //     <div className="text-end" style={{ minWidth: "120px" }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t("News Total"),
      //   accessor: "news_total",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: 150,
      //   thClass: "text-end align-middle",
      //   Cell: (cell: any) => (
      //     <div className="text-end" style={{ minWidth: "120px" }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t("News"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        thWidth: 450,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("News")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'news_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'news_index') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t("News Index")}
                  {query?.sort_by === 'news_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Assigned News")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Total News")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "150px" }}>
                {formatNumberWithCommas(item.viral_index)}
              </div>
              <div
                className="cursor-pointer link-primary text-decoration-underline"
                style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}
                onClick={() => setKeywordAnalysisNews((_prev) => ({ keyword_id: item?.id, date: query?.date || TWO_DATE_AGO_DEFAULT }))}
              >
                {formatNumberWithCommas(item.news_count)}
              </div>
              <div style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.news_total)}
              </div>
            </div>
          );
        },
      },
      {
        Header: t('Viral Index'),
        accessor: "viral_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t('Activity Index'),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-end",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("P Index"),
        accessor: "p_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-end",
        description: t("Participation Index"),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Gender"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 200,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={2}>
                  {t("Gender")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Male")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Female")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_male_value || 0,
            item.search_female_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_male_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_female_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "6",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Age"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 500,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Age")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("10")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("20")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("30")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("40")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("50")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_age_10_value || 0,
            item.search_age_20_value || 0,
            item.search_age_30_value || 0,
            item.search_age_40_value || 0,
            item.search_age_50_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_10_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_20_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_30_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[2]} %)</span>
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.search_age_40_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[3]} %)</span>
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_50_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[4]} %)</span>
              </div>
            </div>
          );
        },
      },
      // {
      //   Header: t('Created at'),
      //   accessor: "created_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end align-middle',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end" style={{ minWidth: '100px' }}>
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: t('Updated at'),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end align-middle',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end" style={{ minWidth: '100px' }}>
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: t('Action'),
      //   thClass: 'text-center align-middle',
      //   thWidth: 80,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <div className="text-center" style={{ minWidth: '80px' }}>
      //         <ul className="list-inline justify-content-center hstack gap-2 mb-0">
      //           {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_UPDATE, userPermissions) && (<TooltipCustom
      //             title={t('Button Update Keyword')}
      //             id={`update-cp-${item?.id}`}
      //           >
      //             <li className="list-inline-item edit " title="Edit">
      //               <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
      //                 onClick={(e) => {
      //                   e.preventDefault();
      //                   setItemUpdateKeyword((_prev) => item);
      //                 }}>
      //                 <i className="ri-pencil-fill"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>)}
      //           {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_DELETE, userPermissions) && (<TooltipCustom
      //             title={t('Button Delete Keyword')}
      //             id={`detail-cp-${item?.id}`}
      //           >
      //             <li className="list-inline-item" title="Remove">
      //               <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
      //                 onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
      //               >
      //                 <i className="ri-delete-bin-5-fill"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>)}
      //         </ul>
      //       </div>
      //     );
      //   },
      // },
    ],
    [i18n?.language, userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IKeyword) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeyword(idDelete);
      if (response?.code === 200) {
        dispatch(onGetKeywordsMonitor(query));
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  }
  // End::Delete


  // Begin::Update status
  const handleConfirmChangeStatus = (item: { id: number, category_id: number, status: number }) => {
    setKeywordAction((_prev) => item);
  };

  const handleActionChangeStatus = async () => {
    if (!keywordAction) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await changeStatusKeyword(keywordAction);
      if (response?.code === 200) {
        dispatch(onGetKeywordsMonitor(query));
        setIsConfirmLoading((_prev) => false);
        setKeywordAction((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusClick = () => {
    setKeywordAction((_prev) => null);
  }
  // End::Update status

  const handleUpdateKeyword = async () => {
    try {
      if (!itemUpdateKeyword) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putKeyword(itemUpdateKeyword?.id, { keyword: itemUpdateKeyword?.keyword });
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeyword((_prev) => null);
        dispatch(onGetKeywordsMonitor(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: categoryDropdown?.level_5?.value || categoryDropdown?.level_4?.value || categoryDropdown?.level_3?.value || categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, [])
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setKeyword((prev: any) => null);
        setCategoryDropdown((prev: any) => ({}));
        setInputKeywords((prev: any) => '');
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        dispatch(onGetKeywordsMonitor(query));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }


  const handleCallKeywordDetail = async (item: IKeyword) => {
    try {
      setKeywordDetail(() => ({ keyword_type: item?.keyword_type }));
      const response: any = await getKeyword(item?.id);
      if (response?.code === 200) {
        setKeywordDetail(() => (response?.data));
      } else {
        setKeywordDetail(() => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setKeywordDetail(() => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordDetailClick = () => {
    setKeywordDetail(() => null);
  };

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelKeywords({ ...query, page: 1 });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const onHideKeywordAnalysisNews = () => {
    setKeywordAnalysisNews((_prev) => null);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {

        const [res1, res2]: any = await Promise.all([getFrequencies(), getAllCategoriesNormal({})]);
        setListFrequency((_prev) => res1?.data || []);
        setOptionCategoryDropdown((_prev: any) => res2?.data || []);

        if (query?.category_id) {
          setCategorySearch((_prev) => {
            return ({
              label: '',
              value: `${query?.category_id}`,
            });
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!query?.category_id) {
      setCategorySearch({ label: t('All Category'), value: '' });
    }
    document.title = `${t('Ranking Monitoring')} - ${t('Monitoring')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Ranking Monitoring')} pageTitle={t('Monitoring')} />
          {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_MANAGEMENT, userPermissions) && (
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-5 col-sm-6 col-md-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={keywords?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      <div className="col-7  col-sm-6 col-md-6 text-end">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleDownloadExcel}
                          disabled={isLoadingExportFile}
                        >
                          {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                          <span className="ms-1">{t('Button Download Excel')}</span>
                        </button>
                      </div>
                      {/* {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_CREATE, userPermissions) && (<div className="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            className="btn btn-success add-btn rounded-pill"
                            id="create-btn"
                            onClick={() => setKeyword((prev: any) => ({}))}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {t('Button Create Keyword')}
                          </button>
                        </div>
                      </div>)} */}
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Col md={12} xl={12} xxl={6}>
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Status_Keyword_Ranking_0')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {(isKeywordLoading && String(query?.page) === '1') ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={keywords?.pagination?.active_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Status_Keyword_Ranking_1')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {(isKeywordLoading && String(query?.page) === '1') ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={keywords?.pagination?.delete_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Category')} isShow={!!categorySearch}>
                          <DropdownCategory
                            name="category"
                            placeholder={t('Category')}
                            isClearable={true}
                            dataList={optionCategoryDropdown || []}
                            initialValue={categorySearch ?? undefined}
                            onChangeCategory={(event) => {
                              setCategorySearch(event)
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_KEYWORD_RANKING_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Status'), value: '' }}
                            colors={['success', 'danger']}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('Date')}`}
                            value={dateSearch || undefined}
                            selected={!!dateSearch ? new Date(dateSearch) : undefined}
                            dateFormat="yyyy-MM-dd"
                            isClearable={false}
                            maxDate={new Date()}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={12} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed sticky-table-ranking-monitoring"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={keywords?.list?.length ? keywords?.list : []}
                      textNoData={(keywords?.list?.length === 0 && !query?.category_id) ? t('Please select at least 1 category') : ''}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>

        <Modal
          isOpen={keyword !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setKeyword(null)}>
            {t('Button Create Keyword')}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Category')}
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 1')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={optionCategoryDropdown || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_1 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_1: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 2')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_1?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_2 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_2: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 3')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_2?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_3 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_3: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 4')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_3?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_4 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_4: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 5')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_4?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_5 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({ ...prev, level_5: e }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Keyword')}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords((prev: any) => (event?.target?.value || ''))
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => { !isLoading && setKeyword(null) }}
                  style={{ minWidth: '100px' }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || (!categoryDropdown?.level_1?.value || !inputKeywords)}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: '100px' }}
                >
                  {isLoading ? (
                    <Spinner size="sm" ></Spinner>
                  ) : t("Button Update")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={itemUpdateKeyword !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeyword((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeyword((_prev) => null)}>
            {t('Button Update Keyword')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="keyword"
                  name="keyword"
                  autocomplete={false}
                  value={itemUpdateKeyword?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeyword((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  style={{ width: '100px' }}
                  onClick={() => { !isLoading && setItemUpdateKeyword((_prev) => null) }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoading}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeyword()}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!keywordAnalysisNews}
          centered={true}
          toggle={onHideKeywordAnalysisNews}
          size="xl"
          className="modal-fullscreen"
          scrollable={true}
          keyboard={true}
        >
          <ModalHeader toggle={onHideKeywordAnalysisNews}>
            {t('News Searching Keyword')}
          </ModalHeader>
          <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
            <ModalNewsSearchingKeyword keywordAnalysisNews={keywordAnalysisNews} />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Keyword')}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ModalDetailKeyword
          header={t("Keyword Description")}
          isShow={!!keywordDetail}
          keyword={keywordDetail}
          onClose={onCloseKeywordDetailClick}
        />
        <ModalConfirm
          header={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) ? t('Hide') : (String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED) ? t('Cancel') : '')}
          textButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) ? t('Hide') : (String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED) ? t('Cancel') : '')}
          classButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) ? 'btn-soft-danger' : (String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED) ? 'btn-secondary' : 'btn-danger')}
          title={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) ? `${t('Are you sure you want to hide this keyword')}?` : `${t('Are you sure you want to cancel this')}?`}
          content={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.DELETED) ? t('When hidden, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.') : (String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED) ? t('After confirming the cancel action, the data will be permanently canceled and cannot be recovered. Do you want to proceed with the cancel action.') : '')}
          isOpen={keywordAction !== null}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusClick}
          onConfirm={handleActionChangeStatus}
        />
      </div>
    </React.Fragment >
  );
};

export default RankingMonitoring;