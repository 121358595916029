import { createAsyncThunk } from "@reduxjs/toolkit";
import categoryApi from 'api/categoryApi';
import { formatQueryParams } from "helpers/format";
import { changeStatusRankingCampaignsAction } from "./reducer";

export const getCategories = createAsyncThunk("Categories", async (params: any = {}) => {
  try {
    const response = await categoryApi.categories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getConditions = createAsyncThunk("Category Conditions", async (params: any = {}) => {
  try {
    const response = await categoryApi.conditions(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getConfigHistories = createAsyncThunk("Category Config History", async (params: any = {}) => {
  try {
    const response = await categoryApi.getConfigHistories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getCategory = async (id: number) => {
  try {
    const response = await categoryApi.getCategory(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postCategory = async (data: any = {}, params: any = {}) => {
  try {
    const response = await categoryApi.postCategory(data, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putCategory = async (id: number | string, data: any = {}) => {
  try {
    const response = await categoryApi.putCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putGroupTypeCategory = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.putGroupTypeCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putClassificationCategory = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.putClassificationCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putIndexConfigsCategory = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.putIndexConfigsCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putFrequencyTypeCategory = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.putFrequencyTypeCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategory = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.deleteCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putCategories = async (data: any = {}, params: any = {}) => {
  try {
    const response = await categoryApi.putCategories(data, params);
    return response;
  } catch (error) {
    return error;
  }
};


export const changeStatusRankingCampaigns = (preloader: any) => async (dispatch: any) => {
  try {
    dispatch(changeStatusRankingCampaignsAction(preloader));
  } catch (error) {
    // console.log(error);
  }
};




export const getCondition = async (id: number) => {
  try {
    const response = await categoryApi.getCondition(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postCondition = async (data: any = {}, params: any = {}) => {
  try {
    const response = await categoryApi.postCondition(data, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putCondition = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.putCondition(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCondition = async (id: number, data: any = {}) => {
  try {
    const response = await categoryApi.deleteCondition(id, data);
    return response;
  } catch (error) {
    return error;
  }
};