import BreadCrumb from "components/Common/BreadCrumb";
import CollapseTag from "components/Common/CollapseTag";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  getUserVotes as onGetUserVote,
  getUserVotesChart as onGetUserVoteChart,
} from "../../../store/thunks";
import ChartLine from "./ChartLine";

const typeQuery = {
  key: StringParam,
};

const SORT_BY_DEFAULT = "date";
const ORDER_BY_DEFAULT = "DESC";

registerLocale("en", en);
registerLocale("ko", ko);

const VoteStatistics = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ""),
    category_id: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, `${moment().subtract(7, 'days').format("Y-MM-DD")}`),
    end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
    ...typeQuery,
  });

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Statistic;

  const StatisticsUserVotesProperties = createSelector(
    selectLayoutState,
    (state) => ({
      userVotes: state.userVotes,
      isUserVoteSuccess: state.isUserVoteSuccess,
      isUserVoteLoading: state.isUserVoteLoading,

      userVotesLineChart: state.userVotesLineChart,
      isUserVoteLineChartLoading: state.isUserVoteLineChartLoading,
      isUserVoteLineChartSuccess: state.isUserVoteLineChartSuccess,

      error: state.error,
    })
  );

  // Inside your component
  const {
    userVotes,
    isUserVoteLoading,
    userVotesLineChart,
    isUserVoteLineChartLoading,
  } = useSelector(StatisticsUserVotesProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date ? moment(query?.start_date, "Y-MM-DD").toDate() : null,
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  useEffect(() => {
    dispatch(onGetUserVote(query));
    dispatch(onGetUserVoteChart(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || "",
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      start_date: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
      end_date: `${moment().format("Y-MM-DD")}`,
      keyword: "",
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: +new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch([
      moment(queryNew?.start_date || "", "Y-MM-DD").toDate(),
      moment(queryNew?.end_date || "", "Y-MM-DD").toDate(),
    ]);
    setKeywordSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-start",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return !!item?.text_row ? (
            <strong>{item?.text_row} </strong>
          ) : (
            <div className="text-start" style={{ minWidth: "120px" }}>
              <span>{cell?.value}</span>
            </div>
          );
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => (
          <div style={{ minWidth: "140px" }}>
            <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
          </div>
        ),
      },
      {
        Header: t("Ticket"),
        accessor: "ticket_amount",
        filterable: false,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => (
          <div style={{ minWidth: "140px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 280,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          let cate = value?.categories || [];
          const categories = [...cate].sort(function (a: any, b: any) {
            return a?.depth - b?.depth;
          });

          let categoryName: string[] = [];

          let subs = [];
          for (let category of categories) {
            subs.push(category?.name);
          }
          categoryName.push(subs.join(` > `));

          return (
            <div style={{ minWidth: "280px" }}>
              <CollapseTag
                tags={categoryName}
                isRank={false}
                isInlineBlock={true}
                totalShow={2}
                isBackground={false}
                isShowModal={true}
                titleModal={t("Categories")}
              />
            </div>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    document.title = `${t("Vote Statistics")} - ${t("Statistics")} | Rankify`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const categories = useMemo(() => {
    return userVotesLineChart?.map((item: any) => item?.date).reverse() || [];
  }, [userVotesLineChart, i18n?.language]);

  const series = useMemo(() => {
    const obChart = userVotesLineChart?.map((i: any) => i?.ticket_amount).reverse() || [];
    return [
      {
        name: t("Ticket"),
        type: "column",
        data: obChart,
      },
    ];
  }, [userVotesLineChart, i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Vote Statistics")}
            pageTitle={t("Statistics")}
          />
          {isHavePermissionRole(
            ROLES_FOR_APP.KEYWORD_MANAGEMENT,
            userPermissions
          ) && (
              <Row>
                <Col sm={12}>
                  <Card
                    id="customerList"
                    style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                  >
                    <CardHeader className="border-0">
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              <span className="me-2">{t("Total")}:</span>
                              <CountUp
                                start={0}
                                end={userVotes?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </h5>
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={12}
                          md={4}
                          xl={3}
                          xxl={2}
                          className="mt-3 mt-md-2"
                        >
                          <LabelWrapper
                            label={t("Keyword")}
                            isShow={!!keywordSearch}
                          >
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Keyword")}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col
                          sm={4}
                          md={4}
                          xl={3}
                          xxl={3}
                          className="date-picker-wrapper-custom mt-3 mt-md-2"
                        >
                          <LabelWrapper label={t("Date")} isShow={!!dateSearch}>
                            <DatePickerCustom
                              placeholder={`${t("Start Date")} - ${t(
                                "End Date"
                              )}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                              showOptions={[
                                "today",
                                "yesterday",
                                "two_day_ago",
                                "last_3_days",
                                "last_7_days",
                                "last_14_days",
                                "last_30_days",
                                "this_week",
                                "last_week",
                                "this_month",
                                "last_month",
                              ]}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          xl={12}
                          xxl={4}
                          className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={searchData}
                              disabled={isUserVoteLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <ChartLine
                      className="my-3"
                      titles={[t("Ticket")]}
                      categories={categories}
                      series={series}
                    />
                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={userVotes?.list?.length ? userVotes?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={userVotes?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(userVotes?.pagination?.total) /
                          Number(userVotes?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isUserVoteLoading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VoteStatistics;
