import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownStatus from "components/Common/DropdownStatus";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import { COLOR_STATUS_PAYMENT, STATUS_PAYMENT_OPTIONS, TYPE_PRODUCT_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getPaymentHistories as onGetPaymentHistories } from "../../../store/thunks";
import CopyWrapper from "components/Common/CopyWrapper";

const SORT_BY_DEFAULT = 'created_at';
const ORDER_BY_DEFAULT = 'DESC';
const PaymentHistory = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_PAYMENT_OPTIONS_LANG = STATUS_PAYMENT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_PRODUCT_OPTIONS_LANG = TYPE_PRODUCT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, `${moment().subtract(30, 'days').format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        status: withDefault(StringParam, ''),
        product_types: withDefault(StringParam, ''),
        start_amount: withDefault(StringParam, ''),
        end_amount: withDefault(StringParam, ''),
        transaction_id: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Payment;

    const PaymentProperties = createSelector(
        selectLayoutState,
        (state) => ({
            paymentHistories: state.paymentHistories,
            isPaymentHistorySuccess: state.isPaymentHistorySuccess,
            isPaymentHistoryLoading: state.isPaymentHistoryLoading,
            error: state.error,
        })
    );

    const { paymentHistories, isPaymentHistoryLoading } = useSelector(PaymentProperties);

    const [transactionIdSearch, setTransactionIdSearch] = useState<string>(query?.transaction_id || "");

    // Amount from to range
    const [startAmountSearch, setStartAmountSearch] = useState<string>(query?.start_amount || "");
    const [endAmountSearch, setEndAmountSearch] = useState<string>(query?.end_amount || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_PAYMENT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);
    const [typeSearch, setTypeSearch] = useState<Option[] | null>(TYPE_PRODUCT_OPTIONS_LANG?.filter((item) => String(query?.product_types).split(',')?.includes(String(item?.value))));

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    useEffect(() => {
        dispatch(onGetPaymentHistories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            transaction_id: transactionIdSearch || '',
            status: statusSearch?.value || '',
            product_types: typeSearch?.reduce((arr: string[], item: Option) => (!!item?.value ? [...arr, item?.value] : arr), []).join(',') || '',
            start_amount: startAmountSearch || '',
            end_amount: endAmountSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            status: '',
            product_types: '',
            start_amount: '',
            end_amount: '',
            transaction_id: '',
            start_date: `${moment().subtract(30, 'days').format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }
        setQuery(queryNew, "push");
        setStatusSearch(null);
        setTypeSearch(null);
        setStartAmountSearch("");
        setEndAmountSearch("");
        setTransactionIdSearch("");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    };


    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: false,
                sortable: true,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '60px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Transaction ID'),
                accessor: "relation_id",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '200px' }}>
                                <CopyWrapper contentCopy={cell?.value} >
                                    {cell?.value}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => {
                    return (
                        <div className="text-start" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${COLOR_STATUS_PAYMENT[cell?.value] || 'secondary'}`}>{STATUS_PAYMENT_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Amount') + ' (원)',
                accessor: "amount",
                filterable: false,
                sortable: true,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Item Amount') + ' (개)',
                accessor: "product_amount",
                filterable: false,
                sortable: true,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '130px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Item'),
                accessor: "product_type",
                filterable: false,
                sortable: false,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    return (
                        <div className="text-end" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success', 'primary'][cell?.value] || 'secondary'}`}>{TYPE_PRODUCT_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },

            // {
            //     Header: t('Request at'),
            //     accessor: "request_at",
            //     filterable: true,
            //     sortable: false,
            //     thClass: 'text-end',
            //     Cell: (cell: any) => {
            //         const arrDate = String(cell?.value || '').split(' ');
            //         return (
            //             <div className="text-end" style={{ minWidth: '120px' }}>
            //                 <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            //             </div>
            //         )
            //     },
            // },
            {
                Header: t('Date'),
                accessor: "created_at",
                filterable: true,
                sortable: true,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Verify at'),
                accessor: "verify_at",
                filterable: true,
                sortable: false,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Cancel at'),
                accessor: "cancel_at",
                filterable: true,
                sortable: false,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_PAYMENT_OPTIONS_LANG, TYPE_PRODUCT_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        if (typeSearch) {
            setTypeSearch((_prev: any) =>
                TYPE_PRODUCT_OPTIONS_LANG?.filter((e: any) => _prev?.map((i: any) => String(i?.value))?.includes(String(e.value)))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        document.title = `${t("Payment History")} - ${t("Payment Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Payment History')} pageTitle={t('Payment Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.PAYMENT_HISTORY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={paymentHistories?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Transaction ID')} isShow={!!transactionIdSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Transaction ID')}...`}
                                                        value={transactionIdSearch}
                                                        onChange={(e) => setTransactionIdSearch(String(e.target.value).replace(/[#%&]/g, ''))}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_PAYMENT_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={COLOR_STATUS_PAYMENT}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <InputsRange
                                                    nameFrom="from"
                                                    nameTo="to"
                                                    valueFrom={startAmountSearch}
                                                    valueTo={endAmountSearch}
                                                    placeholderFrom={`${t('Amount (Start)')}...`}
                                                    placeholderTo={`${t('Amount (End)')}...`}
                                                    labelFrom={`${t('Amount (Start)')}`}
                                                    labelTo={`${t('Amount (End)')}`}
                                                    isTypeNumber={true}
                                                    onChangeFrom={(val) => setStartAmountSearch(val)}
                                                    onChangeTo={(val) => setEndAmountSearch(val)}
                                                    onKeyDownFrom={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                    onKeyDownTo={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Items')} isShow={true}>
                                                    <DropdownMultiOption
                                                        name=""
                                                        dataList={TYPE_PRODUCT_OPTIONS_LANG}
                                                        placeholder={`${t("Items")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={typeSearch || null}
                                                        onChangeSelect={(e: any) => setTypeSearch(e)}
                                                        isHasOptionAll={false}
                                                        cacheOptions={true}
                                                        optionAll={{ label: t('All Item'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-end mt-3">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isPaymentHistoryLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={paymentHistories?.list?.length ? paymentHistories?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={paymentHistories?.pagination?.total}
                                            customPageCount={Math.ceil(Number(paymentHistories?.pagination?.total) / Number(paymentHistories?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isPaymentHistoryLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PaymentHistory;