import { ITrendIndexConfig } from "api/types/_trendindex";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Row, Spinner } from "reactstrap";
import { postTrendIndexConfig, putTrendIndexConfig } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ITrendIndexConfig | null;
    indexType: number | string | undefined,
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const Form = ({
    detail,
    indexType,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                description: values?.description || '',
                ratio: Number(values?.ratio || 0),
                index_type: Number(indexType),
            };
            const response: any = detail?.id
                ? await putTrendIndexConfig(detail?.id, data)
                : await postTrendIndexConfig(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        description: Yup.string().nullable(),
        ratio: Yup.string().required(`${t("This is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: "",
            ratio: 100,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_description = valueDefault?.description;
        const v_ratio = valueDefault?.ratio;

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("description", v_description);
        formik.setFieldValue("ratio", v_ratio);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={7} lg={7}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Index")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Index")}...`}
                                                        onChange={(event: any) =>
                                                            formik.setFieldValue(
                                                                "name",
                                                                event?.target?.value || ""
                                                            )
                                                        }
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={5} lg={5}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Weight")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="ratio"
                                                        name="ratio"
                                                        autocomplete={false}
                                                        value={formik?.values?.ratio}
                                                        placeholder={`${t("Weight")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, "");
                                                            const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                                                            formik.setFieldValue("ratio", s)
                                                        }}
                                                    />
                                                    <div
                                                        style={{ bottom: 10, right: 20 }}
                                                        className="position-absolute fw-medium text-secondary"
                                                    >
                                                        %
                                                    </div>
                                                    {formik.touched.ratio && formik.errors.ratio ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.ratio}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Description")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="description"
                                                        name="description"
                                                        autocomplete={false}
                                                        value={formik?.values?.description}
                                                        placeholder={`${t("Description")}...`}
                                                        onChange={(event: any) =>
                                                            formik.setFieldValue(
                                                                "description",
                                                                event?.target?.value || ""
                                                            )
                                                        }
                                                    />
                                                    {formik.touched.description && formik.errors.description ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: "100px" }}
                                                        onClick={() => {
                                                            handleClose && handleClose();
                                                        }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (
                                                        <button
                                                            className="btn btn-primary rounded-pill fs-14"
                                                            color="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                            style={{ width: "150px" }}
                                                        >
                                                            {isLoading ? (
                                                                <Spinner size="sm" className="me-2"></Spinner>
                                                            ) : (
                                                                <>
                                                                    {" "}
                                                                    {detail?.id
                                                                        ? t("Button Update")
                                                                        : t("Button Create")}
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default Form;
