import BreadCrumb from 'components/Common/BreadCrumb';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";

const typeQuery = {
  'key': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';


registerLocale("en", en);
registerLocale("ko", ko);


const Participation = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    start_date: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Configuration;

  const ConfigurationProperties = createSelector(
    selectLayoutState,
    (state) => ({
      configurations: state.configurations,
      isConfigurationSuccess: state.isConfigurationSuccess,
      isConfigurationLoading: state.isConfigurationLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { configurations, error, isConfigurationLoading } = useSelector(ConfigurationProperties);

  // useEffect(() => {
  //   dispatch(onGetConfigurations(
  //     { ...query }
  //   ));
  // }, [dispatch, query]);


  useEffect(() => {
    document.title = `${t('Participation Management')} - ${t('Participation Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Participation Management')} pageTitle={t('Participation Management')} />
          <Row className="text-center py-4">
            <div> {t('Is in the process of development')}</div>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Participation;