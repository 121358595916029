import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ITrendIndex, ITrendIndexConfig } from "api/types/_trendindex";
import { getNewsIndexes, getParticipationIndexes, getSearchIndexes, getSocialIndexes, getStockIndexes, getTrendIndexConfigs, getTrendIndexes, getViralIndexes } from "./thunk";

export interface IState {
  trendIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isTrendIndexLoading: boolean,
  isTrendIndexSuccess: boolean,

  searchIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isSearchIndexLoading: boolean,
  isSearchIndexSuccess: boolean,

  socialIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isSocialIndexLoading: boolean,
  isSocialIndexSuccess: boolean,

  viralIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isViralIndexLoading: boolean,
  isViralIndexSuccess: boolean,

  newsIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isNewsIndexLoading: boolean,
  isNewsIndexSuccess: boolean,

  stockIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isStockIndexLoading: boolean,
  isStockIndexSuccess: boolean,

  participationIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isParticipationIndexLoading: boolean,
  isParticipationIndexSuccess: boolean,

  trendIndexConfigs: ResponseData<ITrendIndexConfig[]> & PaginationResponse | null,
  isTrendIndexConfigLoading: boolean,
  isTrendIndexConfigSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  trendIndexes: null,
  isTrendIndexLoading: false,
  isTrendIndexSuccess: false,

  searchIndexes: null,
  isSearchIndexLoading: false,
  isSearchIndexSuccess: false,

  socialIndexes: null,
  isSocialIndexLoading: false,
  isSocialIndexSuccess: false,

  viralIndexes: null,
  isViralIndexLoading: false,
  isViralIndexSuccess: false,

  newsIndexes: null,
  isNewsIndexLoading: false,
  isNewsIndexSuccess: false,

  stockIndexes: null,
  isStockIndexLoading: false,
  isStockIndexSuccess: false,

  participationIndexes: null,
  isParticipationIndexLoading: false,
  isParticipationIndexSuccess: false,

  trendIndexConfigs: null,
  isTrendIndexConfigLoading: false,
  isTrendIndexConfigSuccess: false,

  error: {},
};

const TrendIndexSlice = createSlice({
  name: "TrendIndex",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //trend index
    builder.addCase(getTrendIndexes.pending, (state: IState, action: any) => {
      state.isTrendIndexLoading = true
    });
    builder.addCase(getTrendIndexes.fulfilled, (state: IState, action: any) => {
      state.trendIndexes = action.payload.data;
      state.isTrendIndexSuccess = true;
      state.isTrendIndexLoading = false;
    });
    builder.addCase(getTrendIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isTrendIndexSuccess = false;
      state.isTrendIndexLoading = false;
    });

    //search index
    builder.addCase(getSearchIndexes.pending, (state: IState, action: any) => {
      state.isSearchIndexLoading = true
    });
    builder.addCase(getSearchIndexes.fulfilled, (state: IState, action: any) => {
      state.searchIndexes = action.payload.data;
      state.isSearchIndexSuccess = true;
      state.isSearchIndexLoading = false;
    });
    builder.addCase(getSearchIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSearchIndexSuccess = false;
      state.isSearchIndexLoading = false;
    });

    //social index
    builder.addCase(getSocialIndexes.pending, (state: IState, action: any) => {
      state.isSocialIndexLoading = true
    });
    builder.addCase(getSocialIndexes.fulfilled, (state: IState, action: any) => {
      state.socialIndexes = action.payload.data;
      state.isSocialIndexSuccess = true;
      state.isSocialIndexLoading = false;
    });
    builder.addCase(getSocialIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSocialIndexSuccess = false;
      state.isSocialIndexLoading = false;
    });

    //viral index
    builder.addCase(getViralIndexes.pending, (state: IState, action: any) => {
      state.isViralIndexLoading = true
    });
    builder.addCase(getViralIndexes.fulfilled, (state: IState, action: any) => {
      state.viralIndexes = action.payload.data;
      state.isViralIndexSuccess = true;
      state.isViralIndexLoading = false;
    });
    builder.addCase(getViralIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isViralIndexSuccess = false;
      state.isViralIndexLoading = false;
    });

    //news index
    builder.addCase(getNewsIndexes.pending, (state: IState, action: any) => {
      state.isNewsIndexLoading = true
    });
    builder.addCase(getNewsIndexes.fulfilled, (state: IState, action: any) => {
      state.newsIndexes = action.payload.data;
      state.isNewsIndexSuccess = true;
      state.isNewsIndexLoading = false;
    });
    builder.addCase(getNewsIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsIndexSuccess = false;
      state.isNewsIndexLoading = false;
    });

    //stock index
    builder.addCase(getStockIndexes.pending, (state: IState, action: any) => {
      state.isStockIndexLoading = true
    });
    builder.addCase(getStockIndexes.fulfilled, (state: IState, action: any) => {
      state.stockIndexes = action.payload.data;
      state.isStockIndexSuccess = true;
      state.isStockIndexLoading = false;
    });
    builder.addCase(getStockIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isStockIndexSuccess = false;
      state.isStockIndexLoading = false;
    });

    //participation index
    builder.addCase(getParticipationIndexes.pending, (state: IState, action: any) => {
      state.isParticipationIndexLoading = true
    });
    builder.addCase(getParticipationIndexes.fulfilled, (state: IState, action: any) => {
      state.participationIndexes = action.payload.data;
      state.isParticipationIndexSuccess = true;
      state.isParticipationIndexLoading = false;
    });
    builder.addCase(getParticipationIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isParticipationIndexSuccess = false;
      state.isParticipationIndexLoading = false;
    });

    //trend index config
    builder.addCase(getTrendIndexConfigs.pending, (state: IState, action: any) => {
      state.isTrendIndexConfigLoading = true
    });
    builder.addCase(getTrendIndexConfigs.fulfilled, (state: IState, action: any) => {
      state.trendIndexConfigs = action.payload.data;
      state.isTrendIndexConfigSuccess = true;
      state.isTrendIndexConfigLoading = false;
    });
    builder.addCase(getTrendIndexConfigs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isTrendIndexConfigSuccess = false;
      state.isTrendIndexConfigLoading = false;
    });
  },
});

export default TrendIndexSlice.reducer;